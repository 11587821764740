import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import banner2 from "../../assets/images/menu/banner2.svg";
import bannerAngle from "../../assets/images/menu/banner2_left_ange.svg";
import Icon from "../../components/Icon/Icon";
import banner2lg from "../../assets/images/menu/banner2_lg.svg";
import { ClockIcon } from "@heroicons/react/24/outline";
import Clock from "../../components/Icon/Clock";
import { Location } from "../../components/Icon/Location";
import VegIcon from "../../assets/icons/veg.svg";
import { StarIcon, PhoneIcon } from "@heroicons/react/20/solid";
import Star from "../../components/Icon/Star";
import { success } from "../../components/notify";
import { useSelector } from "react-redux";
import { AboutPage, MenuPage } from "../../routes/RoutesPath";
import { prepareAddressFormat } from "../../utils/helper";

const MenuBanner = ({ storeData, offerdata }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentOfferIndex, setCurrentOfferIndex] = useState(0);
  const isLoggedin = useSelector((state) => state.userdata.loggedIn);

  useEffect(() => {}, []);

  const getNextOfferIndex = () => {
    return (currentOfferIndex + 1) % offerdata?.common_coupon?.length;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentOfferIndex(getNextOfferIndex());
    }, 4000);
    return () => clearInterval(interval);
  }, [currentOfferIndex, offerdata?.common_coupon?.length]);

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    success("Copied to clipboard!");
  };

  const handleBannerClick = () => {
    if (location.pathname.includes("order")) {
      navigate(AboutPage(storeData.store_Slug, storeData?.product_Id));
    } else if (location.pathname.includes("about")) {
      navigate(MenuPage(storeData.store_Slug, storeData?.product_Id));
    } else if (location.pathname.includes("cart")) {
      navigate(MenuPage(storeData.store_Slug, storeData?.product_Id));
    }
  };

  return (
    <div
      className="relative cursor-pointer"
      onClick={() => handleBannerClick()}
    >
      <div
        style={{
          "--image-url": `url(${storeData && storeData?.store_Banner_Image})`,
        }}
        className={`bg-[image:var(--image-url)] h-40 sm:h-60 lg:h-64 xl:h-72 relative bg-cover bg-center`}
      >
        <div className="bg-black w-full h-40 sm:h-60 lg:h-64 xl:h-72 absolute top-0 opacity-60" />
        <div className="absolute z-auto flex items-center space-x-4 top-8 sm:top-12 md:top-12 mdl:top-10 left-4 md:left-7 mdl:left-[60px] right-4 md:right-7 mdl:right-[60px]">
          <img
            loading="lazy"
            src={storeData && storeData.store_Logo}
            alt="Store Logo"
            className="shadow-sm rounded-lg w-auto h-24 sm:h-36 sm:w-36 xl:h-[150px] xl:w-[150px] object-cover left-3 sm:left-8 lg:left-[60px] "
          />
          <div>
            <div className="flex space-x-1">
              <div className="text-white text-wrap leading-6 text-lg md:text-lg lg:text-3xl 2xl:text-4xl font-bold font-inter sm:w-max sm:whitespace-normal flex">
                {storeData && storeData?.store_Name}
              </div>
            </div>
            <div className="flex sm:space-x-2 mt-0 sm:mt-0 items-center lg:pt-[8px] lg:pb-2">
              <div className="bg-white rounded-full sm:py-1 sm:px-1 lg:py-2 lg:px-2 hidden sm:block">
                <Location className="fill-orange-400 flex mx-auto sm:h-3 sm:w-3 lg:h-5 lg:w-5 2xl:h-6 2xl:w-6 " />
              </div>
              <div className="w-full text-wrap sm:w-full text-white text-xs mdl:text-[17px] 2xl:text-2xl font-medium font-inter">
                {prepareAddressFormat(
                  storeData?.store_Address_Line_1,
                  storeData?.store_Address_Line_2,
                  storeData?.store_Postcode,
                  storeData?.store_City
                )}
              </div>
            </div>
            <div className="items-center gap-x-2 pb-2 text-white font-medium hidden sm:flex">
              {storeData?.store_Telephone && (
                <PhoneIcon className="sm:h-4 sm:w-4 lg:h-5 lg:w-5 2xl:h-6 2xl:w-6" />
              )}
              {storeData && storeData?.store_Telephone}
            </div>
            {/* Rating and timing For MOBILE VIEW */}
            <div className="block sm:hidden">
              {storeData?.storerating?.feedbackCount && (
                <div className="flex mt-1 items-center">
                  <div className="w-12 h-5 bg-green-shade-3 rounded-full justify-center items-center gap-[3px] inline-flex">
                    <div className="flex space-x-1 items-center">
                      <div className="text-white text-xxs font-semibold font-inter">
                        {storeData && storeData?.storerating?.Rating}
                      </div>
                      <StarIcon className="text-white" width={10} height={10} />
                    </div>
                  </div>
                  <div className="text-white text-xs ml-1 font-normal font-inter">
                    {storeData?.storerating?.feedbackCount}
                    {storeData?.storerating?.feedbackCount > 1
                      ? "+ Reviews"
                      : "+ Review"}
                  </div>
                </div>
              )}
            </div>
            {/* TAB VIEW , WEBVIEW */}
            <div className="hidden sm:block w-full bg-white h-px"></div>
            <div className="hidden sm:flex md:space-x-0 xl:space-x-4 md:w-full pt-3">
              <div className="flex space-x-2 items-center">
                <div className="w-max">
                  {storeData?.store_Open_Status !== "Add" ? (
                    <div className="text-white text-xs sm:text-sm xl:text-lg w-max xl:w-max 2xl:text-2xl font-semibold font-inter">
                      Store Closed
                      {storeData?.store_Open_Time != "" && (
                        <div className="flex space-x-1 items-center w-full">
                          <ClockIcon className="sm:h-4 sm:w-4 xl:h-5 xl:w-5 text-white" />
                          <div className="text-white text-xs xl:text-xl font-medium font-inter w-max">
                            Opens at {storeData && storeData.store_Open_Time}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="text-white text-xs sm:text-sm xl:text-lg w-max xl:w-max 2xl:text-2xl font-semibold font-inter">
                      Store Open
                    </div>
                  )}
                </div>
                <div className="border-r-2 h-full opacity-40 border-white" />
                <div className="">
                  <div className="flex items-center space-x-1">
                    <Star className="text-white sm:h-4 sm:w-4 xl:h-5 xl:w-5 fill-white" />
                    <div className="text-white w-max text-xs sm:text-sm xl:text-xl xl:w-max font-semibold font-inter">
                      {storeData && storeData?.storerating?.Rating}
                    </div>
                  </div>
                  <div className="text-white w-max text-[10px] sm:text-xs xl:text-sm 2xl:text-lg xl:w-max font-medium font-inter pr-1">
                    {storeData && storeData?.storerating?.feedbackCount}+
                    {storeData?.storerating?.feedbackCount > 1
                      ? " Reviews"
                      : " Review"}
                  </div>
                </div>
              </div>

              <div className="flex space-x-2">
                {storeData?.store_Open_Delivery == 1 && (
                  <div className="border-r-2 h-full opacity-40 border-white" />
                )}
                {storeData?.store_Open_Delivery == 1 ? (
                  <div className="w-max">
                    <div className="flex space-x-1 items-center">
                      <ClockIcon className="sm:h-4 sm:w-4 xl:h-5 xl:w-5 text-white" />
                      <div className="text-white text-xs sm:text-sm lg:text-sm xl:text-xl font-semibold font-inter">
                        {storeData?.store_Preorder == "Yes" &&
                        storeData?.store_Open_Delivery_Time != ""
                          ? "Pre Order"
                          : `${storeData.store_Delivery_Time} Mins`}
                      </div>
                    </div>
                    <div className="text-white text-[10px] sm:text-xs xl:text-sm 2xl:text-lg font-medium font-inter">
                      {storeData?.store_Preorder == "Yes" &&
                      storeData?.store_Open_Delivery_Time != ""
                        ? "Delivery"
                        : `Delivery Time`}
                    </div>
                  </div>
                ) : null}

                {storeData.store_Open_Collection == 1 ? (
                  <div className="border-r-2 h-full 2xl:h-16 opacity-40 border-white sm:px-2 xl:px-2 2xl:px-4" />
                ) : null}
                {storeData.store_Open_Collection == 1 ? (
                  <div className="">
                    <div className="flex space-x-1 items-center">
                      <ClockIcon className="sm:h-4 sm:w-4 xl:h-5 xl:w-5 text-white" />
                      <div className="text-white w-max text-xs sm:text-sm lg:text-sm xl:text-xl xl:w-max font-semibold font-inter">
                        {storeData.store_Preorder == "Yes" &&
                        storeData.store_Open_Collection_Time != ""
                          ? "Pre Order"
                          : `${storeData.store_Collection_Time} Mins`}
                      </div>
                    </div>
                    <div className="text-white w-max text-[10px] sm:text-xs font-medium xl:text-sm 2xl:text-lg xl:w-max font-inter">
                      {storeData.store_Preorder == "Yes" &&
                      storeData.store_Open_Collection_Time != ""
                        ? "Collection"
                        : "Collection Time"}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="hidden -z-30">
          <div className="relative ">
            <img
              loading="lazy"
              src={storeData && storeData.store_Banner_Image}
              alt="Menu image"
              className="w-full object-cover h-40 sm:h-60 lg:h-64 xl:h-72 "
            />
            <div className="bg-black w-full h-40 sm:h-60 lg:h-64 xl:h-72 absolute top-0 opacity-70"></div>
          </div>
          <div className="">
            {/* banner start */}

            {/* banner end */}
          </div>
          {/* ))} */}
          {/* Banner end */}
          <div className="z-10  absolute top-8 left-[130px] sm:left-[215px] md:top-10 lg:w-[50%] lg:top-16 lg:left-56 xl:left-64">
            <div className="flex items-center space-x-1">
              <div className="text-white text-[24px] lg:text-3xl 2xl:text-4xl font-bold font-inter truncate w-[72%] sm:w-max sm:whitespace-normal">
                {storeData && storeData.store_Name}
              </div>
              <img
                loading="lazy"
                src={VegIcon}
                alt="Veg"
                className="xl:w-auto xl:h-5"
              />
            </div>
            <div className="flex sm:space-x-2 -mt-[4px] sm:mt-0 items-center lg:pt-[8px] lg:pb-2">
              <div className="bg-white rounded-full sm:py-1 sm:px-1 lg:py-2 lg:px-2 hidden sm:block">
                <Location className="fill-orange-400 flex mx-auto sm:h-3 sm:w-3 lg:h-5 lg:w-5 2xl:h-6 2xl:w-6 " />
              </div>
              <div className="w-52 bottom-[5px] sm:w-full text-white text-[12px] lg:text-[17px] 2xl:text-2xl  font-medium font-inter">
                {/* truncate */}
                {storeData && storeData.store_Address_Line_1 + ","}
                {storeData && storeData.store_Address_Line_2 + ","}
                {storeData && storeData.store_County}
              </div>
            </div>
            <div className="flex items-center gap-x-2 pb-2 text-white font-medium">
              <PhoneIcon width={15} height={15} />
              {storeData && storeData?.store_Telephone}
            </div>
            {/* Rating and timing For MOBILE VIEW */}
            <div className="sm:hidden pt-2">
              {!storeData?.storerating?.feedbackCount && (
                <div className="flex mt-1 items-center">
                  <div className="w-[45px] h-[17px] bg-green-shade-3 rounded-full justify-center items-center gap-[3px] inline-flex">
                    <div className="flex space-x-1 items-center">
                      <div className="text-white text-xxs font-semibold font-inter">
                        {storeData && storeData?.storerating?.Rating}
                      </div>
                      <StarIcon className="text-white" width={10} height={10} />
                    </div>
                  </div>
                  <div className="text-stone-500 text-xs ml-1 font-normal font-inter">
                    {storeData?.storerating?.feedbackCount}{" "}
                    {storeData?.storerating?.feedbackCount > 1
                      ? "+ Reviews"
                      : "+ Review"}
                  </div>
                </div>
              )}
            </div>
            {/* TAB VIEW , WEBVIEW */}
            <div className="hidden sm:block w-full lg:w-[80%] bg-white h-[2px]"></div>
            <div className="hidden sm:block lg:flex lg:space-x-5 lg:w-[50%]">
              <div className="flex space-x-4 pt-3 ">
                <div className="w-max">
                  {storeData?.store_Open_Status !== "Add" ? (
                    <div className="text-white text-xs sm:text-sm xl:text-lg w-max xl:w-max 2xl:text-2xl font-semibold font-inter">
                      Store Closed
                      {storeData?.store_Open_Time != "" && (
                        <div className="flex space-x-1 items-center w-full">
                          <Clock className="fill-white w-4 h-4" />
                          <div className="text-white text-xs sm:text-sm xl:text-xl font-medium font-inter w-max">
                            Opens at {storeData && storeData.store_Open_Time}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="text-white text-xs sm:text-sm xl:text-lg w-max xl:w-max 2xl:text-2xl font-semibold font-inter">
                      Store Open
                    </div>
                  )}
                </div>
                <div className="border-r-2 h-10 opacity-40  border-white sm:mt-2 sm:px-2 2xl:h-16" />
                <div className="">
                  <div className="flex items-center space-x-1 lg:space-x-2">
                    <Star className="text-white w-4 h-4 fill-white" />
                    <div className="text-white w-max text-xs sm:text-sm xl:text-xl xl:w-max font-semibold font-inter">
                      {storeData && storeData?.storerating?.Rating}
                    </div>
                  </div>
                  <div className="text-white w-max text-[10px] sm:text-xs xl:text-sm 2xl:text-lg xl:w-max font-medium font-inter pr-1">
                    {storeData && storeData?.storerating?.feedbackCount}+
                    {storeData?.storerating?.feedbackCount > 1
                      ? " Reviews"
                      : " Review"}
                  </div>
                </div>
              </div>
              {storeData?.store_Open_Delivery == 1 ? (
                <div className="hidden lg:block border-r-2 h-10 2xl:h-16  opacity-40 mt-5 border-white" />
              ) : null}
              <div className="flex space-x-4 pt-3 ">
                {storeData?.store_Open_Delivery == 1 ? (
                  <div className="w-max">
                    <div className="flex space-x-1 items-center">
                      <ClockIcon className="h-3 w-3 sm:h-4 sm:w-4 text-white" />
                      <div className="text-white text-xs sm:text-sm lg:text-sm xl:text-xl font-semibold font-inter">
                        {storeData?.store_Preorder == "Yes" &&
                        storeData?.store_Open_Delivery_Time != ""
                          ? "Pre Order"
                          : `${storeData.store_Delivery_Time} Mins`}
                      </div>
                    </div>
                    <div className="text-white text-[10px] sm:text-xs xl:text-sm 2xl:text-lg font-medium font-inter">
                      {storeData?.store_Preorder == "Yes" &&
                      storeData?.store_Open_Delivery_Time != ""
                        ? "Delivery"
                        : `Delivery Time`}
                    </div>
                  </div>
                ) : null}

                {storeData.store_Open_Collection == 1 ? (
                  <div className="border-r-2 h-10 2xl:h-16 opacity-40 mt-2 border-white sm:px-2 xl:px-2 2xl:px-4" />
                ) : null}
                {storeData.store_Open_Collection == 1 ? (
                  <div className="">
                    <div className="flex space-x-1 items-center">
                      <ClockIcon className="h-3 w-3 sm:h-4 sm:w-4 text-white" />
                      <div className="text-white w-max text-xs sm:text-sm lg:text-sm xl:text-xl xl:w-max font-semibold font-inter">
                        {storeData.store_Preorder == "Yes" &&
                        storeData.store_Open_Collection_Time != ""
                          ? "Pre Order"
                          : `${storeData.store_Collection_Time} Mins`}
                      </div>
                    </div>
                    <div className="text-white w-max text-[10px] sm:text-xs font-medium xl:text-sm 2xl:text-lg xl:w-max font-inter">
                      {storeData.store_Preorder == "Yes" &&
                      storeData.store_Open_Collection_Time != ""
                        ? "Collection"
                        : "Collection Time"}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {isLoggedin && offerdata && offerdata?.common_coupon?.length !== 0 ? (
          <div className="hidden sm:block absolute -top-2 right-7 md:right-8 lg:right-12 xl:right-[60px] ">
            <div className="flex justify-start items-start relative w-max">
              <img
                loading="lazy"
                src={bannerAngle}
                alt=""
                className=" absolute -left-2"
              />
              <img
                loading="lazy"
                src={banner2}
                alt=""
                className="block h-52	 w-auto  lg:hidden "
              />
              <img
                loading="lazy"
                src={banner2lg}
                className="hidden lg:block h-60 w-auto"
              />
              <div className="absolute translate-x-[20%] md:translate-x-3 lg:translate-x-6 top-3 lg:top-4">
                <div className="text-white text-[39.94px] lg:text-6xl font-normal font-babes-neue">
                  Offers
                </div>
                <div className="space-y-2 pt-1 h-28 overflow-y-auto cursor-pointer">
                  {offerdata &&
                    offerdata.common_coupon?.map((item, index) => (
                      <div
                        key={item.common_coupon_Id}
                        className={
                          index === currentOfferIndex ? "slide-in" : "hidden"
                        }
                      >
                        <Icon
                          icon="OfferFilled"
                          color="#FF0A38"
                          className="w-5 h-5 2xl:w-6 2xl:h-6 text-primary"
                        />
                        <span className="text-white text-xs md:text-sm 2xl:text-lg font-medium font-inter pl-1 lg:pl-2">
                          {item.common_coupon_Heading}
                          <br />
                          <span className="pl-5 lg:pl-7 2xl:pl-8 text-xs md:text-sm 2xl:text-lg">
                            Use code
                          </span>
                        </span>
                        <br></br>
                        <span
                          className="text-orange-500 text-xs md:text-sm 2xl:text-lg font-extrabold font-inter pl-5 lg:pl-7 2xl:pl-8"
                          onClick={(e) => {
                            const value = e.target.textContent;
                            handleCopy(value);
                          }}
                        >
                          {item.common_coupon_Barcode}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MenuBanner;
