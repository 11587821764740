import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import HomeFooter from "./HomeFooter";
import HomeHeader from "./HomeHeader";
import CategoryCard from "../../components/Cards/CategoryCard";
import CuisineCard from "../../components/Cards/CuisineCard";
import ImageSlider from "../../components/Navigation/ImageSlider";
import BestSellerCard from "../../components/Cards/BestSellerCard";
import TakeAwayCard from "../../components/Cards/TakeAwayCard";
import filterCross from "../../assets/images/filtercross.png";
import NoDataFound from "../../assets/images/no-data-found/nodata-home/nodata.png";
import Banner from "../../components/Elements/Banner";
import {
  getDineConfigSession,
  setSsipSession,
  setDineConfigSession,
  getLocationsSessions,
  setLocationsSessions,
  removeAllSpaceFromString,
  getRecentAddressId,
} from "../../utils";
import { getStoreData, getSearchFilter } from "../../services";
import Layout from "../../layout/Layout";
import logo_cover from "../../assets/images/default_images/logo_cover.jpg";
import { checkEmpty, copyObject } from "../../utils/helper";
import { LandingPage, MenuPage, HomePage } from "../../routes/RoutesPath";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomeLoader from "../../components/Loader/HomeLoader/HomeLoader";
import LazyLoadImageComponent from "../../components/Image/LazyLoadImage";
import {
  setStoreData,
  setFilterItems,
  setSearchItems,
  setHomeLoader,
  setTakeAwayOpen,
  setTakeAwayClosed,
  setLocationData,
} from "../../store/basicData/basicDataSlice";
import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";
import { useSelector, useDispatch } from "react-redux";
import { error } from "../../components/notify";
import useAnalyticsEvents from "../../hooks/useAnalyticsEvents";
import { Helmet } from "react-helmet";

const Home = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigation = useNavigate();
  const { progressFunction } = useProgress();
  const dineConfigSession = getDineConfigSession();

  const filterItems = useSelector((state) => state?.basicdata?.filterItems);
  const searchItems = useSelector((state) => state?.basicdata?.searchItems);
  const filter = useSelector((state) => state.basicdata.filter);
  const isLoader = useSelector((state) => state?.basicdata?.homeLoader);
  const pageContent = useSelector((state) => state?.basicdata?.pageContent);
  const takeawayOpenList = useSelector(
    (state) => state?.basicdata?.takeAwayOpen
  );
  const takeawayClosedList = useSelector(
    (state) => state?.basicdata?.takeAwayClosed
  );

  const [filterPath, setfilterPath] = useState("");
  const [filterList, setFilterList] = useState({});
  const [postcode, setPostcode] = useState(
    dineConfigSession?.postcode == undefined
      ? "ST64EP"
      : dineConfigSession?.postcode
  );
  const [isLoading, setIsLoading] = useState(isLoader);
  const [intervalData, setintervalData] = useState(0);
  const [isLayoutVisible, setIsLayoutVisible] = useState(true);
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const metaDescription = document.querySelector('meta[name="description"]');
  const metaOgDescription = document.querySelector(
    'meta[property="og:description"]'
  );
  const metaOgTitle = document.querySelector('meta[property="og:title"]');

  let searchWord = "";
  const analyticsEvents = useAnalyticsEvents();

  useEffect(() => {
    if (removeAllSpaceFromString(postcode?.toLowerCase() == "st64ep")) {
      setDineConfigSession({
        postcode: "ST64EP",
        heading_img: "",
        locationData: {
          latitude: "53.048988",
          longitude: "-2.198013",
          cityName: "Stoke-on-trent",
          street: "Scotia Road",
          county: "",
          frm: "addr",
          postcodePretty: "st6 4ep",
        },
      });
    }
    window.scrollTo(0, 0);
    checkSession();
    let city = dineConfigSession.locationData.cityName;
    if (checkEmpty(postcode)) {
      document.title = `Food Takeaway and Delivery - Order from ${
        import.meta.env.VITE_PRODUCT_NAME
      }`;
      metaOgTitle.setAttribute(
        "content",
        `Food Takeaway and Delivery - Order from ${
          import.meta.env.VITE_PRODUCT_NAME
        }`
      );
      metaDescription.setAttribute(
        "content",
        `Find Your Favourite food & Order Now. From English to Italian, Indian to Fast food, we have all your Favourite Cuisnes - Order from ${
          import.meta.env.VITE_PRODUCT_NAME
        }`
      );
      metaOgDescription.setAttribute(
        "content",
        `Find Your Favourite food & Order Now. From English to Italian, Indian to Fast food, we have all your Favourite Cuisnes - Order from ${
          import.meta.env.VITE_PRODUCT_NAME
        }`
      );
    } else {
      document.title = `Food Takeaway and Delivery in ${postcode} - Order from ${
        import.meta.env.VITE_PRODUCT_NAME
      }`;
      metaOgTitle.setAttribute(
        "content",
        `Food Takeaway and Delivery in ${postcode} - Order from ${
          import.meta.env.VITE_PRODUCT_NAME
        }`
      );
      metaDescription.setAttribute(
        "content",
        `Find Your Favourite food & Order Now in ${city} - ${postcode} From English to Italian, Indian to Fast food, we have all your Favourite Cuisnes - Order from ${
          import.meta.env.VITE_PRODUCT_NAME
        }`
      );
      metaOgDescription.setAttribute(
        "content",
        `Find Your Favourite food & Order Now in ${city} - ${postcode} From English to Italian, Indian to Fast food, we have all your Favourite Cuisnes - Order from ${
          import.meta.env.VITE_PRODUCT_NAME
        }`
      );
    }
  }, []);

  useEffect(() => {
    if (isLayoutVisible) {
      let count = 0;
      const intervalVariable = setInterval(() => {
        if (count < 90) {
          dispatch(setProgressData(count));
        } else if (count >= 90) {
          dispatch(setProgressData(90));
          clearInterval(intervalVariable);
        }
        count = count + 10;
      }, 10);

      setintervalData(intervalVariable);
      progressFunction(intervalVariable);
    } else {
      clearInterval(intervalData);
      dispatch(setProgressData(100));
    }
  }, [isLayoutVisible]);

  useEffect(() => {
    const paths = location?.pathname?.split("/");
    setPostcode(removeAllSpaceFromString(paths[2]));
    if (removeAllSpaceFromString(postcode?.toLowerCase() === "st64ep")) {
      setDineConfigSession({
        postcode: "ST64EP",
        heading_img: "",
        locationData: {
          latitude: "53.048988",
          longitude: "-2.198013",
          cityName: "Stoke-on-trent",
          street: "Scotia Road",
          county: "",
          frm: "addr",
          postcodePretty: "st6 4ep",
        },
      });
    }

    window.scrollTo(0, 0);
    if (currentPath !== location.pathname) {
      if (!checkIfAllFiltersUnchecked(filterItems)) {
        getDataFromFilter();
      } else {
        getData();
      }
      setCurrentPath(location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (filter?.length) {
      let res = {};
      filter?.forEach((val) => {
        res[val.key] = [];
        val.data.forEach((v) => {
          res[val.key].push({ id: v.Id, checked: false, name: v.Name });
        });
      });
      setFilterList(res);
    }
  }, [filter]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (!checkEmpty(filterItems)) {
      if (!checkIfAllFiltersUnchecked(filterItems)) {
        dispatch(setSearchItems(""));
        setfilterPath("");
        if (!checkEmpty(filterItems)) {
          getDataFromFilter();
        } else {
          getData();
        }
      }
    }
  }, [filterItems]);

  const locationAnalytics = (Postcode = "") => {
    if (!checkEmpty(Postcode)) {
      analyticsEvents("FindLocation", {
        page_path: location.pathname,
        postcode: Postcode,
      });
    }
  };

  const checkSession = () => {
    const data = getDineConfigSession();
    if (id === undefined) {
      if (!data && data != null) {
        navigation(LandingPage);
      } else {
        getData();
      }
    } else {
      getData();
    }
  };

  const getData = async () => {
    dispatch(setHomeLoader(true));
    setSearchItems("");
    try {
      const pathname = location.pathname;
      const pathParts = pathname.split("/");
      if (pathParts.includes("takeaways")) {
        const searchWordIndex = pathParts.indexOf("takeaways") + 1;
        searchWord = pathParts[searchWordIndex];
        dispatch(setHomeLoader(true));
        getDataFromFilter();
      } else {
        const { status, data } = await getStoreData(pathParts[2]);
        if (status === 200) {
          if (checkEmpty(data.locationData)) {
            dispatch(setLocationData({}));
            error(data.msg);
          } else {
            dispatch(setLocationData(data.locationData));
          }
          setDineConfigSession({
            heading_img: "",
            postcode: removeAllSpaceFromString(pathParts[2]),
            locationData: data.locationData,
          });

          locationAnalytics(removeAllSpaceFromString(pathParts[2]));

          const addressId = getRecentAddressId();
          if (checkEmpty(addressId)) {
            dispatch(setLocationData(data.locationData));
            setLocationArrayFun(data.locationData);
          }
          if (
            data?.pageContent?.dineorder_dataview != undefined ||
            data?.pageContent?.dineorder_dataview != ""
          ) {
            dispatch(setStoreData(data?.pageContent?.dineorder_dataview));
            const nearestCount =
              data?.pageContent?.dineorder_dataview.find(
                (section) => section.dineorder_dataview_Type === "Nearest"
              )?.data.length || 0;
            dispatch(setTakeAwayOpen(nearestCount));
            const closedCount =
              data?.pageContent?.dineorder_dataview.find(
                (section) => section.dineorder_dataview_Type === "Closed"
              )?.data.length || 0;
            dispatch(setTakeAwayClosed(closedCount));
          }
        } else {
          throw new Error("Something went wrong!! Please try again");
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      dispatch(setHomeLoader(false));
    }
  };

  const getDataFromFilter = async () => {
    dispatch(setHomeLoader(true));
    setIsLayoutVisible(true);
    const pathname = location.pathname;
    const pathParts = pathname.split("/");
    let postCode = "";
    if (pathParts.includes("takeaways")) {
      const dineConfigSession = getDineConfigSession();
      postCode =
        dineConfigSession?.postcode === undefined
          ? "ST64EP"
          : dineConfigSession?.postcode;
    } else {
      postCode = pathParts[2];
    }
    const apiPayload = {
      postcode: postCode,
      searchLat: "",
      searchLon: "",
      tags: [],
      offers: [],
      cuisines: [],
      searchText: searchWord.toLowerCase(),
    };
    locationAnalytics(postCode);
    if (!checkEmpty(filterItems)) {
      filter.forEach((val) => {
        const { key } = val;
        apiPayload[key] = [];
        filterItems[key]?.forEach((value) => {
          if (value.checked) {
            apiPayload[key].push(value.id);
          }
        });
      });
    }
    const { status, data } = await getSearchFilter(apiPayload);
    dispatch(setHomeLoader(false));
    setIsLayoutVisible(false);
    if (status == 200) {
      const addressId = getRecentAddressId();
      if (checkEmpty(addressId)) {
        dispatch(setLocationData(data.locationData));
        setLocationArrayFun(data.locationData);
      }
      dispatch(setLocationData(data.locationData));
      setDineConfigSession({
        heading_img: "",
        postcode: removeAllSpaceFromString(postCode),
        locationData: data.locationData,
      });
      data.storeList.map((val) => {
        if (val.heading == "Nearest") {
          setIsLoading(false);
          setfilterPath(searchWord);
          dispatch(setStoreData(data.storeList));
          dispatch(setSearchItems(searchWord));
          const nearestCount =
            data.storeList.find((section) => section.heading === "Nearest")
              ?.data.length || 0;
          dispatch(setTakeAwayOpen(nearestCount));

          const closedCount =
            data.storeList.find((section) => section.heading === "Closed")?.data
              .length || 0;
          dispatch(setTakeAwayClosed(closedCount));
          dispatch(setHomeLoader(false));
        }
      });
    } else {
      throw new Error("API call was not successful");
    }
  };

  const setLocationArrayFun = async (value) => {
    let locationArray = getLocationsSessions();

    if (locationArray?.length > 0) {
      const isExist = locationArray.findIndex(
        (val) =>
          removeAllSpaceFromString(val.postcodePretty).toLowerCase() ===
          removeAllSpaceFromString(value.postcodePretty).toLowerCase()
      );
      if (isExist >= 0) {
        locationArray.splice(isExist, 1);
        locationArray.unshift(value);
      } else {
        if (locationArray.length === 3) {
          locationArray.pop();
          locationArray.unshift(value);
        } else {
          locationArray.unshift(value);
        }
      }
      setLocationsSessions(locationArray);
    } else {
      setLocationsSessions([value]);
    }
  };

  const handleBannerClick = (value) => {
    setSsipSession(value.store_Config.ssip);
    navigation(MenuPage(value.store_Slug, value.product_Id));
  };

  const handleDeleteFilter = async (key, id) => {
    dispatch(setHomeLoader(true));
    try {
      let res = copyObject(filterItems);
      const itemIndex = res[key].findIndex((value) => value.id === id);
      if (itemIndex !== -1 && res[key][itemIndex].checked) {
        res[key][itemIndex].checked = false;
        res[key][itemIndex].timestamp = null; // Remove timestamp when unchecked
      }
      dispatch(setFilterItems(res));
      // if (!checkIfAllFiltersUnchecked(res)) {
      //   const apiPayload = {};
      //   apiPayload.postcode = postcode;
      //   apiPayload.searchLat = "";
      //   apiPayload.searchLon = "";
      //   apiPayload.tags = [...new Set(res?.tags)];
      //   apiPayload.offers = [...new Set(res?.offers)];
      //   apiPayload.cuisines = [...new Set(res?.cuisines)];
      //   filter.forEach((val) => {
      //     const { key } = val;
      //     apiPayload[key] = [];
      //     res[key]?.forEach((value) => {
      //       if (value.checked) {
      //         apiPayload[key].push(value.id);
      //       }
      //     });
      //   });
      //   const { status, data } = await getSearchFilter(apiPayload);
      //   if (status === 200) {
      //     dispatch(setStoreData(data?.storeList));
      //     const nearestCount =
      //       data?.storeList.find((section) => section.heading === "Nearest")
      //         ?.data.length || 0;
      //     dispatch(setTakeAwayOpen(nearestCount));
      //     const closedCount =
      //       data?.storeList.find((section) => section.heading === "Closed")
      //         ?.data.length || 0;
      //     dispatch(setTakeAwayClosed(closedCount));
      //   }
      // }
    } catch (error) {
      console.error("Error handling delete filter:", error);
    } finally {
      dispatch(setHomeLoader(false));
    }
  };

  const checkIfAllFiltersUnchecked = (filterItems) => {
    const allUnchecked = Object.values(filterItems).every((items) =>
      items.every((item) => !item.checked)
    );
    setIsLayoutVisible(allUnchecked);
    if (allUnchecked) {
      getData();
      return true;
    } else {
      return false;
    }
  };

  const searchTextFilterDelete = async () => {
    if (removeAllSpaceFromString(postcode?.toLowerCase() === "st64ep")) {
      setDineConfigSession({
        postcode: "ST64EP",
        heading_img: "",
        locationData: {
          latitude: "53.048988",
          longitude: "-2.198013",
          cityName: "Stoke-on-trent",
          street: "Scotia Road",
          county: "",
          frm: "addr",
          postcodePretty: "st6 4ep",
        },
      });
    }
    const pathname = location.pathname;
    const pathParts = pathname.split("/");
    setSearchItems("");
    setfilterPath("");
    dispatch(setSearchItems(""));
    if (pathParts.includes("takeaways")) {
      setTimeout(() => {
        navigation(
          HomePage + removeAllSpaceFromString(postcode?.toLowerCase())
        );
      }, 1000);
      setIsLayoutVisible(false);
    } else {
      setIsLayoutVisible(true);

      getData();
    }
  };

  return (
    <div>
      {isLoader ? (
        <HomeLoader
          subClassName={`grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4`}
          subsubClassName={`h-60`}
        />
      ) : (
        <div key="kuickhome" className="overflow-hidden font-[inter]">
          {isLayoutVisible && <HomeHeader />}
          <Layout className="mt-2">
            {!isLayoutVisible &&
              !checkEmpty(filterItems) &&
              Object.keys(filterItems)?.map((badge) => {
                const checkedItems = filterItems[badge]
                  .filter((val) => val.checked)
                  .sort(
                    (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
                  );

                return checkedItems.map((val) => {
                  return (
                    <div
                      key={"badge" + val.id} // needed so that the issue of repeating badge is not shown again
                      className="inline-flex w-max text-white items-center justify-center gap-x-2  rounded-md bg-primary text-sm mb-3 mr-2 "
                    >
                      <p className="mr-1 pl-3 py-2"> {val.name}</p>
                      <div
                        className="cursor-pointer w-6 py-2 pr-3"
                        onClick={() => {
                          handleDeleteFilter(badge, val.id);
                        }}
                      >
                        <img src={filterCross} alt="cross icon" />
                      </div>
                    </div>
                  );
                });
              })}

            {!isLayoutVisible &&
              !checkEmpty(searchItems) &&
              checkEmpty(filterPath) && (
                <div className="inline-flex w-max text-white items-center justify-center gap-x-2 rounded-md bg-primary text-sm mb-3 mr-2 ">
                  <p className="mr-1 pl-3 py-2 capitalize">{searchItems}</p>
                  <div
                    className="cursor-pointer w-6 py-2 pr-3"
                    onClick={() => {
                      searchTextFilterDelete();
                    }}
                  >
                    <img src={filterCross} alt="cross icon" />
                  </div>
                </div>
              )}

            {!isLayoutVisible && !checkEmpty(filterPath) && (
              <div className="inline-flex w-max text-white items-center justify-center gap-x-2 rounded-md bg-primary text-sm mb-3 mr-2 ">
                <p className="mr-1 pl-3 py-2 capitalize">{filterPath}</p>
                <div
                  className="cursor-pointer w-6 py-2 pr-3"
                  onClick={() => {
                    searchTextFilterDelete();
                  }}
                >
                  <img src={filterCross} alt="cross icon" />
                </div>
              </div>
            )}
          </Layout>

          {!isLayoutVisible && <div className="border-b border-divider"></div>}
          {pageContent &&
            Object.values(pageContent).map((val, ind) => {
              const {
                dineorder_dataview_Type,
                data,
                dineorder_dataview_Title,
                heading,
              } = val;
              const isExist = pageContent.findIndex(
                (val) =>
                  val.dineorder_dataview_Type === "Favourites" ||
                  val.dineorder_dataview_Type === "Popular"
              );
              if (
                dineorder_dataview_Type === "Cuisines" ||
                heading === "Cuisines"
              ) {
                /* Popular cusines start */
                return (
                  <React.Fragment key={"home++" + ind}>
                    <ImageSlider
                      key={dineorder_dataview_Type}
                      data={data}
                      renderItem={(item) => (
                        <div
                          key={"ImageSlider" + item.common_cuisine_Id}
                          className="carousel-item pt-5 pb-6 sm:pt-7 sm:pb-8 xl:py-8 2xl:pt-5 2xl:pb-2 hover:scale-105 transition duration-200"
                        >
                          <CuisineCard
                            key={"CuisineCard" + item.common_cuisine_Id}
                            image={item.common_cuisine_Icon}
                            title={item.common_cuisine_Name}
                            itemList={data}
                            postcode={postcode}
                            id={item.common_cuisine_Id}
                          />
                        </div>
                      )}
                      title={
                        <div className="sm:font-medium text-sm sm:text-[21px] xl:text-[26px] 3xl:text-[32px]">
                          <span className="font-semibold text-sm sm:text-[21px] xl:text-[26px] 3xl:text-[32px]">
                            {dineorder_dataview_Title}
                          </span>{" "}
                        </div>
                      }
                    />
                  </React.Fragment>
                );
              } else if (
                dineorder_dataview_Type === "Best Sellers" ||
                heading === "Best Sellers"
              ) {
                /* best seller start */
                return (
                  <React.Fragment key={"home++" + ind}>
                    <ImageSlider
                      className={
                        "bg-bg-stroke mt-2 pt-5 pb-2 sm:pt-7 sm:pb-2 xl:pb-2 2xl:pb-0 "
                      }
                      key={dineorder_dataview_Type}
                      data={data}
                      renderItem={(item) => {
                        return (
                          <div
                            key={"ImageSlider" + item.product_Id}
                            className="carousel-item pt-5 py-6 sm:pt-7 sm:pb-8 2xl:pt-5 2xl:pb-0 hover:scale-105 transition duration-200"
                          >
                            <BestSellerCard
                              image={item.img}
                              title={item.title}
                              description={item.description}
                              productId={item.product_Id}
                              postcode={postcode}
                              setIsLayoutVisible={setIsLayoutVisible}
                            />
                          </div>
                        );
                      }}
                      title={
                        <div className="text-sm sm:text-[22px] xl:text-[26px]  3xl:text-[32px]">
                          <span className="font-semibold text-sm sm:text-[22px] xl:text-[26px]  3xl:text-[32px]">
                            {dineorder_dataview_Title}
                          </span>
                        </div>
                      }
                    />
                  </React.Fragment>
                );
              } else if (
                dineorder_dataview_Type === "Slideshow" ||
                heading === "Slideshow"
              ) {
                return (
                  <React.Fragment key={"home++" + ind}>
                    <ImageSlider
                      key={dineorder_dataview_Type}
                      data={data}
                      renderItem={(v, ind) => (
                        <div
                          key={"ImageSlider" + ind}
                          className="relative cursor-pointer hover:scale-105 transition duration-200 pb-6 xl:pb-8 2xl:pb-10"
                          onClick={() => handleBannerClick(v)}
                        >
                          <LazyLoadImageComponent
                            loading="lazy"
                            src={v.store_Slide_Logo}
                            alt=""
                            className={`absolute top-8 left-2 xl:left-3 2xl:left-4 xl:top-9 2xl:top-[40px] rounded-lg w-10 h-10`}
                            onErrorSrc={logo_cover}
                            placeholderSrc={logo_cover}
                          />
                          <Banner
                            image={v.dineorder_slideshow_Image}
                            className="w-[328px] xl:w-[377px] 2xl:w-[435px] min-[1920px]:w-[585px] object-cover rounded-lg 2xl:rounded-[12px]"
                            imageClassName="w-[328px] xl:w-[377px] 2xl:w-[425px] min-[1920px]:w-[585px] h-[120] min-[1920px]:h-[274px] 2xl:h-[234px] object-cover rounded-lg 2xl:rounded-[12px]"
                          />
                        </div>
                      )}
                      title={
                        <div className="sm:font-medium text-sm sm:text-[21px] xl:text-[26px] 3xl:text-[32px]">
                          <span className="font-semibold text-sm sm:text-[21px] xl:text-[26px] 3xl:text-[32px]">
                            Offers
                          </span>{" "}
                        </div>
                      }
                    ></ImageSlider>
                  </React.Fragment>
                );
              } else if (
                dineorder_dataview_Type === "Nearest" ||
                heading === "Nearest"
              ) {
                return (
                  <React.Fragment key={"home++" + ind}>
                    {data && data.length > 0 ? (
                      <Layout
                        key={dineorder_dataview_Type}
                        className={`${
                          isExist !== -1 ? "bg-white-shade-1" : "bg-white"
                        } px-4 pt-6 pb-10 sm:px-7 lg:pl-10 2xl:px-16`}
                      >
                        <div className="flex justify-between items-center ">
                          <div className="font-semibold text-sm sm:text-[21px] xl:text-[24px] 3xl:text-[32px]">
                            {data.length} Open Takeaways
                          </div>
                        </div>

                        {/* open category card */}
                        <div className="pt-5 sm:pt-6 mx-auto grid grid-cols-1 justify-center space-y-4 sm:space-y-0 sm:gap-5 xl:gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 cursor-pointer">
                          {data.map((val, ind) => (
                            <TakeAwayCard
                              key={"takecard" + ind}
                              unique={val.product_Id}
                              className={"overflow-hidden"}
                              header={val.store_Name}
                              disabled={false}
                              val={val}
                              productId={val.product_Id}
                              onClick={() => handleBannerClick(val)}
                            />
                          ))}
                        </div>
                        {/* open category card */}
                      </Layout>
                    ) : null}
                  </React.Fragment>
                );
              } else if (
                dineorder_dataview_Type === "Recommend" ||
                heading === "Recommend"
              ) {
                return (
                  <React.Fragment key={"home++" + ind}>
                    <ImageSlider
                      key={dineorder_dataview_Type}
                      data={data}
                      className={``}
                      renderItem={(item, ind) => (
                        <div
                          key={"ImageSlider" + ind}
                          className="carousel-item pt-5 pb-6  sm:pt-7 sm:pb-8 xl:py-8 2xl:pt-5 2xl:pb-2 hover:scale-105 transition duration-200"
                        >
                          <CategoryCard
                            image={item.img}
                            title={item.title}
                            description={item.desc}
                            postcode={postcode}
                            setIsLayoutVisible={setIsLayoutVisible}
                            className={`mt-12 sm:mt-20 cursor-pointer`}
                          />
                        </div>
                      )}
                      title={
                        <div className="font-semibold text-sm sm:text-[21px] xl:text-[26px] 3xl:text-[32px]">
                          Explore By {dineorder_dataview_Type}
                        </div>
                      }
                    />
                  </React.Fragment>
                );
              } else if (
                dineorder_dataview_Type === "Closed" ||
                heading === "Closed"
              ) {
                return (
                  <React.Fragment key={"home++" + ind}>
                    {data && data.length > 0 ? (
                      <Layout
                        key={dineorder_dataview_Type}
                        className={`${
                          isExist ? "bg-white" : "bg-white-shade-1"
                        } px-4 pt-6 sm:px-7 2xl:pb-20 lg:pl-10 2xl:px-16`}
                      >
                        <div className="flex justify-between items-center ">
                          <div className="font-semibold text-sm sm:text-[21px] xl:text-[26px] 3xl:text-[32px]">
                            {data.length} Closed Takeaways
                          </div>
                        </div>
                        {/* Closed takeaway card */}
                        <div className="pt-5 sm:pt-6 mx-auto grid grid-cols-1 justify-center space-y-4 sm:space-y-0 sm:gap-5 xl:gap-4 sm:grid sm:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4">
                          {data.map((val, ind) => (
                            <TakeAwayCard
                              key={"takecard" + ind}
                              unique={val.product_Id}
                              header={val.store_Name}
                              disabled={true}
                              val={val}
                              productId={val.product_Id}
                              subclassName={`hidden shadow-none`}
                            />
                          ))}
                        </div>
                        {/* closed takeaway card */}
                      </Layout>
                    ) : null}
                  </React.Fragment>
                );
              } else if (dineorder_dataview_Type === "Favourites") {
                return (
                  <React.Fragment key={"fav__" + ind}>
                    {data && data.length > 0 ? (
                      <Layout
                        key={dineorder_dataview_Type}
                        className="bg-white px-4 pt-6 pb-10 sm:px-7 lg:pl-10 2xl:px-16"
                      >
                        <div className="flex justify-between items-center ">
                          <div className="font-semibold text-sm sm:text-[21px] xl:text-[24px] 3xl:text-[32px]">
                            {data.length} Favourites Takeaways
                          </div>
                        </div>

                        {/* open category card */}
                        <div className="pt-5 sm:pt-6 mx-auto grid grid-cols-1 justify-center space-y-4 sm:space-y-0 sm:gap-5 xl:gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 cursor-pointer">
                          {data.map((val, ind) => (
                            <TakeAwayCard
                              key={"takecard" + ind}
                              unique={val.product_Id}
                              className={"overflow-hidden"}
                              header={val.store_Name}
                              disabled={false}
                              val={val}
                              productId={val.product_Id}
                              onClick={() => handleBannerClick(val)}
                            />
                          ))}
                        </div>
                        {/* open category card */}
                      </Layout>
                    ) : null}
                  </React.Fragment>
                );
              } else if (dineorder_dataview_Type === "Popular") {
                return (
                  <React.Fragment key={"fav__" + ind}>
                    {data && data.length > 0 ? (
                      <Layout
                        key={dineorder_dataview_Type}
                        className="bg-white-shade-1 px-4 pt-6 pb-10 sm:px-7 lg:pl-10 2xl:px-16"
                      >
                        <div className="flex justify-between items-center ">
                          <div className="font-semibold text-sm sm:text-[21px] xl:text-[24px] 3xl:text-[32px]">
                            {data.length} Popular Takeaways
                          </div>
                        </div>

                        {/* open category card */}
                        <div className="pt-5 sm:pt-6 mx-auto grid grid-cols-1 justify-center space-y-4 sm:space-y-0 sm:gap-5 xl:gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 cursor-pointer">
                          {data.map((val, ind) => (
                            <TakeAwayCard
                              key={"takecard" + ind}
                              unique={val.product_Id}
                              className={"overflow-hidden"}
                              header={val.store_Name}
                              disabled={false}
                              val={val}
                              productId={val.product_Id}
                              onClick={() => handleBannerClick(val)}
                            />
                          ))}
                        </div>
                        {/* open category card */}
                      </Layout>
                    ) : null}
                  </React.Fragment>
                );
              }
            })}

          {takeawayOpenList === 0 && takeawayClosedList === 0 && (
            <Layout className="flex justify-center items-center">
              <img
                src={NoDataFound}
                className="w-1/2 -mt-10"
                alt=""
                srcSet=""
              />
            </Layout>
          )}

          <HomeFooter />
        </div>
      )}
    </div>
  );
};

export default Home;
