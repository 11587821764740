import { useDispatch, useSelector } from "react-redux";
import { formatTotal } from "../../utils";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import Button from "../Elements/Button";
import { setCartConfigSession } from "../../utils";
import { setBasketData } from "../../store/basicData/basicDataSlice";
import { success } from "../notify";
import {
  insertProductToCart,
  actBasketProduct,
  deleteBasketComponent,
} from "../../services";

const CartItem = ({ value, className, isPayment }) => {
  const dispatch = useDispatch();
  const currency = useSelector(
    (state) => state?.basicdata?.storedetails?.store_Currency
  );

  const handleQtyChange = async (data_Act) => {
    const { status, data } = await actBasketProduct({
      data_Id: value.pos_basketproduct_Id,
      data_Act,
    });
    if (status === 200) {
      if (data.status === "success") {
        success(data?.msg, true);
        setCartConfigSession(data?.cartConfig);
        dispatch(setBasketData(data?.basket));
      }
    }
  };

  return (
    <div className={`space-y-2 ${className} `}>
      <div className="flex justify-between items-center">
        <div className="w-3/5 text-black-shade-4 text-sm mdl:text-xs xl:text-mdl font-medium font-inter leading-tight pr-2 break-words">
          {value?.pos_basketproduct_Name}
        </div>
        <div className="w-2/5 flex items-center justify-end gap-x-1 xl:gap-x-4">
          <div className="flex space-x-0.5 items-center 2xl:space-x-1 ">
            {!isPayment && (
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  handleQtyChange("dec");
                }}
              >
                <div className="border rounded-lg px-1 py-1 w-min ">
                  <MinusIcon className="w-3 h-3 xl:w-5 xl:h-5 text-center" />
                </div>
              </Button>
            )}
            <div className="text-sm mdl:text-xs xl:text-sm flex items-center justify-center w-7 h-7 font-medium text-center rounded-lg px-3 py-2 text-black-shade-6">
              {value?.pos_basketproduct_Quantity}
            </div>
            {!isPayment && (
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  handleQtyChange("inc");
                }}
              >
                <div className="border rounded-lg px-1 py-1 w-min ">
                  <PlusIcon className="w-3 h-3 xl:w-5 xl:h-5 text-center" />
                </div>
              </Button>
            )}
          </div>
          <div className="text-black-shade-4 text-end w-16 mdl:w-20 text-sm mdl:text-xs xl:text-mdl font-medium font-inter leading-normal">
            {currency}
            {formatTotal(Number(value?.pos_basketproduct_Total_Price))}
          </div>
        </div>
      </div>
      {/* extras */}
      <div className="flex flex-col">
        {value?.basketProductlines?.map((val, ind) => {
          if (
            value?.pos_basketproduct_Name !== val?.pos_basketproductline_Name
          ) {
            return <ExtraItem key={val.pos_basketproductline_Id} data={val} />;
          }
          return val?.basketComponents?.map((bp) => {
            return (
              <ExtraItem key={bp.pos_basketcomponent_Id} data={bp} isRemove />
            );
          });
        })}
      </div>
    </div>
  );
};

export default CartItem;

const ExtraItem = ({ data, isRemove }) => {
  const dispatch = useDispatch();
  const removeItem = async (dataId) => {
    const { status, data } = await deleteBasketComponent(dataId);
    if (status === 200) {
      if (data.status === "success") {
        // update basket data and cartconfig
        dispatch(setBasketData(data?.basket));
        setCartConfigSession(data?.cartConfig);
      } else if (data.status === "error") {
      }
    }
  };

  return (
    <div className="my-2">
      <div className="flex justify-between items-center ">
        <div className="text-gray-500 text-sm font-normal font-inter leading-normal">
          {isRemove && (
            <Button
              onClick={() => {
                removeItem(data.pos_basketcomponent_Id);
              }}
              className="text-rose-600 text-xs font-normal font-inter leading-tight mr-4"
            >
              Remove
            </Button>
          )}
          <label className="text-sm mdl:text-xs xl:text-sm">
            {data?.pos_basketproductline_Name}
            {data?.pos_basketcomponent_Name}
          </label>
        </div>
        <div className="text-slate-950 text-sm mdl:text-xs xl:text-sm font-normal font-inter leading-normal">
          £{data?.pos_basketproductline_Total_Price}
          {data?.pos_basketcomponent_Total_Price}
        </div>
      </div>
      <div>
        {data?.basketComponents?.map((bp, ind) => {
          return (
            <ExtraItem key={bp.pos_basketcomponent_Id} data={bp} isRemove />
          );
        })}
      </div>
    </div>
  );
};
