import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setProgressData } from "../store/progressData/progressDataSlice";

export const useProgress = () => {
  const dispatch = useDispatch();

  const [progressData, setprogressData] = useState(
    useSelector((state) => state.progressdata.progress)
  );
  const [id, setId] = useState(0);

  const progressFunction = (e) => {
    setId(e);
    if (progressData === 100) {
      setprogressData(0);
      dispatch(setProgressData(0));
    }
  };
  return { progressFunction };
};
