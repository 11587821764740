import { useRef, useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import CrossButton from "../Elements/CrossButton";
import InputSearch from "../Elements/InputSearch";
import LocationLocator from "../Elements/LocationLocator";
import locationIcon from "../../assets/images/locationIcon.png";
import searchNodata from "../../assets/images/nodatasearch.png";

import Card from "../Cards/Card";
import RadioButton from "../Elements/RadioButton";
import Location from "../../assets/images/drawerImages/location.png";
import Home from "../../assets/images/drawerImages/home.png";
import Office from "../../assets/images/drawerImages/briefcase.png";
import clock from "../../assets/images/drawerImages/clock.png";
import TakeAwayCard from "../Cards/TakeAwayCard";
import { setDefaultAddress } from "../../store/userData/userDataSlice";
import Search from "../Icon/Search";
import { useNavigate } from "react-router-dom";
import SearchData from "../Elements/SearchData";
import { error } from "../../components/notify";
import { checkEmpty, formatPostcode } from "../../utils/helper";
// const isLoggedin = useSelector((state) => state.userdata.loggedIn);

import {
  removeAllSpaceFromString,
  setDineConfigSession,
  getStoresSessions,
  setSsipSession,
  getLocationsSessions,
  setRecentAddressId,
  getRecentAddressId,
  postcodeRegEx,
} from "../../utils";

import {
  searchStore,
  getLocationFromLatLng,
  sendLinkSMS,
} from "../../services";

import { HomePage, MenuPage } from "../../routes/RoutesPath";

import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";
import DrawerHeader from "./DrawerHeader";
import { number } from "prop-types";
import DownArrowLeftChevron from "../Icon/DownArrowLeftChevron";

function HeaderDrawer({ isDrawerOpen, isOpen, onClose }) {
  const dispatch = useDispatch();
  const drawerRef = useRef(null);
  const navigation = useNavigate();
  const { progressFunction } = useProgress();
  const [visibleCount, setVisibleCount] = useState(3);
  const [isFakeLoader, setIsFakeLoader] = useState(false);
  const userAddres = useSelector((state) => state.userdata.address);
  const [userAddress, setuserAddres] = useState(userAddres?.address);

  const [searchData, setSearchData] = useState([]);
  const [stores, setStores] = useState([]);
  const [inputValue, setInputValue] = useState({ type: "search", value: "" });
  const [isInputVisible, setInputVisible] = useState(false);
  const options = ["Home", "Work", "Others"];
  const [listHeight, setListHeight] = useState("auto");
  const [noDataMessage, setNoDataMessage] = useState("");
  const [intervalData, setintervalData] = useState(0);
  const [isLoad, setIsLoading] = useState(false);
  const [defaultSelected, setDefaultSelected] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null); // State to hold selected address details
  const [postcodes, setpostcodes] = useState([]);
  const [showLess, setShowLess] = useState(false);
  const myRef = useRef(null);

  const handleAddressClick = async (val) => {
    window.scrollTo(0, 0);
    let locationArray = {
      postcode: val.common_customer_address_Postcode,
      cityName: val.common_customer_address_City,
      street: val.common_customer_address_Line_2,
    };
    dispatch(setDefaultAddress(val));
    setRecentAddressId(val?.common_customer_address_Id);
    setDefaultSelected(val?.common_customer_address_Id);
    // Save selected address to localStorage
    const pincode = val?.common_customer_address_Postcode?.toLowerCase();
    navigation(`/stores/${removeAllSpaceFromString(pincode.toLowerCase())}`);
    onClose();
    setIsLoading(true);
    setSelectedAddress(val); // Update selected address details

    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  };

  useEffect(() => {
    // On component mount, check if there's a selected address in localStorage
    const storedSelectedAddress = getRecentAddressId();
    if (storedSelectedAddress) {
      const removedItem = userAddress.find(
        (vals) =>
          vals.common_customer_address_Id === Number(storedSelectedAddress)
      );

      const updatedAddresses = userAddress.filter(
        (vals) =>
          vals.common_customer_address_Id !== Number(storedSelectedAddress)
      );

      updatedAddresses.unshift(removedItem);
      setuserAddres(updatedAddresses);

      setDefaultSelected(storedSelectedAddress);
    }
  }, []);

  useEffect(() => {
    if (isLoad) {
      let count = 0;
      const intervalVariable = setInterval(() => {
        if (count < 90) {
          dispatch(setProgressData(count));
        } else if (count >= 90) {
          dispatch(setProgressData(100));
        }
        count = count + 10;
      }, 10);

      setintervalData(intervalVariable);
      progressFunction(intervalVariable);
    } else {
      dispatch(setProgressData(100));
      window.clearInterval(intervalData);
    }
  }, [isLoad]);

  useEffect(() => {
    if (getStoresSessions()) {
      setStores(getStoresSessions());
    }

    if (getLocationsSessions()) {
      setpostcodes(getLocationsSessions());
    }
  }, [isDrawerOpen]);

  const getAddressIcon = (type) => {
    if (type === "Home") return Home;
    else if (type === "Work") return Office;
    // else if (type === "Others")
    return Location;
    // return "";
  };

  // const handleInputChange = (e) => {
  //   setPinCode(e.target.value);
  // };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        setSearchData([]);
        setNoDataMessage("");
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [drawerRef]);

  useEffect(() => {
    if (inputValue?.type === "detect") {
      if (postcodeRegEx.test(inputValue))
        debouncedFetchStores(inputValue?.value);
    } else {
      debouncedFetchStores(inputValue?.value);
    }
  }, [inputValue]);

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const fetchStores = async (value) => {
    if (value.trim().length >= 4) {
      setIsLoading(true);
      const { status, data } = await searchStore(value);
      if (status === 200) {
        const searchResults = [...data.postcodeList, ...data.storeList];
        setSearchData(searchResults);
        if (searchResults.length === 0) {
          setNoDataMessage("No data available");
        } else {
          setNoDataMessage("");
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setSearchData([]);
        setNoDataMessage("No data available");
      }
    } else {
      setIsLoading(false);
      setSearchData([]);
      setNoDataMessage("");
    }
  };

  const debouncedFetchStores = useCallback(debounce(fetchStores, 300), []);

  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue({ type: "search", value });
  };

  const toggleInputVisibility = () => {
    setTimeout(() => {
      setInputVisible(!isInputVisible);
      setNoDataMessage("");
    }, 100);
  };

  const handleSelection = async (val) => {
    if (val && typeof val === "object") {
      setRecentAddressId();
      if (val.postcode !== undefined) {
        const postcode = val.postcode;
        navigation(
          `/stores/${removeAllSpaceFromString(postcode?.toLowerCase())}`
        );
      } else {
        setSsipSession(val?.store_Config?.ssip || "");
        window.history.pushState(
          {},
          "",
          MenuPage(val.store_Slug, val.product_Id)
        );
        navigation(MenuPage(val.store_Slug, val.product_Id));
      }
      toggleInputVisibility();
      setSearchData([]);
      setInputValue({ type: "search", value: "" });
      onClose();
    } else {
      console.error("Invalid 'val' parameter:", val);
    }
  };

  const handleBannerClick = (value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // window.history.pushState(
    //   {},
    //   "",
    //   MenuPage(value?.store_Slug, value?.product_Id)
    // );
    // window.location.reload();
    navigation(MenuPage(value?.store_Slug, value?.product_Id));
    onClose();
  };

  const locationRedirectToStores = (id) => {
    setRecentAddressId();
    navigation(`/stores/${removeAllSpaceFromString(id.toLowerCase())}`);
    onClose();
  };

  const handleShowMore = () => {
    setIsFakeLoader(true);
    setTimeout(() => {
      setIsFakeLoader(false);
      setShowLess(true);
      setVisibleCount(userAddress.length);
    }, 1000);
  };

  const handleShowLess = () => {
    if (myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth" });
    }
    setShowLess(false);
    setVisibleCount(3);
  };

  useEffect(() => {
    const height = searchData.length * 70; // Assuming each item is approximately 50px in height
    setListHeight(height > 288 ? "18rem" : `${height}px`); // Limit height to 18rem (72)
  }, [searchData]);

  return (
    <div className="no-scrollbar" ref={myRef}>
      <DrawerHeader title={"Location"} onClose={onClose} />
      <div className="overflow-y-hidden overflow-drawer min-h-[250px]">
        <div className="rounded-md mt-0 border px-3 h-11 items-center flex dropdown w-[90%] m-auto">
          <input
            type="text"
            value={inputValue?.value}
            maxLength={40}
            placeholder="Search Postcode or Takeaway Name"
            className="w-full"
            autoComplete="new-password"
            onChange={handleChange}
          />
          {searchData.length > 0 ? (
            <div ref={drawerRef} className="w-full">
              <SearchData
                searchData={searchData}
                handleSelection={handleSelection}
                listHeight={listHeight}
                locationIcon={locationIcon}
              />
            </div>
          ) : (
            noDataMessage && (
              <div
                ref={drawerRef}
                className="absolute w-full left-0 top-11 z-10 bg-white rounded-md shadow-lg px-4 py-2 h-auto"
              >
                <div className="text-sm text-gray-700 flex justify-center items-center">
                  <img className="w-1/2" src={searchNodata} />
                </div>
                <p className="md:mt-5 mt-10 text-center">No data available </p>
              </div>
            )
          )}
        </div>
        <LocationLocator
          onFinished={(val) => {
            setInputValue({ type: "detect", value: val?.postcode });
          }}
          className="mt-3 rounded-md h-11 w-[89%] m-auto"
        />

        {userAddress?.length > 0 && userAddress ? (
          <>
            <div className="text-mdl font-inter font-semibold mt-5 mb-4 w-[90%] m-auto">
              Saved Address
            </div>
            <div className="w-[90%] m-auto h-auto">
              {userAddress.slice(0, visibleCount).map((val, ind) => {
                const checked =
                  val?.common_customer_address_Id === Number(defaultSelected);
                return (
                  <Card key={"card" + ind} className="mb-4">
                    <div
                      className="m-1 h-auto flex flex-row justify-evenly items-center"
                      onClick={() => handleAddressClick(val)}
                    >
                      <img
                        src={getAddressIcon(val?.common_customer_address_Label)}
                        className="w-6 h-6"
                        loading="lazy"
                      />
                      <div className="w-[70%] py-2">
                        <p className="capitalize text-sm text-black-shade-4 sm:text-md">
                          {val?.common_customer_address_Label}
                        </p>
                        <p className="mt-2 text-xxs sm:text-sm text-dark-shade-8 font-medium">
                          {val?.common_customer_address_Line_1},
                          {val?.common_customer_address_Line_2},
                          {formatPostcode(
                            val?.common_customer_address_Postcode
                          )}
                          , {val?.common_customer_address_City}
                        </p>
                      </div>
                      <RadioButton
                        value={options}
                        checked={checked}
                        onChange={() => handleAddressClick(val)}
                      />
                    </div>
                  </Card>
                );
              })}
            </div>
            {isFakeLoader ? (
              <div className={`animate-pulse`}>
                <div className="w-full sm:rounded-lg relative xs:pl-1 xs:pr-1 sm:pl-3 sm:pr-3 md:pl-0 md:pr-0">
                  <div className="grid grid-cols-1">
                    <div className="h-[62px] sm:h-[69px] md:h-[90px] bg-gray-300 rounded mx-5"></div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {userAddress.length > 3 ? (
              <div className="flex justify-end">
                <div
                  className={`text-primary flex font-inter m-auto mx-[18px] xxs:mx-[20px] xs:mx-[24px] sm:mx-[32px] md:mx-[24px] lg:mx-[22px] cursor-pointer ${
                    showLess ? "hidden" : "block"
                  }`}
                  onClick={handleShowMore}
                >
                  <span className="pr-1 pt-1">Show All</span>
                  <span className="pt-[6px]">
                    <DownArrowLeftChevron
                      className={"w-4 h-5 fill-primary rotate-0"}
                    />
                  </span>
                </div>
                <div
                  className={`text-primary flex font-inter m-auto mx-[18px] xxs:mx-[20px] xs:mx-[24px] sm:mx-[32px] md:mx-[24px] lg:mx-[22px] cursor-pointer ${
                    showLess ? "block" : "hidden"
                  }`}
                  onClick={handleShowLess}
                >
                  <span className="pr-1 pt-1">Show Less</span>
                  <span className="pt-[6px]">
                    <DownArrowLeftChevron
                      className={"w-4 h-5 fill-primary rotate-180"}
                    />
                  </span>
                </div>
              </div>
            ) : null}
          </>
        ) : null}

        {postcodes && postcodes.length > 0 && (
          <>
            <div className="text-mdl font-inter font-semibold mt-9 w-[90%] m-auto">
              Recent Locations
            </div>
            {postcodes.map((val, ind) => (
              <div
                key={"recentlocations" + ind}
                className="flex my-4 items-center w-[90%] m-auto cursor-pointer"
                onClick={() => locationRedirectToStores(val?.postcodePretty)}
              >
                <img src={clock} className="w-6 h-6" loading="lazy" />
                <div className="text-md ml-3">
                  {val?.cityName}, {formatPostcode(val?.postcodePretty)},{" "}
                  {val?.street}
                </div>
              </div>
            ))}
          </>
        )}
        {stores && stores.length > 0 ? (
          <>
            <div className="text-mdl font-inter font-semibold my-6 w-[90%] m-auto">
              Recent Stores
            </div>
            <div className="space-y-4 w-[90%] m-auto cursor-pointer mt-9 pb-6 mdl:pb-6">
              {stores ? (
                stores?.map((val, ind) => {
                  return val?.value?.store_Name != "" &&
                    val?.value?.product_Id != "" ? (
                    <TakeAwayCard
                      key={ind}
                      header={val?.value?.store_Name}
                      disabled={false}
                      val={val?.value}
                      productId={val?.value?.product_Id}
                      onClick={() => handleBannerClick(val?.value)}
                      fromHeader
                      // className={`my-8`}
                    />
                  ) : (
                    ""
                  );
                })
              ) : (
                <h2>No store found</h2>
              )}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default HeaderDrawer;
