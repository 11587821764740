import { useEffect, useState, useLayoutEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import BottomMenu from "../components/BottomMenu/BottomMenu";
import {
  getSession,
  getVersionControl,
  setVersionControl,
  getDismissedPendingOrders,
} from "../utils";
import { setUserData } from "../store/userData/userDataSlice";
import { LandingPage } from "../routes/RoutesPath";
import CookiesAlert from "../components/notify/alert/cookiesAlert";
import LoginModal from "../components/Modal/LoginModal";
import { getCookiesAlert, setCookiesAlert } from "../utils/index";
import {
  setUserCookies,
  setLoginModule,
} from "../store/userData/userDataSlice";
import { pendingOrdersList, versionControl } from "../services";
import {
  setId,
  setPendingList,
  setDismissedPendingList,
} from "../store/pendingOrders/pendingOrderSlice";
import { setCurrentPage } from "../store/currentPage/currentPageDataSlice";
import OrderAlert from "../components/notify/alert/orderAlert";
import { removeDismissedPendingOrders } from "../utils";
import { checkEmpty } from "../utils/helper";
import StoreClosedAlert from "../components/notify/alert/storeClosed";

export default function RootLayout() {
  const [showFooter, setShowFooter] = useState(true); // Hide footer by default
  const [contentLoaded, setContentLoaded] = useState(false); // Track content loading status
  const dispatch = useDispatch();
  const location = useLocation();
  const loggedIn = useSelector((state) => state.userdata.loggedIn);
  const isCookiesSet = useSelector((state) => state.userdata.userCookies);
  const isLoginSset = useSelector((state) => state.userdata.showLogin);
  const pageContent = useSelector(
    (state) => state?.basicdata?.pageContent?.customer
  );
  const navigation = useNavigate();
  const pendingOrderIntervalId = useSelector((state) => state.pendingOrder.id);
  const pendingOrderListCheck = useSelector(
    (state) => state.pendingOrder.pendingList
  );
  const isLoggedin = useSelector((state) => state.userdata.loggedIn);
  const isAllDismissed = useSelector(
    (state) => state.pendingOrder.dismissedPendingList
  );
  const [isPendingOrderList, setIsPendingOrderList] = useState([]);
  const [isSingleElement, setIsSingleElement] = useState(false);
  const storeClosedData = useSelector((state) => state.basicdata.storedetails);
  const [isStoreCloseAlert, setIsStoreClosedAlert] = useState(false);
  const [hideAlertSection, setHideAlertSection] = useState(false);
  const [pendingListAllDismiss, setPendingListAllDismiss] = useState(false);
  const menuRegexPattern = "^/order/([^/]+)/([^/]+)";
  const storeLogoUrl = useSelector(
    (state) => state.basicdata.storedetails.store_Banner_Image
  );
  const storeDetails = useSelector((state) => state.basicdata.storedetails);

  useEffect(() => {
    if (
      location.pathname != "/" &&
      !location.pathname.includes("/stores/") &&
      !location.pathname.includes("/takeaways/")
    ) {
      let metaOgImage = document.querySelector('meta[property="og:image"]');
      metaOgImage.setAttribute("content", storeLogoUrl);
    } else {
      let metaOgImage = document.querySelector('meta[property="og:image"]');
      metaOgImage.setAttribute(
        "content",
        "https://cdn.dineorder.com/uploads/dineorder/italianshop/homepage/slideshow/italian_slideshow21.jpg"
      );
    }
  }, [storeLogoUrl, location]);

  useEffect(() => {
    const productData = {
      "@context": "https://schema.org",
      "@type": "FastFoodRestaurant",
      name: storeDetails?.store_Name,
      image: storeDetails?.store_Logo,
      url: "https://www.system.dinetest.com",
      "@id": "https://www.system.dinetest.com",
      telephone: storeDetails?.store_Telephone,
      priceRange: "££",
      menu: "https://www.system.dinetest.com/storemenu",
      servesCuisine: "",
      acceptsReservations: "true",
      address: {
        "@type": "PostalAddress",
        streetAddress: storeDetails?.store_Address_Line_1,
        addressLocality: storeDetails?.store_Address_Line_2,
        addressRegion: storeDetails?.store_County,
        postalCode: storeDetails?.store_Postcode,
        addressCountry: storeDetails?.store_Country,
      },
      geo: {
        "@type": "GeoCoordinates",
        latitude: storeDetails?.store_Latitude,
        longitude: storeDetails?.store_Longitude,
      },
    };

    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.innerHTML = JSON.stringify(productData);
    document.head.appendChild(script);

    // Cleanup on unmount
    return () => {
      document.head.removeChild(script);
    };
  }, [storeDetails]);

  useEffect(() => {
    const productData = {
      "@context": "https://schema.org",
      "@type": "Organization",
      name: storeDetails?.store_Name,
      alternateName: "Kuick",
      url: "www.kuick.com",
      logo: storeDetails?.store_Logo,
      contactPoint: {
        "@type": "ContactPoint",
        telephone: storeDetails?.store_Telephone,
        contactType: "customer service",
        areaServed: "GB",
        availableLanguage: "en",
      },
    };

    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.innerHTML = JSON.stringify(productData);
    document.head.appendChild(script);

    // Cleanup on unmount
    return () => {
      document.head.removeChild(script);
    };
  }, [storeDetails]);

  useEffect(() => {
    const productData = {
      "@context": "https://schema.org/",
      "@type": "Product",
      name: storeDetails?.store_Name,
      image: storeDetails?.store_Logo,
      description: storeDetails?.store_Description,
      brand: "Kuick",
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: storeDetails?.storerating?.Rating,
        ratingCount: storeDetails?.storerating?.feedbackCount,
      },
    };

    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.innerHTML = JSON.stringify(productData);
    document.head.appendChild(script);

    // Cleanup on unmount
    return () => {
      document.head.removeChild(script);
    };
  }, [storeDetails]);

  const getPendingOrders = async () => {
    let dismissedPendingOrders = getDismissedPendingOrders()?.length;
    const { status, data } = await pendingOrdersList();
    if (status === 200) {
      if (data.status === "success") {
        if (data.orders.length == 0) {
          removeDismissedPendingOrders();
          dispatch(setPendingList(false));
        } else {
          data.orders.length == 1
            ? setIsSingleElement(false)
            : setIsSingleElement(true);
          setIsPendingOrderList(data.orders);
          if (data.orders.length == dismissedPendingOrders) {
            dispatch(setPendingList(false));
          } else {
            dispatch(setPendingList(true));
          }
        }
      }
    }
  };

  const setPendingOrderInterval = () => {
    if (isLoggedin && pendingOrderListCheck) {
      if (pendingOrderIntervalId != null) {
        clearInterval(pendingOrderIntervalId);
        const intervalId = setInterval(() => {
          getPendingOrders();
        }, 30000);
        dispatch(setId(intervalId));
      } else {
        const intervalId = setInterval(() => {
          getPendingOrders();
        }, 30000);
        dispatch(setId(intervalId));
      }
    } else {
      clearInterval(pendingOrderIntervalId);
    }
  };

  useLayoutEffect(() => {
    const data = getSession();
    if (data) {
      dispatch(setUserData(data));
    }
  }, [dispatch]);

  const checkFooter = () => {
    if (location.pathname.includes("profile") && window.innerWidth < 640) {
      setShowFooter(false);
    }
  };

  useEffect(() => {
    if (storeClosedData.store_Open_Status !== "Add") {
      setIsStoreClosedAlert(true);
    }
  }, [storeClosedData]);

  useEffect(() => {
    if (location.pathname === "/") {
      dispatch(setCurrentPage("Home"));
    } else if (
      location.pathname.includes("/stores/") ||
      location.pathname.includes("/takeaways/")
    ) {
      dispatch(setCurrentPage("Storelist"));
    } else if (location.pathname.includes("order")) {
      dispatch(setCurrentPage("Menu"));
    } else if (location.pathname.includes("/store/cart")) {
      dispatch(setCurrentPage("Cart"));
    } else if (location.pathname.includes("/profile")) {
      dispatch(setCurrentPage("Profile"));
    } else if (location.pathname.includes("/uns")) {
      dispatch(setCurrentPage("Unsubscribe"));
    } else if (location.pathname.includes("/store/confirm")) {
      dispatch(setCurrentPage("Confirm"));
    } else if (location.pathname.includes("/store/orderstatus/")) {
      dispatch(setCurrentPage("OrderStatus"));
    } else if (location.pathname.includes("/store/paymentprocessing")) {
      dispatch(setCurrentPage("Processing"));
    } else if (location.pathname.includes("/store/payment")) {
      dispatch(setCurrentPage("Payment"));
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (getCookiesAlert("cookies") == null) {
      setCookiesAlert("cookies", false);
      dispatch(setUserCookies(false));
    } else {
      if (getCookiesAlert("cookies") == "true") {
        dispatch(setUserCookies(true));
      } else {
        dispatch(setUserCookies(false));
      }
    }
    checkFooter();
    if (!loggedIn && pageContent?.length == 0) {
      navigation(LandingPage);
    }
    // dispatch(setPendingList(true));
    setTimeout(() => {
      getPendingOrders();
    }, 10000);
    versionCheck();
  }, []);

  useEffect(() => {
    setPendingOrderInterval();
  }, [pendingOrderListCheck, isLoggedin]);

  useEffect(() => {
    // Show footer after 1 second when content is fully loaded
    if (contentLoaded) {
      // const timer = setTimeout(() => {
      //   setShowFooter(true);
      // }, 1000);
      // // Clear the timer if the component unmounts or location changes
      // return () => clearTimeout(timer);
    }
  }, [contentLoaded, location]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsStoreClosedAlert(false);
  }, [location.pathname]);

  useEffect(() => {
    if (isCookiesSet && !isStoreCloseAlert && isAllDismissed) {
      setHideAlertSection(true);
    } else if (isCookiesSet && !isStoreCloseAlert && !isAllDismissed) {
      setHideAlertSection(true);
    } else if (!isCookiesSet && !isStoreCloseAlert && isAllDismissed) {
      setHideAlertSection(true);
    } else if (isCookiesSet && isAllDismissed) {
      if (isStoreCloseAlert == true) {
        setHideAlertSection(false);
      } else {
        setHideAlertSection(true);
      }
    } else {
      setHideAlertSection(false);
    }
  }, [isCookiesSet, isStoreCloseAlert, isAllDismissed]);

  const versionCheck = async () => {
    let resVersion = {};
    let webVersion = getVersionControl();

    const { status, data } = await versionControl();
    if (status === "success") {
      resVersion = data.version;
      if (checkEmpty(webVersion?.appVersionAll)) {
        webVersion = {};
        webVersion.appVersionAll = resVersion.appVersionAll;
        webVersion.fileVersionAll = resVersion.fileVersionAll;
        setVersionControl(webVersion);
      } else if (!checkEmpty(webVersion?.appVersionAll)) {
        localStorage.clear();
        deleteAllCookies();
        webVersion = {};
        webVersion.appVersionAll = resVersion.appVersionAll;
        webVersion.fileVersionAll = resVersion.fileVersionAll;
        setVersionControl(webVersion);
        setTimeout(function () {
          location.reload(true);
        }, 500);
      }
      if (checkEmpty(webVersion?.fileVersionAll)) {
        webVersion.fileVersionAll = resVersion.fileVersionAll;
        setVersionControl(webVersion);
      } else if (!checkEmpty(webVersion?.fileVersionAll)) {
        if (webVersion?.fileVersionAll !== resVersion?.fileVersionAll) {
          webVersion.fileVersionAll = resVersion.fileVersionAll;
          setVersionControl(webVersion);

          setTimeout(function () {
            location.reload(true);
          }, 500);
        }
      }
    } else if (status === "error") {
      console.error("Version control error--->", data);
    }
  };

  const deleteAllCookies = () => {
    // Get all cookies
    const cookies = document.cookie.split(";");
    // Loop through each cookie
    cookies.forEach((cookie) => {
      const cookieName = cookie.split("=")[0].trim();
      // Delete the cookie by setting its expiry date in the past
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
  };

  const closeLoginModal = () => {
    document.body.style.overflow = "";
    document.body.style.height = "auto";
    dispatch(setLoginModule(false));
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      {/* <main className={`flex-grow ${ !showFooter ? "bg-secondary" : "bg-white"}`}> */}{" "}
      <main className="flex-grow pb-16 mdl:pb-0" id="main_div">
        <Outlet onLoad={() => setContentLoaded(true)} />
      </main>
      <div
        className={`flex flex-col h-auto fixed left-0 right-0 bottom-0 md:left-1/2 md:-translate-x-1/2 ${
          hideAlertSection ? "hidden" : "block pb-20 mdl:pb-10"
        }`}
      >
        {pendingOrderListCheck && isLoggedin && (
          <div className={`z-auto pb-4`}>
            <OrderAlert
              items={isPendingOrderList}
              setIsPendingOrderList={setIsPendingOrderList}
              itemLenChecker={isSingleElement}
            />
          </div>
        )}
        {isStoreCloseAlert &&
          storeClosedData.store_Open_Status !== "Add" &&
          !checkEmpty(storeClosedData) &&
          location.pathname.match(menuRegexPattern) && (
            <div className={`z-auto pt-5`}>
              <StoreClosedAlert
                openStatus={storeClosedData.store_Open_Status}
                onClose={() => setIsStoreClosedAlert(!isStoreCloseAlert)}
                preOrder={storeClosedData?.store_Preorder}
                storeOpenTime={storeClosedData?.store_Open_Time}
              />
            </div>
          )}
        {!isCookiesSet && (
          <div className={`z-auto pt-2`}>
            <CookiesAlert />
          </div>
        )}
      </div>
      <LoginModal visible={isLoginSset} onClose={closeLoginModal} />
      <BottomMenu className="block mdl:hidden" />
      <Footer className="hidden mdl:block" />
    </div>
  );
}
