import { getSearchFilter } from "../../services";
import blue_placeholder_circle from "../../assets/images/default_images/blue_placeholder_circle.png";
import LazyLoadImageComponent from "../../components/Image/LazyLoadImage";
import { useDispatch } from "react-redux";
import {
  setStoreData,
  setSearchItems,
  setHomeLoader,
  setTakeAwayOpen,
  setTakeAwayClosed,
} from "../../store/basicData/basicDataSlice";

const CategoryCard = ({
  image,
  title,
  description,
  className,
  subclassName,
  setIsLayoutVisible,
  postcode,
}) => {
  const dispatch = useDispatch();


  const storeFilterApiCall = async () => {
    try {
      dispatch(setHomeLoader(true));
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      const apiPayload = {
        postcode: postcode,
        searchLat: "",
        searchLon: "",
        tags: [],
        offers: [],
        cuisines: [],
        searchText: title.toLowerCase(),
      };

      const { status, data } = await getSearchFilter(apiPayload);

      if (status == 200) {
        data.storeList.map((val) => {
          if (val.heading == "Nearest") {
            setIsLayoutVisible(false);
            dispatch(setStoreData(data.storeList));
            dispatch(setSearchItems(title));
            const nearestCount =
              data.storeList.find((section) => section.heading === "Nearest")
                ?.data.length || 0;
            dispatch(setTakeAwayOpen(nearestCount));

            const closedCount =
              data.storeList.find((section) => section.heading === "Closed")
                ?.data.length || 0;
            dispatch(setTakeAwayClosed(closedCount));
            dispatch(setHomeLoader(false));
          }
        });
      } else {
        throw new Error("API call was not successful");
      }
    } catch (error) {
      console.error("Error in storeFilterApiCall:", error);
      dispatch(setHomeLoader(false));
    }
  };

  return (
    <div
      className={`categoryCardHeight sm:w-56 w-[138px] flex justify-center 2xl:w-[269px] bg-white-shade-4 rounded-lg px-3 relative ${className}`}
    >
      <div
        className={`absolute -top-12 sm:-top-20 2xl:-top-20 z-auto m-auto sm:right-6 2xl:right-7 ${subclassName} `}
        onClick={() => storeFilterApiCall()}
      >
        <LazyLoadImageComponent
          src={image}
          alt=""
          className="w-[110px] h-[110px] sm:h-44 sm:w-44 2xl:h-52 2xl:w-52"
          onErrorSrc={blue_placeholder_circle}
          loading="lazy"
          placeholderSrc={blue_placeholder_circle}
        />
      </div>
      <div className="">
        <div className="text-sm 2xl:text-2xl text-dark-shade-1 sm:text-base font-semibold text-center pt-20 sm:pt-32 2xl:pt-40  sm:font-semibold">
          {title}
        </div>
        <div className="tooltip tooltip-bottom" data-tip={description}>
          <span className="line-clamp-2 text-xs  2xl:text-xl sm:text-[16px] text-center pt-[15px] text-black-shade-2 font-normal">
            {description}
          </span>
        </div>

        <div
          className={`text-[10px] 2xl:text-[15px] font-medium sm:text-sm text-exploreBtn text-center py-4 sm:pt-12 sm:pb-8 ${subclassName}`}
        >
          <span className="flex space-x-2 justify-center text-center">
            <span
              className="cursor-pointer exploreStore"
              onClick={() => storeFilterApiCall()}
            >
              Explore Store {`>>`}{" "}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default CategoryCard;
