import { useSelector } from "react-redux";
import Card from "./Card";
import LocationPin from "../Icon/LocationPin";
import Clock from "../Icon/Clock";
import Star from "../Icon/Star";
import Chips from "../Elements/Chips";
import { capitalizeFirstWord } from "../../utils";
import { checkEmpty } from "../../utils/helper";
import Tags from "../Elements/Tags";
import Bookmark from "../../components/Elements/Bookmark";
import Banner from "../../components/Elements/Banner";
import logo_cover from "../../assets/images/default_images/logo_cover.jpg";
import LazyLoadImageComponent from "../../components/Image/LazyLoadImage";

const TakeAwayCard = ({
  unique,
  header,
  disabled,
  val = {},
  onClick,
  productId,
  subclassName,
  className,
  fromHeader = false,
}) => {
  const deliveryType = useSelector((state) => state.userdata.deliveryType);
  return (
    <Card
      cardunique={"card" + unique}
      key={unique}
      onClick={onClick}
      disabled={disabled}
      className={`relative border rounded-lg bg-white transition duration-200 ${
        disabled ? "opacity-70" : "hover:scale-105"
      } ${className}`}
      val={val}
      footer={
        !fromHeader ? (
          <div className="px-3 sm:px-4">
            <div className="border-b border-divider"></div>
            <div className="py-1 sm:py-2 flex justify-between">
              <Chips
                value={
                  <div className="flex items-center gap-1">
                    <Star className="w-4 h-4 2xl:w-[18px] 2xl:h-[18px] fill-black-shade-1" />
                    <div className="text-sm 2xl:text-[15px] font-normal text-black-shade-1">
                      {val.store_Rating_Counter}
                    </div>
                  </div>
                }
              />
              <Chips
                value={
                  <div className="flex items-center gap-1 w-max">
                    {!checkEmpty(val.store_Distance) && (
                      <LocationPin className="w-[18px] h-[18px] 2xl:w-[20px] 2xl:h-[20px] stroke-black-shade-1" />
                    )}
                    <div className="text-sm 2xl:text-[15px] font-normal text-black-shade-1">
                      {checkEmpty(val.store_Distance)
                        ? ""
                        : `${val.store_Distance} ${capitalizeFirstWord(
                            val.store_Distance_Unit
                          )}`}
                    </div>
                  </div>
                }
              />
              <Chips
                value={
                  <div className="flex items-center gap-1 w-max">
                    <Clock
                      width={14}
                      height={14}
                      className="w-4 h-4 2xl:w-[18px] 2xl:h-[18px] fill-black-shade-1"
                    />
                    <div className="text-sm 2xl:text-[15px] font-normal text-black-shade-1">
                      {val.store_Open_Status !== "Pre Order"
                        ? `${val[`store_${deliveryType}_Time`]} Mins`
                        : val.store_Open_Status}
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        ) : null
      }
    >
      <Banner
        image={val.store_Banner_Image}
        className="w-full"
        imageClassName="w-full h-44 object-cover"
      />
      <div className="">
        <div className="flex absolute rounded-lg items-center top-3 sm:top-4 left-3 sm:left-4">
          <LazyLoadImageComponent
            src={val.store_Logo}
            alt=""
            className={`rounded-lg w-16 h-16`}
            onErrorSrc={logo_cover}
            loading="lazy"
            placeholderSrc={logo_cover}
          />
        </div>
      </div>
      <div className="h-24 px-3 sm:px-4">
        {header && (
          <div className={`flex justify-between`}>
            <p
              className={`${
                fromHeader ? "w-11/12" : "w-full"
              } text-[14px] sm:text-md font-semibold text-black-shade-1 pt-2 pb-2 sm:pb-3`}
            >
              {header}
            </p>
            {fromHeader && (
              <Chips
                value={
                  <div className="flex items-center gap-1">
                    <Star className="w-4 h-4 2xl:w-[18px] 2xl:h-[18px] fill-black-shade-1" />
                    <div className="text-sm 2xl:text-[15px] font-normal text-black-shade-1">
                      {val.store_Rating_Counter}
                    </div>
                  </div>
                }
              />
            )}
          </div>
        )}
        <div className="flex justify-start pb-2 sm:pb-3">
          <Tags
            data={
              checkEmpty(val?.store_Cuisines_Name?.trim())
                ? []
                : val?.store_Cuisines_Name?.trim().split(",")
            }
          />
        </div>
      </div>
      {!subclassName?.includes(`hidden`) && (
        <Bookmark productId={productId} className={`w-4`} />
      )}
    </Card>
  );
};

export default TakeAwayCard;
