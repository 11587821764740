import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import SubHeader from "../../components/Loader/MenuLoaders/SubHeader";
import GreaterThan from "../../assets/images/chevron-right.png";
import Lottie from "lottie-react";
import checkmark from "../../assets/images/checkmark.json";
import { capitalizeFirstWord, setCartConfigSession } from "../../utils";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Layout from "../../layout/Layout";
import {
  CheckoutPage,
  HomePage,
  LandingPage,
  MenuPage,
  TrackFoodPage,
} from "../../routes/RoutesPath";
import MenuLoader from "../../components/Loader/MenuLoaders/MenuLoader";
import CheckoutLoader from "../../components/Loader/CheckoutPageLoaders/CheckoutLoader";
import MenuBanner from "../menu/MenuBanner";
import Cart from "../../components/Elements/Cart";
import { getBasicData, confirmData, basketDetails } from "../../services";
import { checkEmpty } from "../../utils/helper";
import mastercard from "../../assets/images/payment_cards/Mastercard.svg";
import PayByCard from "../../components/Icon/PayByCard";
import ApplePay from "../../components/Icon/ApplePay";
import GooglePay from "../../components/Icon/GooglePay";
import ArrowLeftChevron from "../../components/Icon/ArrowLeftChevron";
import AddNew from "../../components/Icon/AddNew";
import { setBasketData } from "../../store/basicData/basicDataSlice";
import { setPendingList } from "../../store/pendingOrders/pendingOrderSlice";
import useAnalyticsEvents from "../../hooks/useAnalyticsEvents";

const Confirm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const basket = useSelector((state) => state.basicdata.basket);
  const storeDetails = useSelector((state) => state.basicdata.storedetails);
  const storeSlug = storeDetails?.store_Slug;
  const productId = storeDetails?.product_Id;
  const storeName = storeDetails?.store_Name;
  const storeCity = storeDetails?.store_City;
  const storeBannerImage = storeDetails?.store_Banner_Image;

  const [storeData, setStoreData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFixed, setIsFixed] = useState(false);
  const [pageData, setPageData] = useState("");
  const analyticsEvents = useAnalyticsEvents();
  const metaDescription = document.querySelector('meta[name="description"]');
  const metaOgDescription = document.querySelector(
    'meta[property="og:description"]'
  );
  const metaOgTitle = document.querySelector('meta[property="og:title"]');

  const isSticky = () => {
    const scrollTop = window.scrollY;
    setIsFixed(scrollTop > 80);
  };

  const getBasicDataAPI = async () => {
    setIsLoading(true);
    try {
      const { status, data } = await getBasicData();
      if (status == 200) {
        if (data.status === "success") {
          if (!checkEmpty(data?.storedetails)) {
            setStoreData(data?.storedetails);
          } else if (
            checkEmpty(data?.storedetails) &&
            checkEmpty(data?.storedetails?.product_Id)
          ) {
            navigate(LandingPage);
          }
        }
      }
    } catch (error) {
      //setIsLoading(false);
      console.error("error msg", error);
    } finally {
      //  setIsLoading(false);
    }
  };

  const getFinishOrder = async () => {
    try {
      const { status, data } = await basketDetails(id);
      if (status === 200) {
        if (data.status === "success") {
          if (
            data.basket.pos_basket_Delivery_Type == "Delivery" ||
            data.basket.pos_basket_Delivery_Type == "Collection"
          ) {
            if (!checkEmpty(data.basket)) {
              analyticsEvents("Purchase", data.basket);
            }
            setTimeout(() => {
              navigate(TrackFoodPage + id);
              dispatch(setBasketData(""));
            }, 5000);
          }
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("error msg", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getConfirmPageContentData = async () => {
    setIsLoading(true);
    try {
      const { status, data } = await confirmData();
      if (status === 200) {
        if (data.status === "success") {
          dispatch(setPendingList(true));
          setPageData(data);
        }
        //setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("error msg", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!checkEmpty(storeName)) {
      document.title = `Confirm | ${storeName} - ${
        import.meta.env.VITE_PRODUCT_NAME
      }`;
      metaOgTitle.setAttribute(
        "content",
        `Confirm | ${storeName} - ${import.meta.env.VITE_PRODUCT_NAME}`
      );
      metaDescription.setAttribute(
        "content",
        `Order food online from ${storeName}, ${storeCity}. Get a chance to win voucher on every order. Super fast food delivery, Live Order tracking, Exclusive discounts & Offers Only on ${
          import.meta.env.VITE_PRODUCT_NAME
        }.`
      );
      metaOgDescription.setAttribute(
        "content",
        `Order food online from ${storeName}, ${storeCity}. Get a chance to win voucher on every order. Super fast food delivery, Live Order tracking, Exclusive discounts & Offers Only on ${
          import.meta.env.VITE_PRODUCT_NAME
        }.`
      );
    }
  }, [storeName, storeCity, storeBannerImage]);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getBasicDataAPI();
    setTimeout(() => {
      getConfirmPageContentData();
      getFinishOrder();
    }, 1000);
  }, [storeSlug, productId]);

  return (
    <div>
      <Layout
        className={`bg-secondary ${
          isFixed
            ? "sticky animate-fadedown top-[76px] left-0 shadow-xl px-[25px] sm:px-7 md:px-8 lg:px-12 xl:px-[60px] overflow-hidden"
            : "animate-fadeup"
        } bg-white w-full z-50 mdl:flex items-center hidden`}
      >
        <Link to={HomePage} className="flex items-center cursor-pointer">
          {isLoading ? (
            <SubHeader />
          ) : (
            <span className="text-xs sm:text-sm font-normal text-grey">
              Home
            </span>
          )}
          <img src={GreaterThan} className="w-4 h-4" loading="lazy" />
        </Link>
        <div
          onClick={() => {
            navigate(MenuPage(storeSlug, productId));
          }}
          className="flex items-center cursor-pointer"
        >
          {isLoading ? (
            <SubHeader />
          ) : (
            <span className="text-xs sm:text-sm font-normal text-grey">
              Menu
            </span>
          )}
          <img src={GreaterThan} className="w-4 h-4" loading="lazy" />
        </div>
        <div className="flex items-center">
          {isLoading ? (
            <SubHeader />
          ) : (
            <span className="text-xs sm:text-sm font-normal text-active-route">
              Order Confirmation
            </span>
          )}
        </div>
      </Layout>
      {isLoading ? (
        <>
          <MenuLoader
            subClassName={`h-38 sm:h-56 md:h-56 lg:h-60 xl:h-72 relative -z-30`}
          />
          <Layout className="flex mt-[30px] md:mt-[30px]">
            <div className="xl:pt-0 space-y-5 w-full xl:w-[98%] mb-4 mdl:mr-[2%] sm:mb-11">
              <CheckoutLoader className="block" />
            </div>
          </Layout>
        </>
      ) : (
        <div>
          <MenuBanner
            storeSlug={storeSlug}
            productId={productId}
            setIsLoading={setIsLoading}
            storeData={storeData}
          />
          <Layout className="flex mt-[30px] md:mt-[30px]">
            <div className="flex flex-col m-auto mb-5 items-center">
              <div className=" lg:text-[19px]  md:text-[16px]   text-[12px]  text-slate-950	font-semibold		">
                {" "}
                Order Received!{" "}
              </div>
              <div className=" lg:text-[56px]  md:text-[48px]   text-[38px]  text-slate-950	font-semibold		">
                {" "}
                Thank You.{" "}
              </div>
              <div className=" lg:text-[16px]     text-[13px]  text-slate-950	font-medium		">
                {" "}
                We are waiting for the restaurant to accept the order.{" "}
              </div>
              <div className=" lg:text-[14px]     text-[11px]  text-slate-400		font-normal		">
                {" "}
                In your bank statement this transaction will show as or
                DineOrder
              </div>
              <div style={{ width: 200, height: 200 }}>
                <Lottie
                  style={{ flex: 1 }}
                  animationData={checkmark}
                  loop={false}
                />
              </div>
              <div className=" text-[14px]  text-slate-400		font-normal		">
                {" "}
                Your Order Number is
              </div>
              <div className=" text-[25px]  text-primary 		font-medium		">
                {" "}
                {basket?.pos_basket_Id}{" "}
              </div>
            </div>
          </Layout>
        </div>
      )}
    </div>
  );
};

export default Confirm;
