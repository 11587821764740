import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Modal from "react-modal";
import Cross from "../../assets/icons/cross.svg";
import Button from "../Elements/Button";
import {
  checkEmpty,
  checkFloat,
  checkInt,
  objectSize,
  resetObject,
  copyObject,
} from "../../utils/helper";
import useWindowDimensions from "../../hooks/useWindowsDimensons";
import { getComplexGroups, getComplexSubMenu } from "../../services";
import { error } from "../notify";
import RadioButton from "../Elements/RadioButton";
import Checkbox from "../Elements/Checkbox";
import MenuCheck from "../Icon/MenuCheck";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";

const CustomizeCartComplex = ({
  visible,
  onClose,
  submitProduct,
  tempProduct,
  finalProduct,
  setTempProduct,
  setFinalProduct,
  calculateFinalProductTotal,
}) => {
  const parentRef = useRef(null);
  const { width, height } = useWindowDimensions();
  const [curGroup, setCurGroup] = useState({});
  const [curProductlineKey, setCurProductlineKey] = useState(0);
  const [curProductline, setCurProductline] = useState({});
  const [curGroupKey, setCurGroupKey] = useState(0);
  const [curLabels, setCurLabels] = useState({});
  const [curStaticLabel, setCurStaticLabel] = useState(" ");
  const currency = useSelector(
    (state) => state?.basicdata?.storedetails?.store_Currency
  );
  const basket = useSelector((state) => state.basicdata.basket);

  // useEffect(() => {
  //   setCartConfig(getCartConfigSession());
  // }, []);

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = visible ? "hidden" : "auto";
    if (visible) {
      productLineController();
    }
  }, [visible]);

  const productLineController = () => {
    if (!checkEmpty(tempProduct.productline)) {
      let curProductlineTTT = tempProduct.productline[0];
      try {
        if (
          !checkEmpty(curProductlineTTT.productList) &&
          curProductlineTTT.productList.length == 1 &&
          curProductlineTTT.productList[0].inventory_product_Name ==
            tempProduct.inventory_product_Nam
        ) {
          nextComplexMenu(curProductlineTTT.productList[0]);
        } else {
          setCurProductline(tempProduct.productline[0]);
        }
      } catch (e) {
        setCurProductline(tempProduct.productline[0]);
      }
    }
  };

  const nextComplexMenu = (product) => {
    // if (parentRef.current) {
    //   parentRef.current.scrollTop = 0;
    // }

    let prd = copyObject(product);
    if (product.inventory_product_Form == "OnlyMenu") {
      complexOnlyMenu(prd);
    } else if (product.inventory_product_Form == "SingleMenu") {
      complexSingleMenu(prd);
    } else {
      complexSubMenu(prd);
    }
  };

  const resetData = () => {
    setCurGroup({});
    setCurProductlineKey(0);
    setCurProductline({});
    setCurGroupKey(0);
    setCurLabels({});
    setCurStaticLabel("");
    setFinalProduct({});
    setTempProduct({});
    onClose();
  };

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(finalProduct, "productline")) {
      if (
        finalProduct.productline[curProductlineKey].inventory_product_Form ===
        "OnlyMenu"
      ) {
        nextProductlineIndex();
      }
    }
  }, [finalProduct]);

  const complexOnlyMenu = (product) => {
    let res = copyObject(finalProduct);
    if (!Object.prototype.hasOwnProperty.call(res, "productline")) {
      res.productline = {};
    }
    res.productline[curProductlineKey] = product;
    setFinalProduct(res);
  };

  const complexSingleMenu = async (product) => {
    let res = copyObject(finalProduct);
    if (!Object.prototype.hasOwnProperty.call(finalProduct, "productline")) {
      res.productline = {};
    }
    const { status, data } = await getComplexGroups(
      product.inventory_product_Id
    );
    if (status === 200) {
      if (data.status === "success") {
        res.productline[curProductlineKey] = product;
        curProductline.inventory_product_Id = {
          ...product.inventory_product_Id,
        };
        setFinalProduct(res);
        setCurProductline(copyObject(curProductline));
        if (checkEmpty(data.groups)) {
          nextProductlineIndex();
        } else {
          curProductline.groups = data.groups;
          setCurProductline(copyObject(curProductline));
          setCurGroupKey(0);
          setCurLabels({});
          setCurGroup({ ...data.groups[0] });
        }
      } else if (data.status == "error") {
        /* empty */
      }
    }
  };

  const complexSubMenu = async (product) => {
    const { status, data } = await getComplexSubMenu(
      product.inventory_product_Id
    );
    if (status === 200) {
      if (data.status == "success") {
        if (checkEmpty(data.submenu)) {
          nextProductlineIndex();
        } else {
          curProductline.productList = data.submenu;
          setCurProductline({ ...curProductline });
        }
      } else if (data.status == "error") {
        /* empty */
      }
    }
  };

  const nextProductlineIndex = () => {
    setCurGroup({});
    setCurLabels({});
    setCurGroupKey(0);
    if (!checkEmpty(tempProduct.productline[curProductlineKey + 1])) {
      let res = copyObject(finalProduct);
      setCurProductline(
        copyObject(tempProduct.productline[curProductlineKey + 1])
      );
      if (!Object.prototype.hasOwnProperty.call(finalProduct, "productline")) {
        res.productline = {};
      }
      res.productline[curProductlineKey + 1] = {};
      setCurProductlineKey(curProductlineKey + 1);
      setFinalProduct(res);
    } else {
      submitProduct();
      resetData();
    }
  };

  const getComponentLabel = (gKey, groupItem) => {
    let lbl = "";
    if (Object.prototype.hasOwnProperty.call(finalProduct, "productline")) {
      const { productline } = finalProduct;
      Object.keys(productline[curProductlineKey] ?? {})?.forEach(() => {
        if (
          Object.prototype.hasOwnProperty.call(
            productline[curProductlineKey],
            "groups"
          )
        ) {
          Object.keys(
            productline[curProductlineKey].groups[gKey] ?? {}
          )?.forEach((g) => {
            if (
              productline[curProductlineKey].groups[gKey][g]
                .inventory_component_Name == groupItem.inventory_component_Name
            ) {
              lbl = productline[curProductlineKey].groups[gKey][g].label + " ";
              return;
            }
          });
        }
      });
    }
    return lbl;
  };

  const setComponentLabel = (label) => {
    let lbl = copyObject(label);
    let res = copyObject(finalProduct);
    if (
      finalProduct.productline[curProductlineKey].groups[curGroupKey][
        objectSize(
          finalProduct.productline[curProductlineKey].groups[curGroupKey]
        ) - 1
      ].label === lbl.inventory_label_Name
    ) {
      res.productline[curProductlineKey].groups[curGroupKey][
        objectSize(
          finalProduct.productline[curProductlineKey].groups[curGroupKey]
        ) - 1
      ].label = "";
    } else {
      res.productline[curProductlineKey].groups[curGroupKey][
        objectSize(
          finalProduct.productline[curProductlineKey].groups[curGroupKey]
        ) - 1
      ].label = lbl.inventory_label_Name;
    }
    setFinalProduct(res);
  };

  const getComponentLabelForButton = (label) => {
    let lbl = copyObject(label);
    if (
      finalProduct.productline[curProductlineKey].groups[curGroupKey][
        objectSize(
          finalProduct.productline[curProductlineKey].groups[curGroupKey]
        ) - 1
      ].label == lbl.inventory_label_Name
    ) {
      return true;
    } else {
      return false;
    }
  };

  const setGroupComponent = (component) => {
    setCurLabels({});
    let found = 0;
    let lblCmpMdl = {};
    lblCmpMdl.cmp = "";
    let res = copyObject(finalProduct);
    if (
      !Object.prototype.hasOwnProperty.call(
        res?.productline[curProductlineKey],
        "groups"
      )
    ) {
      res.productline[curProductlineKey].groups = {};
    }

    if (component.inventory_groups_Type == "Single") {
      let tmpcmp = copyObject(component);

      if (!checkEmpty(curStaticLabel)) {
        tmpcmp.label = curStaticLabel;
      }

      if (
        checkEmpty(res?.productline[curProductlineKey]?.groups?.[curGroupKey])
      ) {
        res.productline[curProductlineKey].groups[curGroupKey] = {};
      }
      res.productline[curProductlineKey].groups[curGroupKey][0] = tmpcmp;
      setFinalProduct(res);
    } else {
      if (
        Object.prototype.hasOwnProperty.call(
          res.productline[curProductlineKey],
          "groups"
        )
      ) {
        Object.keys(
          res.productline[curProductlineKey].groups[curGroupKey] ?? {}
        )?.forEach((k) => {
          const {
            inventory_groupsline_Id,
            inventory_component_Id,
            inventory_groups_Id,
          } = res.productline[curProductlineKey].groups[curGroupKey][k];
          if (
            inventory_groupsline_Id == component.inventory_groupsline_Id &&
            inventory_component_Id == component.inventory_component_Id &&
            inventory_groups_Id == component.inventory_groups_Id
          ) {
            delete res.productline[curProductlineKey].groups[curGroupKey][k];
            found = 1;
          }
        });
        res.productline[curProductlineKey].groups[curGroupKey] = resetObject(
          res.productline[curProductlineKey].groups[curGroupKey]
        );
      } else {
        res.productline[curProductlineKey].groups = {};
        res.productline[curProductlineKey].groups[curGroupKey] = {};
      }
      if (found == 0) {
        let tmpcmp = component;

        if (!checkEmpty(curStaticLabel)) {
          tmpcmp.label = curStaticLabel;
        }

        res.productline[curProductlineKey].groups[curGroupKey] = resetObject(
          res.productline[curProductlineKey].groups[curGroupKey]
        );

        res.productline[curProductlineKey].groups[curGroupKey][
          objectSize(res.productline[curProductlineKey].groups[curGroupKey])
        ] = tmpcmp;

        if (!checkEmpty(component.labels)) {
          setCurLabels({});
          let curLabelss = component.labels;
          curLabelss?.forEach((l, ind) => {
            if (l?.inventory_label_Name.trim().toLowerCase() != "free") {
              curLabels[ind] = l;
            }
          });
          curLabels.checkId =
            component.inventory_component_Id +
            "-" +
            component.inventory_groupsline_Id;
          setCurLabels(curLabels);
        }
      }
      setFinalProduct(res);
    }
  };

  const setGroupMultiComponent = (act, compt) => {
    let component = compt;
    setCurLabels({});
    // let found = 0;
    let lblCmpMdl = {};
    lblCmpMdl.cmp = "";
    let res = copyObject(finalProduct);
    if (!Object.prototype.hasOwnProperty.call(finalProduct, "groups")) {
      res.groups = {};
      res.groups[curGroupKey] = {};
    }

    if (act == "sub") {
      Object.keys(res?.groups)?.forEach((k) => {
        const {
          inventory_groupsline_Id,
          inventory_component_Id,
          inventory_groups_Id,
          inventory_component_Name,
          inventory_groupsline_Collection_Price,
          inventory_groupsline_Delivery_Price,
          inventory_groupsline_Restaurant_Price,
          inventory_groupsline_Waiting_Price,
        } = finalProduct.groups[curGroupKey][k];
        if (
          inventory_groupsline_Id == component.inventory_groupsline_Id &&
          inventory_component_Id == component.inventory_component_Id &&
          inventory_groups_Id == component.inventory_groups_Id
        ) {
          let nmsplit = inventory_component_Name.split(" x ");
          if (nmsplit.length > 0) {
            nmsplit = checkInt(nmsplit[0]);
            if (nmsplit > 1) {
              nmsplit -= 1;
              res.groups[curGroupKey][k].inventory_component_Name =
                nmsplit + " x " + nmsplit[1];
              res.groups[curGroupKey][k].inventory_groupsline_Collection_Price =
                checkFloat(inventory_groupsline_Collection_Price) * nmsplit;
              res.groups[curGroupKey][k].inventory_groupsline_Delivery_Price =
                checkFloat(inventory_groupsline_Delivery_Price) * nmsplit;
              res.groups[curGroupKey][k].inventory_groupsline_Restaurant_Price =
                checkFloat(inventory_groupsline_Restaurant_Price) * nmsplit;
              res.groups[curGroupKey][k].inventory_groupsline_Waiting_Price =
                checkFloat(inventory_groupsline_Waiting_Price) * nmsplit;
            } else {
              delete finalProduct.groups[curGroupKey][k];
            }
          }
          // found = 1;
          return;
        }
      });
      res.groups[curGroupKey] = resetObject(finalProduct.groups[curGroupKey]);
      setFinalProduct(res);
    } else if (act == "add") {
      let tmpcmp = component;
      res.groups[curGroupKey] = resetObject(finalProduct.groups[curGroupKey]);
      res.groups[curGroupKey][objectSize(finalProduct.groups[curGroupKey])] =
        tmpcmp;

      if (!checkEmpty(component.labels)) {
        let curLabelss = component.labels;
        setCurLabels({});
        curLabelss?.forEach((l) => {
          if (
            curLabelss[l]?.inventory_label_Name?.trim().toLowerCase() != "free"
          ) {
            curLabels[l] = curLabelss[l];
          }
        });
        curLabels.checkId =
          component.inventory_component_Id +
          "-" +
          component.inventory_groupsline_Id;
      }
    }
  };

  const submitGroup = function () {
    // document.documentElement.scrollTo({ top: 60, behavior: "smooth" });
    setCurStaticLabel("");
    let res = copyObject(finalProduct);
    if (checkEmpty(finalProduct.productline[curProductlineKey]?.groups)) {
      res.productline[curProductlineKey].groups = {};
    }
    if (
      curGroup.inventory_groups_Type == "Single" &&
      checkEmpty(res.productline[curProductlineKey].groups[curGroupKey])
    ) {
      error("Please select an item", true);
    } else {
      setCurLabels({});
      if (!checkEmpty(curProductline?.groups?.[curGroupKey + 1])) {
        setCurGroup(copyObject(curProductline.groups[curGroupKey + 1]));
        if (
          !Object.prototype.hasOwnProperty.call(
            res.productline[curProductlineKey],
            "groups"
          )
        ) {
          res.productline[curProductlineKey].groups = {};
        }
        res.productline[curProductlineKey].groups[curGroupKey + 1] = {};
        setCurGroupKey(curGroupKey + 1);
        setFinalProduct(res);
      } else {
        setFinalProduct(res);
        nextProductlineIndex();
      }
    }
  };

  const getComponentChecked = (groupsItem) => {
    let found = 0;
    Object.keys(
      finalProduct?.productline[curProductlineKey]?.groups?.[curGroupKey] ?? {}
    ).forEach((val) => {
      if (
        groupsItem?.inventory_component_Id ===
        finalProduct?.productline[curProductlineKey]?.groups?.[curGroupKey][val]
          .inventory_component_Id
      ) {
        found = 1;
        return;
      }
    });

    return found;
  };

  const countGroupMultiComponent = function (component) {
    let count = 0;
    let res = copyObject(finalProduct);
    if (
      !Object.prototype.hasOwnProperty.call(
        finalProduct.productline[curProductlineKey],
        "groups"
      )
    ) {
      res.productline[curProductlineKey].groups = {};
    }
    Object.keys(
      res.productline[curProductlineKey].groups?.[curGroupKey]
    ).forEach((k) => {
      const {
        inventory_groupsline_Id,
        inventory_component_Id,
        inventory_groups_Id,
      } = finalProduct.productline[curProductlineKey].groups[curGroupKey][k];
      if (
        inventory_groupsline_Id == component.inventory_groupsline_Id &&
        inventory_component_Id == component.inventory_component_Id &&
        inventory_groups_Id == component.inventory_groups_Id
      ) {
        count++;
      }
    });

    return count;
  };

  return (
    <Modal
      isOpen={visible}
      onRequestClose={resetData}
      className="flex justify-center items-center h-full px-0"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-50"
    >
      <div
        style={{ maxHeight: width < 769 ? height : height * 0.8 }}
        className="h-full md:h-auto md:max-h-[80vh] my-0 bg-white md:rounded-lg w-full md:w-3/4 xl:w-2/5 flex flex-col"
      >
        <header className="fixed w-full md:w-3/4 xl:w-2/5 flex items-center justify-between px-7 py-2 rounded-t-lg border-b">
          <span className="text-mdl md:text-lg font-semibold">
            {finalProduct?.inventory_product_Name}
          </span>
          <Button
            onClick={() => resetData()}
            // className="hover:bg-light-shade-5"
          >
            <img src={Cross} className="w-10 h-10" alt="cross-button" />
          </Button>
        </header>

        <main ref={parentRef} className="flex-1 overflow-auto mt-14 mb-0">
          {finalProduct?.inventory_product_Description?.trim() && (
            <div className="mx-7 mt-4">
              <span className="text-black-shade-11 text-sm mt-4">
                {finalProduct?.inventory_product_Description}
              </span>
            </div>
          )}

          <div className="space-y-2 mt-2">
            {/* already added products */}
            <div className=" max-w-full px-7 flex flex-col">
              {Object.keys(finalProduct?.productline ?? {})?.map((val, ind) => {
                const v = finalProduct?.productline[val];
                if (!checkEmpty(v)) {
                  return (
                    <div key={"custom" + ind}>
                      <div className="flex items-center border-dark-shade-11 border bg-gray-shade-7 rounded-[4px] px-2 w-max max-w-full mb-4 first:mt-4 gap-x-2.5">
                        <div className="flex flex-wrap">
                          <p className="text-xs leading-5">
                            {v.inventory_product_Name}
                          </p>
                        </div>
                        <MenuCheck />
                      </div>
                      {Object.keys(v?.groups ?? {})?.map((grpFnl, keyg) => {
                        if (
                          !checkEmpty(v.groups[grpFnl]) &&
                          v?.inventory_product_Id + keyg !=
                            curProductline.inventory_product_Id + curGroupKey
                        ) {
                          let tmpgrpcmp = [];
                          Object.keys(v.groups[grpFnl])?.forEach((grpFnl2) => {
                            tmpgrpcmp.push(
                              (v.groups[grpFnl][grpFnl2].label ?? "") +
                                " " +
                                v.groups[grpFnl][grpFnl2]
                                  .inventory_component_Name
                            );
                          });
                          return (
                            <div
                              key={"grpfnl" + keyg}
                              className="flex items-center border-dark-shade-11 border bg-gray-shade-7 rounded-[4px] px-2 w-max max-w-full mb-4 gap-x-2.5"
                            >
                              <div className="flex gap-2 items-center">
                                <p className="text-xs leading-5">
                                  {tmpgrpcmp.join(", ")}
                                </p>
                                <MenuCheck />
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  );
                }
              })}
            </div>

            {checkEmpty(curGroup) ? (
              <div className="">
                <div className="bg-white-shade-7 px-7 py-4">
                  <h3 className="text-sm font-medium">
                    {curProductline?.inventory_productline_Heading}
                  </h3>
                </div>
                <div className="flex flex-col space-y-3 px-7 mt-5 last:mb-5">
                  {curProductline?.productList?.map((productsList, key) => {
                    return (
                      <div
                        key={"customizecart" + key}
                        className="flex justify-between items-center w-full "
                      >
                        <div className="flex flex-col items-center">
                          <div className="text-md font-medium text-start w-full">
                            {productsList.inventory_product_Name}
                          </div>
                          <div className="text-sm">
                            {productsList.inventory_product_Description}
                          </div>
                        </div>
                        <div className="flex items-center space-x-2">
                          <span className="text-sm font-medium">
                            {!checkEmpty(
                              productsList[
                                "inventory_product_" +
                                  basket.pos_basket_Delivery_Type +
                                  "_Hidden_Price"
                              ]
                            )
                              ? `${currency}${
                                  productsList[
                                    "inventory_product_" +
                                      basket.pos_basket_Delivery_Type +
                                      "_Hidden_Price"
                                  ]
                                }`
                              : `${currency}0.00`}
                          </span>
                          <Button
                            className="bg-light-shade-3 px-4 py-1 w-24 text-white text-sm btn btn-sm border-0 rounded-lg justify-center items-center "
                            onClick={() => nextComplexMenu(productsList)}
                          >
                            <span
                              className={`capitalize text-black`}
                              // style={{color:onHover?'black':'red'}}
                            >
                              {productsList?.inventory_product_Search == 0
                                ? "Select"
                                : "Add"}
                            </span>
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="">
                <div>
                  <div className="bg-white-shade-7 px-7 py-4">
                    <h3 className="text-sm font-medium">
                      {curGroup.inventory_groups_Heading}
                      {curGroup?.inventory_groups_Default_Selection_Limit >
                        0 && (
                        <span>
                          (
                          {checkEmpty(
                            curGroup[
                              "inventory_groups_Default_Selection_" +
                                basket.pos_basket_Delivery_Type +
                                "_Price"
                            ]
                          ) && <span>Free</span>}
                          {currency}
                          {
                            curGroup[
                              "inventory_groups_Default_Selection_" +
                                basket.pos_basket_Delivery_Type +
                                "_Price"
                            ]
                          }
                          upto{" "}
                          {curGroup?.inventory_groups_Default_Selection_Limit}{" "}
                          items)
                        </span>
                      )}
                    </h3>
                  </div>

                  <div className="px-7">
                    <div className="">
                      {curGroup.inventory_groups_Static_Labels === 1 && (
                        <div className="">
                          {curGroup?.staticLabels?.map((label) => {
                            return (
                              label.inventory_label_Name?.toLowerCase() !=
                                "free" &&
                              !checkEmpty(label.inventory_label_Name) && (
                                <div
                                  className={`${
                                    label.inventory_label_Name ===
                                    curStaticLabel
                                      ? "border-primary text-primary"
                                      : "border-primary-shade-4 text-primary-shade-4"
                                  } border p-2 rounded-[100px] bg-white`}
                                  onClick={() =>
                                    setCurStaticLabel(
                                      label.inventory_label_Name
                                    )
                                  }
                                >
                                  <span>{label.inventory_label_Name}</span>
                                </div>
                              )
                            );
                          })}
                        </div>
                      )}

                      <div className="grid grid-cols-1 md:grid-cols-2 py-4 gap-x-10 gap-y-4">
                        {curGroup?.groupitems?.map((groupsItem, key) => {
                          const label = getComponentLabel(
                            curGroupKey,
                            groupsItem
                          );
                          return (
                            <div key={"cmplbl" + key} className="">
                              {curGroup.inventory_groups_Type == "Multiple" &&
                              groupsItem.inventory_groupsline_Selection_Type ==
                                "Multiple" ? (
                                <div className="component-check ">
                                  <label
                                    className={`text-xs ${
                                      label?.toLowerCase().trim() === "no"
                                        ? "line-through text-primary"
                                        : "text-black"
                                    }`}
                                  >
                                    {label}
                                    {groupsItem.inventory_component_Name}
                                  </label>
                                  <div
                                    className=""
                                    style={{
                                      display:
                                        objectSize(
                                          finalProduct.productline[
                                            curProductlineKey
                                          ]?.groups?.[curGroupKey]
                                        ) >=
                                        groupsItem?.inventory_groups_Default_Selection_Limit
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    {currency}
                                    {
                                      groupsItem[
                                        "inventory_groupsline_" +
                                          basket.pos_basket_Delivery_Type +
                                          "_Price"
                                      ]
                                    }
                                  </div>
                                  <div
                                    className=""
                                    style={{
                                      display:
                                        objectSize(
                                          finalProduct.productline[
                                            curProductlineKey
                                          ]?.groups?.[curGroupKey]
                                        ) <
                                        groupsItem?.inventory_groups_Default_Selection_Limit
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    {currency}
                                    {
                                      groupsItem[
                                        "inventory_groups_Default_Selection_" +
                                          basket.pos_basket_Delivery_Type +
                                          "_Price"
                                      ]
                                    }
                                  </div>
                                  <div className="flex items-center justify-end">
                                    <Button
                                      onClick={() =>
                                        setGroupMultiComponent(
                                          "sub",
                                          groupsItem
                                        )
                                      }
                                    >
                                      <div className="border rounded-lg px-1 py-1 w-min border-gray-shade-8 cursor-pointer">
                                        <MinusIcon className="w-5 h-5 text-center" />
                                      </div>
                                    </Button>
                                    <div className="text-sm font-medium w-8 text-center rounded-lg text-black-shade-6">
                                      {countGroupMultiComponent(groupsItem)}
                                    </div>
                                    <Button
                                      onClick={() =>
                                        setGroupMultiComponent(
                                          "add",
                                          groupsItem
                                        )
                                      }
                                    >
                                      <div className="border rounded-lg px-1 py-1 border-gray-shade-8 cursor-pointer">
                                        <PlusIcon className="w-5 h-5 text-center" />
                                      </div>
                                    </Button>
                                    <div className="ml-3">
                                      {objectSize(
                                        finalProduct?.groups?.[curGroupKey]
                                      ) >=
                                        groupsItem?.inventory_groups_Default_Selection_Limit && (
                                        <div className="">
                                          {currency}
                                          {
                                            groupsItem[
                                              "inventory_groupsline_" +
                                                basket.pos_basket_Delivery_Type +
                                                "_Price"
                                            ]
                                          }
                                        </div>
                                      )}
                                      {objectSize(
                                        finalProduct?.groups?.[curGroupKey]
                                      ) <
                                        groupsItem?.inventory_groups_Default_Selection_Limit && (
                                        <div className="">
                                          {currency}
                                          {
                                            groupsItem[
                                              "inventory_groups_Default_Selection_" +
                                                basket.pos_basket_Delivery_Type +
                                                "_Price"
                                            ]
                                          }
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="col-span-1 w-full flex items-center cursor-pointer"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setGroupComponent(groupsItem);
                                  }}
                                >
                                  {curGroup.inventory_groups_Type ==
                                  "Single" ? (
                                    <RadioButton
                                      checked={getComponentChecked(groupsItem)}
                                      onChange={() => {}}
                                    />
                                  ) : (
                                    <Checkbox
                                      checked={getComponentChecked(groupsItem)}
                                      onChange={() => {}}
                                    />
                                  )}
                                  <label className="w-full flex mx-2 justify-between">
                                    <div
                                      className={`font-medium ${
                                        label?.toLowerCase().trim() === "no"
                                          ? "line-through text-primary"
                                          : "text-black"
                                      }`}
                                    >
                                      {!checkEmpty(label) && (
                                        <span>{label}</span>
                                      )}
                                      {groupsItem?.inventory_component_Name}
                                    </div>
                                    <div
                                      className={`font-medium ${
                                        label?.toLowerCase().trim() === "no"
                                          ? "line-through text-primary"
                                          : "text-black"
                                      }`}
                                      style={{
                                        display:
                                          objectSize(
                                            finalProduct?.productline?.[
                                              curProductlineKey
                                            ]?.groups?.[curGroupKey]
                                          ) >=
                                          groupsItem?.inventory_groups_Default_Selection_Limit
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      {currency}
                                      {
                                        groupsItem[
                                          "inventory_groupsline_" +
                                            basket.pos_basket_Delivery_Type +
                                            "_Price"
                                        ]
                                      }
                                    </div>
                                    <div
                                      className={`font-medium ${
                                        label?.toLowerCase().trim() === "no"
                                          ? "line-through text-primary"
                                          : "text-black"
                                      }`}
                                      style={{
                                        display:
                                          objectSize(
                                            finalProduct?.productline?.[
                                              curProductlineKey
                                            ]?.groups?.[curGroupKey]
                                          ) <
                                          groupsItem?.inventory_groups_Default_Selection_Limit
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      {currency}
                                      {
                                        groupsItem[
                                          "inventory_groups_Default_Selection_" +
                                            basket.pos_basket_Delivery_Type +
                                            "_Price"
                                        ]
                                      }
                                    </div>
                                  </label>
                                </div>
                              )}
                              {curGroup.inventory_groups_Static_Labels !==
                                1 && (
                                <div className="block sm:hidden ">
                                  {!checkEmpty(curLabels) &&
                                    curLabels.checkId ==
                                      groupsItem.inventory_component_Id +
                                        "-" +
                                        groupsItem.inventory_groupsline_Id && (
                                      <div className="flex w-full space-x-6 justify-between my-2">
                                        {Object.keys(curLabels)?.map(
                                          (labelButton, index) => {
                                            if (
                                              curLabels[
                                                labelButton
                                              ]?.inventory_label_Name
                                                ?.toLowerCase()
                                                .trim() != "free" &&
                                              !checkEmpty(
                                                curLabels[labelButton]
                                                  .inventory_label_Name
                                              )
                                            )
                                              return (
                                                <div
                                                  className="flex"
                                                  key={"lblbtn" + index}
                                                >
                                                  <div
                                                    className="w-auto h-5 flex gap-x-1 md:gap-x-3 items-center cursor-pointer"
                                                    onClick={(event) => {
                                                      event.stopPropagation();
                                                      setComponentLabel(
                                                        curLabels[labelButton]
                                                      );
                                                    }}
                                                  >
                                                    <RadioButton
                                                      checked={getComponentLabelForButton(
                                                        curLabels[labelButton]
                                                      )}
                                                      onChange={() => {}}
                                                    />
                                                    <label>
                                                      {
                                                        curLabels[labelButton]
                                                          ?.inventory_label_Name
                                                      }
                                                    </label>
                                                  </div>
                                                </div>
                                              );
                                          }
                                        )}
                                      </div>
                                    )}
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="clear-both"></div>

                    {curGroup.inventory_groups_Static_Labels != 1 && (
                      <div className="">
                        <div className="hidden sm:block w-full px-6">
                          {!checkEmpty(curLabels) && (
                            <div className="flex w-full space-x-6 justify-between my-4">
                              {Object.keys(curLabels)?.map(
                                (labelButton, index) => {
                                  if (
                                    curLabels[labelButton]?.inventory_label_Name
                                      ?.trim()
                                      .toLowerCase() != "free" &&
                                    !checkEmpty(
                                      curLabels[labelButton]
                                        .inventory_label_Name
                                    )
                                  ) {
                                    return (
                                      <div
                                        key={"lblbtn+" + index}
                                        className="w-auto h-5 flex gap-x-3 items-center cursor-pointer"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          setComponentLabel(
                                            curLabels[labelButton]
                                          );
                                        }}
                                      >
                                        <RadioButton
                                          checked={getComponentLabelForButton(
                                            curLabels[labelButton]
                                          )}
                                          onChange={() => {}}
                                        />
                                        <label>
                                          {
                                            curLabels[labelButton]
                                              ?.inventory_label_Name
                                          }
                                        </label>
                                      </div>
                                    );
                                  }
                                }
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="clear-both"></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>

        <div className="border-orderDetailBD h-1 border-b" />

        <footer className="flex justify-end items-center px-6 my-2.5">
          {checkEmpty(curGroup) ? (
            // <div className="w-full border-t flex justify-end pt-1">
            <div className="flex flex-col items-end w-1/2">
              <div className="text-sm sm:text-sm/[13px] text-dark-shade-10">
                Total
              </div>
              <div className="text-base sm:text-base font-semibold">
                {currency}
                {calculateFinalProductTotal()}
              </div>
            </div>
          ) : (
            // </div>
            <>
              <Button
                onClick={() => submitGroup()}
                className="text-white w-1/2 bg-primary hover:bg-primary font-medium rounded-lg text-md inline-flex justify-center items-center p-3 text-center"
              >
                {checkEmpty(
                  finalProduct?.productline?.[curProductlineKey]?.groups?.[
                    curGroupKey
                  ]
                ) && curGroup.inventory_groups_Type == "Single"
                  ? "Select an item"
                  : checkEmpty(
                      finalProduct?.productline?.[curProductlineKey]?.groups?.[
                        curGroupKey
                      ]
                    )
                  ? "Skip Extras"
                  : "Add To Cart"}
              </Button>
              <div className="flex flex-col items-end w-1/2">
                <div className="text-sm sm:text-sm/[13px] text-dark-shade-10">
                  Total
                </div>
                <div className="text-base sm:text-base font-semibold">
                  {currency}
                  {calculateFinalProductTotal()}
                </div>
              </div>
            </>
          )}
        </footer>
      </div>
    </Modal>
  );
};

export default CustomizeCartComplex;
