import { useEffect, useState } from "react";
import BookmarkCircle from "../../components/Icon/BookmarkCircle";
import Star from "../../components/Icon/Star";
import Clock from "../../components/Icon/Clock";
import Card from "../../components/Cards/Card";
import { profileData, removeBookmarkData } from "../../services";
import { useNavigate } from "react-router-dom";
import { MenuPage } from "../../routes/RoutesPath";
import BookMarkCardLoader from "../../components/Loader/ProfilePageLoaders/BookmarkLoader/BookMarkCardLoader";
import MotionWrapper from "../../components/Animation/MotionWrapper";
import nodata_found from "../../assets/images/no-data-found/profile/bookmarks/nodata.png";
import LazyLoadImageComponent from "../../components/Image/LazyLoadImage";
import logo_cover from "../../assets/images/default_images/blue_placeholder_square.png";

import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";
import { useDispatch } from "react-redux";
import { checkEmpty } from "../../utils/helper";
import { success } from "../../components/notify";

const BookMarkCards = () => {
  const [bookmark, setBookmark] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTopBar, setIsLoadingTopBar] = useState(false);
  const [ssip, setSsip] = useState("");
  const navigation = useNavigate();

  const dispatch = useDispatch();
  const { progressFunction } = useProgress();
  const [intervalData, setintervalData] = useState(0);

  // Function to handle card click
  const handleCardClick = (restaurant) => {
    const selectedCard = restaurant.store_Config;
    setSsip(selectedCard.ssip);
    navigation(MenuPage(restaurant.store_Slug, restaurant.product_Id), {
      state: {
        storeslug: restaurant.store_Slug,
        product_Id: restaurant.product_Id,
      },
    });
  };

  useEffect(() => {
    bookmarkData();
  }, []);

  useEffect(() => {
    if (isLoading || isLoadingTopBar) {
      let count = 0;
      const intervalVariable = setInterval(() => {
        if (count < 90) {
          dispatch(setProgressData(count));
        } else if (count >= 90) {
          dispatch(setProgressData(90));
          window.clearInterval(intervalVariable);
        }
        count = count + 10;
      }, 10);

      setintervalData(intervalVariable);
      progressFunction(intervalVariable);
    } else {
      dispatch(setProgressData(100));
      window.clearInterval(intervalData);
    }
  }, [isLoading, isLoadingTopBar]);

  const bookmarkData = async () => {
    setIsLoading(true);
    try {
      const { status, data } = await profileData();
      if (status === 200) {
        if (data.status === "success") {
          setBookmark(data?.pageContent?.bookmark_stores);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("error msg", error);
    } finally {
      setIsLoading(false);
    }
  };

  const removeBookmark = async (key, product_Id) => {
    setIsLoadingTopBar(true);
    try {
      const { status, data } = await removeBookmarkData(product_Id);
      if (status === 200) {
        if (data.status === "success") {
          let currentBookMarkList = bookmark;
          currentBookMarkList.splice(key, 1);
          setBookmark(currentBookMarkList);
          if (!checkEmpty(data.msg)) {
            success(data.msg);
          }
          setIsLoadingTopBar(false);
        }
      }
    } catch (error) {
      setIsLoadingTopBar(false);
      console.error("error msg", error);
    } finally {
      setIsLoadingTopBar(false);
    }
  };

  return (
    <div className="">
      {isLoading ? (
        <BookMarkCardLoader
          subClassName={`grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4`}
          subsubClassName={`h-60`}
        />
      ) : bookmark && bookmark.length > 0 ? (
        <section className="bookmarkCard">
          <div className="my-5">
            <div className="grid grid-column xs:grid-cols-2 2xl:grid-cols-3 gap-6">
              {bookmark &&
                bookmark.map((restaurant, key) => (
                  <Card
                    key={restaurant.id}
                    className={`rounded-lg border-none bg-white relative `}
                  >
                    <div
                      className="absolute z-10 right-3 top-3 sm:right-1  sm:top-1  p-2.5 rounded-[25.97px] shadow flex-col justify-start items-start inline-flex cursor-pointer"
                      onClick={() => removeBookmark(key, restaurant.product_Id)}
                    >
                      <div className="absolute rounded-full flex justify-center right-2.5 top-2.5 items-center shadow-md h-6 w-6 bg-white md:h-8 md:w-8 2xl:xl:lg:h-[32px] 2xl:xl:lg:w-[32px]">
                        <BookmarkCircle
                          className={`z-20 text-primary-shade-1 justify-center items-center flex fill-primary w-4 stroke-white`}
                          bookmark={true}
                        />
                      </div>
                    </div>
                    <div
                      className="relative cursor-pointer"
                      onClick={() => handleCardClick(restaurant)}
                    >
                      <LazyLoadImageComponent
                        src={restaurant.store_Banner_Image}
                        alt={restaurant.store_Name}
                        className="w-full rounded-t-lg object-cover flex"
                        onErrorSrc={logo_cover}
                        loading="lazy"
                        placeholderSrc={logo_cover}
                      />
                      <div className=""></div>
                    </div>
                    <div className="pt-3 px-3 space-y-2 ">
                      <div className="text-stone-950 text-mdl sm:text-xl  font-medium font-['Inter'] leading-tight">
                        {restaurant?.store_Name}
                      </div>
                      <div className="w-fit sm:text-[17px] text-stone-500 text-xs font-normal font-['Inter'] sm:leading-[18px] ">
                        {restaurant?.store_Cuisines_name}
                      </div>
                    </div>

                    <div className="border-b pt-3 px-3" />
                    <div className="flex justify-between items-center space-x-1  px-4 py-4 sm:py-2 rounded-lg">
                      <div className="flex items-center space-x-[6px]  ">
                        <Star className="h-4 w-4 sm:h-5 sm:w-4 fill-hover-shade" />
                        <div className="text-[15px] sm:text-[15px] text-black-shade-1 w-max">
                          {restaurant.store_Rating_Counter}
                        </div>
                      </div>
                    </div>
                  </Card>
                ))}
            </div>
          </div>
        </section>
      ) : (
        <div className="flex justify-center items-center w-full sm:min-h-[calc(100vh_-_17rem)] sm:h-auto">
          <div className=" lg:w-[350px] md:w-[200px]">
            <img src={nodata_found} alt="No data found image" />
          </div>
        </div>
      )}
    </div>
  );
};

const Bookmarks = () => {
  return (
    <MotionWrapper>
      <div className="">
        <div className="mdl:min-h-[calc(100vh_-_10.5rem)] mdl:h-auto mdl:p-7 overflow-y-scroll h-auto no-scrollbar pb-16">
          <div className="text-lg font-bold sm:text-xl sm:font-semibold sm:font-inter ">
            Bookmarks
          </div>
          <div>
            <BookMarkCards />
          </div>
        </div>
      </div>
    </MotionWrapper>
  );
};

export default Bookmarks;
