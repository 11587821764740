import { useState, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import kuickLogo from "../../assets/kuick_logo.svg";
import crossIcon from "../../assets/images/CrossIcon.png";
import OtpInput from "../Elements/OtpInput";
import Button from "../Elements/Button";
import Dropdown from "../Elements/Dropdown";
import Loader from "../Elements/Loader";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Edit } from "../Icon/Edit";
import { Cross } from "../Icon/Cross";
import { VerifyButton } from "../Icon/VerifyButton";
import { RightArrow } from "../Icon/RightArrow";
import {
  isNumeric,
  // normalizeInputMobile,
  removeAllSpaceFromString,
  setSession,
  getTimeRemaining,
  setSessionUserId,
  setSessionCCId,
  removeDismissedPendingOrders,
} from "../../utils";
import { loginCall, getBasicDineData } from "../../services";
import { error } from "../notify";
import {
  setUserData,
  setUserAddress,
} from "../../store/userData/userDataSlice";

import { setBasicDineData } from "../../store/basicData/basicDataSlice";

import { HomePage } from "../../routes/RoutesPath";

import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";
import Modal from "react-modal";

import { setLoginModule } from "../../store/userData/userDataSlice";
import useWindowDimensions from "../../hooks/useWindowsDimensons";

const LoginModal = ({ visible, onClose }) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoggedIn = location?.pathname?.includes("login");
  const settings = useSelector((state) => state.basicdata.settings);
  const [loader, setLoader] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState(
    settings?.countryCode ? settings.countryCode[0] : "+44"
  );
  const [step, setStep] = useState(1);
  const [ctoken, setCToken] = useState(null);
  const [otpLen, setOtpLen] = useState(4);
  const [showResend, setShowResend] = useState(false);
  const [otp, setOtp] = useState("");
  const [counterCount, setCounterCount] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [referalCode, setReferalCode] = useState("");
  // The state for our timer
  const [timer, setTimer] = useState("00:00");
  const [regVisible, setRegVisible] = useState(false);

  const timerRef = useRef(null);
  const [isLoginPage, setIsLoginPage] = useState(true);

  const { progressFunction } = useProgress();
  const [isLoading, setIsLoading] = useState(false);
  const [intervalData, setintervalData] = useState(0);

  const [pageNames, setpageName] = useState("");
  const [isParentToggle, setIsParentToggle] = useState(false);
  const dropDownRef = useRef(null);
  const { width } = useWindowDimensions();
  const [isSmall, setIsSmall] = useState(false);
  const [countryData, setCountryData] = useState("");
  const [defaultCountryList, setDefaultCountryList] = useState([]);
  const [countriesCode, setCountriesCode] = useState("+44");

  useEffect(() => {
    if (loader) {
      let count = 0;
      const intervalVariable = setInterval(() => {
        if (count < 90) {
          dispatch(setProgressData(count));
        } else if (count >= 90) {
          dispatch(setProgressData(90));
          window.clearInterval(intervalVariable);
        }
        count = count + 10;
      }, 1000);

      setintervalData(intervalVariable);
      progressFunction(intervalVariable);
    } else {
      dispatch(setProgressData(100));
      window.clearInterval(intervalData);
    }
  }, [loader]);

  const handleToggle = () => {
    if (step == 2 || step == 3) {
      setStep(1);
      setCounterCount(0);
      setTimer("00:00");
    }
    setIsLoginPage((prev) => !prev);
  };
  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      if (total === 0) {
        setShowResend(true);
        clearInterval(timerRef.current);
      }
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    const { minutes, seconds } = getTimeRemaining(e);
    setTimer(
      (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
    );
    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (timerRef.current) clearInterval(timerRef.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    timerRef.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(
      deadline.getSeconds() +
        (counterCount === 1 ? 40 : (counterCount - 1) * 30 + 40)
    );
    return deadline;
  };

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  useEffect(() => {
    if (width < 640) {
      setIsSmall(true);
    } else {
      setIsSmall(false);
    }
  }, [width]);

  useEffect(() => {
    setShowResend(false);
    clearTimer(getDeadTime());
  }, [counterCount]);

  useEffect(() => {
    if (visible) {
      setStep(1);
      setCounterCount(0);
      setMobileNumber("");
    }
  }, [visible]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setIsParentToggle(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [dropDownRef]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/countries.json");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setDefaultCountryList(jsonData);
        setCountryData(jsonData);
      } catch (err) {
        console.log();
      } finally {
        console.log();
      }
    };

    fetchData();
  }, []);

  function onChange(value) {
    setCToken(value);
  }

  const getLogin = async (pageName, resend = "No", mode = "SMS") => {
    setpageName(pageName);
    if (mobileNumber.length === 11 && mobileNumber.charAt(0) !== "0") {
      error("Invalid mobile number. Please enter a valid number", true);
    } else {
      setLoader(true);

      setTimeout(async () => {
        const res = await loginCall(
          countryCode,
          mobileNumber,
          otp,
          resend,
          mode,
          pageName,
          firstName,
          referalCode,
          ctoken
        );
        setLoader(false);
        if (res.status === 200) {
          setLoader(false);

          const { status, msg, bk } = res.data;
          if (status === "success") {
            if (bk) {
              if (pageName != "reotp") {
                error("Something went wrong!! Please try again", true);
              } else if (pageName == "reotp") {
                setOtp(new Array(length).fill("")); // Clear OTP input
                setShowResend(false); // Reset resend OTP flag

                setCounterCount(counterCount + 1);

                setOtpLen(otpLen);
                setStep(2);
                setCToken(null);
              }
            } else {
              if (step === 1) {
                if (otpLen) {
                  setOtpLen(otpLen);
                  setShowResend(false); // Reset resend OTP flag
                  setStep(2);
                  setCToken(null);
                  setCounterCount(counterCount + 1);
                } else {
                  // server side error
                }
              } else if (step === 2) {
                const { customer } = res.data;
                if (customer?.common_customer_First_Name === "") {
                  setStep(3);
                } else {
                  document.body.style.overflow = "";
                  document.body.style.height = "auto";
                  setSessionUserId(res?.data?.config?.usrId);
                  setSessionCCId(res?.data?.config?.ccid);
                  if (res?.data?.customer?.common_customer_Address?.length) {
                    dispatch(
                      setUserAddress({
                        selected:
                          res?.data?.customer?.common_customer_Address[0],
                        address: res?.data?.customer?.common_customer_Address,
                      })
                    );
                  }
                  dispatch(setUserData(res?.data));
                  setSession(customer);
                  removeDismissedPendingOrders();
                  ///  setVisible(false);
                  dispatch(setLoginModule(false));
                  getBasicDineData().then((res) => {
                    if (res.status === 200) {
                      dispatch(setBasicDineData(res.data));
                    }
                  });
                }
              } else if (step === 3) {
                document.body.style.overflow = "";
                document.body.style.height = "auto";
                const { customer } = res.data;
                setSessionUserId(res?.data?.config?.usrId);
                setSessionCCId(res?.data?.config?.ccid);
                dispatch(setUserData(res?.data));
                setSession(customer);
                navigation(HomePage);
                removeDismissedPendingOrders();
                ///  setVisible(false);
                dispatch(setLoginModule(false));
              }
            }
          } else if (status === "valid_error") {
            error(msg, true);
          } else if (status === "error") {
            error(msg, true);
          } else {
            error(msg, true);
          }
        } else {
          // axios error
          setLoader(false);
          error("Something went wrong !! Please try again");
        }
      }, 3000);
    }
  };

  const getHeader = () => {
    switch (step) {
      case 1:
        return "Welcome";
      case 2:
        return "One Time Password";
      default:
        return "";
    }
  };

  const getSubHeader = () => {
    switch (step) {
      case 1:
        return "Enter Mobile Number";
      case 2:
        return (
          <span>
            We have sent the OTP verification to
            <span className="flex space-x-2 items-center">
              <span>{` ${countryCode} ${mobileNumber}`}</span>
              <Button
                onClick={() => {
                  setStep(1);
                  setCounterCount(0);
                  setCToken(null);
                  setShowResend(false);
                }}
              >
                <Edit className="cursor-pointer" />
              </Button>
            </span>
          </span>
        );
      case 3:
        return (
          <span className="font-semibold font-inter text-[20px] sm:text-[24px]">
            Update Your Details.
          </span>
        );
      default:
        return "";
    }
  };

  const getBody = () => {
    switch (step) {
      case 1:
        return (
          <div className="border border-gray-300 focus-within:border-blue-500 focus:outline-none w-full rounded-lg flex mt-3">
            <div className="flex items-center w-full">
              <div
                className="w-[22%] sm:w-[32%] pl-4 py-2"
                onClick={() => {
                  setIsParentToggle(!isParentToggle);
                }}
                ref={dropDownRef}
              >
                <Dropdown
                  countryList={countryData}
                  setCountryData={setCountryData}
                  value={countryCode}
                  defaultCountryList={defaultCountryList}
                  className="sm:w-full"
                  onChange={(value) => {
                    setCountryCode(value);
                  }}
                  area="Login"
                  parentToggle={isParentToggle}
                />
              </div>
              <div className="w-[78%] sm:w-[68%] pl-8 xs:pl-4 sm:px-2 py-2">
                <input
                  type="text"
                  maxLength={11}
                  keyboardtype="number-pad" // keyboardType
                  inputMode="numeric"
                  autoComplete="off"
                  placeholder="XXXXXXXXXX"
                  value={mobileNumber}
                  className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none w-full md:h-10 text-sm sm:text-md font-normal font-inter leading-normal sm:w-full focus:outline-none"
                  onChange={(e) => {
                    const { value } = e.target;
                    let typed = removeAllSpaceFromString(value);
                    if (isNumeric(typed)) {
                      setMobileNumber(typed);
                    } else if (value === "") {
                      setMobileNumber("");
                    }
                  }}
                />
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="pt-6 space-x-2">
            <OtpInput
              length={otpLen}
              onOtpSubmit={setOtp}
              pageNames={pageNames}
            />
          </div>
        );
      case 3:
        return (
          <div className="w-full rounded-lg">
            <div className="text-textPrimary text-base sm:text-mdl font-normal font-inter pt-4 sm:pt-6">
              Enter Your Full Name
            </div>
            <div className="flex mt-3 items-center w-full">
              <input
                type="text"
                placeholder="Name"
                value={firstName}
                autoComplete="off"
                className="px-3 space-x-[6px] py-3 sm:py-3 rounded-lg text-base font-normal font-inter leading-normal w-full placeholder-placeholder border border-gray-300 focus:border-blue-500 focus:outline-none"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                maxLength={50}
              />
            </div>
            <div className="text-textPrimary text-base sm:text-mdl font-normal font-inter pt-4 sm:pt-6">
              Referral Code (If Any){" "}
            </div>
            <div className="mt-3 flex items-center w-full">
              <input
                type="text"
                placeholder="Code"
                autoComplete="off"
                value={referalCode}
                className="rounded-lg px-3 space-x-[6px] py-3 sm:py-3 text-base font-normal font-inter leading-normal w-full border border-gray-300 focus:border-blue-500  focus:outline-none placeholder-placeholder"
                onChange={(e) => {
                  setReferalCode(e.target.value);
                }}
                maxLength={50}
              />
            </div>
          </div>
        );
      default:
        return "";
    }
  };

  const onSubmit = () => {
    switch (step) {
      case 1:
        return (
          <Button
            onClick={() => {
              getLogin("username");
            }}
            disabled={
              loader ||
              (mobileNumber?.charAt(0) === "0"
                ? mobileNumber?.length <= 10
                : mobileNumber?.length < 10) ||
              !ctoken
            }
            type="primary"
            title="Get OTP"
            className="w-full mt-5 md:mt-6 py-[13px] md:h-14 inline-flex justify-center items-center"
          >
            <RightArrow />
          </Button>
        );
      case 2:
        return (
          <div className="m-auto mdl:w-full">
            <Button
              onClick={() => getLogin("otp")}
              disabled={otp.length !== otpLen || loader}
              type="primary"
              className="w-full py-1 mt-6 md:mt-6 h-12 md:h-14 gap-1 inline-flex justify-center items-center cursor-pointer"
            >
              <VerifyButton />
              <div className="text-center text-white text-base font-semibold font-inter leading-normal">
                Verify
              </div>
            </Button>
          </div>
        );
      case 3:
        return (
          <Button
            onClick={() => getLogin("details")}
            disabled={!firstName.length}
            type="primary"
            className="w-full py-1 mt-4 md:mt-6 h-12 md:h-14 gap-1 inline-flex justify-center items-center"
          >
            <div className="text-center text-white text-base font-semibold font-inter leading-normal">
              Submit
            </div>
          </Button>
        );
      default:
        return "";
    }
  };

  const onCloseButton = () => {
    onClose();
    setCounterCount(0);
    setStep(1); // Reset step to 1
    setLoader(false); // Reset loader state
    setMobileNumber(""); // Clear mobile number input
    setOtp(""); // Clear OTP input
    setTimer("00:00"); // Reset timer
    setFirstName(""); // Clear first name input
    setReferalCode(""); // Clear referral code input
    setCToken(null); // Reset captcha token
    setShowResend(false); // Reset resend OTP flag
    setRegVisible(false); // Reset registration visibility
    dispatch(setLoginModule(false)); // Hide the modal
  };

  return (
    <Modal
      isOpen={visible}
      onRequestClose={onCloseButton}
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-50 cursor-pointer"
      className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-none border-none focus:outline-none bg-white ${
        isSmall
          ? "h-full w-full px-10"
          : "sm:w-[481px] sm:h-[610px] rounded-2xl"
      } flex items-center justify-center`}
    >
      <Button
        type="button"
        className="z-50 absolute top-3 right-4 text-gray-400 bg-transparent rounded-lg text-sm w-6 h-6 sm:w-8 sm:h-8 ms-auto inline-flex justify-center items-center "
        onClick={onCloseButton}
      >
        <img src={crossIcon} alt="" srcSet="" />
      </Button>
      <div className={`relative w-full mx-4 sm:mx-16`}>
        <img
          loading="lazy"
          src={kuickLogo}
          className="flex mx-auto w-[104px] sm:w-[133px] my-4 sm:my-6"
        />
        <div className={`sm:${step > 1 ? "px-5" : "px-24"} sm:mt-auto  w-full`}>
          <div className="text-[20px] sm:text-[24px] font-bold leading-[38px] font-inter">
            {getHeader()}
          </div>
          <div
            className={`text-neutral-900 text-[14px] sm:text-md mdl:text-mdl font-normal font-inter leading-normal ${
              step == 2 || step == 3 ? "pt-0" : "pt-4"
            }`}
          >
            {getSubHeader()}
          </div>
          {getBody()}

          {step === 1 && (
            <div className="mt-3 md:mt-6">
              <ReCAPTCHA
                sitekey={import.meta.env.VITE_CAPTCHA_SITE_KEY}
                onChange={onChange}
              />
            </div>
          )}

          {step === 2 &&
            (showResend ? (
              <div className="flex justify-between pt-[23px] mdl:w-full m-auto">
                <div
                  className="cursor-pointer w-[131px] sm:w-[156px] h-11 px-4 py-1 bg-pink-50 rounded-lg justify-center items-center gap-1 inline-flex"
                  onClick={() => {
                    getLogin("reotp", "Yes");
                    setCounterCount(counterCount + 1);
                  }}
                >
                  <div className="text-center text-slate-500 text-base font-semibold font-inter leading-normal">
                    Resend OTP
                  </div>
                </div>
                <div
                  className="cursor-pointer w-[131px] sm:w-[156px] h-11 px-4 py-1 bg-pink-50 rounded-lg justify-center items-center gap-1 inline-flex"
                  onClick={() => {
                    getLogin("reotp", "Yes", "VOICE");
                    setCounterCount(counterCount + 1);
                  }}
                >
                  <div className="text-center text-slate-500 text-base font-semibold font-inter leading-normal">
                    Voice OTP
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-center mt-6 md:mt-6">
                <span className="text-p6 font-bold">{timer}</span>
              </div>
            ))}

          {/* get otp button */}
          {onSubmit()}

          <div className={`flex space-x-1 justify-center mt-3 sm:my-6`}>
            <div className="text-gray-500 text-base font-semibold font-inter leading-normal">
              {isLoginPage
                ? "Don’t have an account?"
                : "Already have an account?"}
            </div>
            <div
              onClick={handleToggle}
              className="text-blue-700 text-base font-semibold font-inter leading-normal cursor-pointer"
            >
              {isLoginPage ? "Sign Up" : "Login"}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal;
