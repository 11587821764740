import Star from "../Icon/Star";
import LocationPin from "../Icon/LocationPin";
import Clock from "../Icon/Clock";
import Tags from "../Elements/Tags";
import { getSearchFilter } from "../../services";
import Banner from "../../components/Elements/Banner";
import { useDispatch } from "react-redux";
import {
  setStoreData,
  setSearchItems,
  setTakeAwayOpen,
  setTakeAwayClosed,
  setHomeLoader,
} from "../../store/basicData/basicDataSlice";

const BestSellerCard = ({
  image,
  title,
  description,
  postcode,
  className,
  setIsLayoutVisible,
}) => {
  const dispatch = useDispatch();
  const handleOnFilterChange = async () => {
    try {
      dispatch(setHomeLoader(true));
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      const apiPayload = {
        postcode,
        searchLat: "",
        searchLon: "",
        tags: [],
        offers: [],
        cuisines: [],
        searchText: title.toLowerCase(),
      };

      const { status, data } = await getSearchFilter(apiPayload);

      if (status === 200) {
        data?.storeList.forEach((val, ind) => {
          if (val.heading === "Nearest") {
            setIsLayoutVisible(false);
            dispatch(setStoreData(data.storeList));
            dispatch(setSearchItems(title));

            const nearestCount =
              data.storeList.find((section) => section.heading === "Nearest")
                ?.data.length || 0;
            dispatch(setTakeAwayOpen(nearestCount));

            const closedCount =
              data.storeList.find((section) => section.heading === "Closed")
                ?.data.length || 0;
            dispatch(setTakeAwayClosed(closedCount));
          }
        });
      }
    } catch (error) {
      console.error("Error in handleOnFilterChange:", error);
    } finally {
      dispatch(setHomeLoader(false));
    }
  };

  return (
    <div
      className={`bg-white sm:h-max w-[196px] sm:w-60 md:w-[260px] rounded-lg bg-[white-shade-1] cursor-pointer ${className}`}
      onClick={() => handleOnFilterChange()}
    >
      <Banner
        image={image}
        className=""
        imageClassName="h-36 sm:h-44 rounded-t-lg w-[196px] sm:w-60 md:w-[260px]"
      />
      <div className={`px-2 sm:px-4 pt-3 mb-2`}>
        <div className="space-y-2">
          <div className="text-[13px] sm:text-[20px] 3xl:leading-[1.3rem] font-semibold leading-4 sm:leading-5 line-clamp-2 h-10 sm:h-16 lg:h-[50px] text-black-shade-3">
            {title}
          </div>
          {description && description !== "" ? (
            <div className="text-xs sm:text-base text-chip-text-color">
              {description}
            </div>
          ) : null}
        </div>
        {/* tags */}
        {/* <Tags
          className={`pr-2 sm:pr-0 mb-2`}
          data={["Burger", "Steak", "Noodles"]}
        /> */}
        {/* <div className="border-b " /> */}
      </div>
      {/* Data not available from Api */}
      {/* <div
        className={`flex justify-between items-center space-x-1 pt-2 pb-3 sm:pt-3 sm:pb-4 px-2 sm:px-4`}
      >
        <div className="flex items-center ">
          <div className="">
            <Star className="h-3.5 w-3.5 sm:h-4 sm:w-4 2xl:h-4.5 2xl:w-4.5 fill-hover-shade" />
          </div>
          <div className="text-[12px] sm:text-[14px] 2xl:text-[15px] font-medium text-black-shade-1 w-max ml-0.5">
            4.5
          </div>
        </div>
        <div className="flex items-center ">
          <div className="">
            <LocationPin className="h-3.5 w-3.5 sm:h-4 sm:w-4 2xl:h-4.5 2xl:w-4.5 stroke-hover-shade" />
          </div>
          <div className="text-[12px] sm:text-[14px] font-medium text-black-shade-1 2xl:text-[15px] w-max ml-0.5">
            3.00 Miles
          </div>
        </div>
        <div className="flex items-center  ">
          <div className="">
            <Clock className="h-3.5 w-3.5 sm:h-4 sm:w-4 2xl:h-4.5 2xl:w-4.5 fill-hover-shade" />
          </div>
          <div className="text-[12px] sm:text-[14px] 2xl:text-[15px] font-medium text-black-shade-1 w-max ml-0.5">
            45 Mins
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default BestSellerCard;
