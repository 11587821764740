import { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import EditIcon from "../../assets/images/profile_section/Edit.png";
import MailIcon from "../../assets/images/profile_section/mail.png";
import PhoneIcon from "../../assets/images/profile_section/phone.png";
import Edit from "../../assets/images/profile_section/editIcon.png";
import EditRectangle from "../../components/Icon/EditRectangle";
import InputField from "../../components/Elements/InputField";
import kuickLogo from "../../assets/kuick_logo.svg";
import CloseIcon from "../../assets/images/profile_section/close.png";
import { checkEmpty } from "../../utils/helper";

import { Cross } from "../../components/Icon/Cross";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import OtpInput from "../../components/Elements/OtpInput";
// import { pageContent } from "../../utils/config";
// import Loader from "../../components/Elements/Loader";
import ProfileLoader from "../../components/Loader/ProfilePageLoaders/ProfileLoader/ProfileLoader";
import { profileData, updateProfile } from "../../services";
import { HomePage } from "../../routes/RoutesPath";
import Button from "../../components/Elements/Button";
import MotionWrapper from "../../components/Animation/MotionWrapper";
import {
  ProperNameRegEx,
  mobileNumberRegEx,
  emailIdrRegEx,
  getTimeRemaining,
} from "../../utils";
import Default_pfp from "../../assets/images/default_images/Default_pfp.png";
import { success, error } from "../../components/notify";
import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";
import { useDispatch } from "react-redux";

Modal.setAppElement("#root");

const Profile = () => {
  const [counterCount, setCounterCount] = useState(0);
  const [alertMessage, setAlertMessage] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [profileDetails, setProfileDetails] = useState("");
  // const profileDetails = useSelector((state) => state?.basicdata?.customer);
  const [isLoading, setIsLoading] = useState(false);
  const isLoggedin = useSelector((state) => state.userdata.loggedIn);
  const [nameError, setNameError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [inputError, setInputError] = useState("");
  const [inputEmailError, setInputEmailError] = useState("");
  const [timer, setTimer] = useState("00:00");
  const [showResend, setShowResend] = useState(false);
  const timerRef = useRef(null);
  const navigation = useNavigate();
  // const dispatch = useDispatch();
  const dispatch = useDispatch();
  const { progressFunction } = useProgress();
  const [intervalData, setintervalData] = useState(0);
  const [otpLen, setOtpLen] = useState(4);
  const [otp, setOtp] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");

  // useEffect(() => {
  //   console.log("isLoggedin",isLoggedin)
  //   if (isLoggedin) {
  //     getProfileData();
  //   } else navigation(HomePage);
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getProfileData();
  }, []);

  useEffect(() => {
    if (isLoading) {
      let count = 0;
      const intervalVariable = setInterval(() => {
        if (count < 90) {
          dispatch(setProgressData(count));
        } else if (count >= 90) {
          dispatch(setProgressData(90));
          window.clearInterval(intervalVariable);
        }
        count = count + 10;
        // console.log(count);
      }, 100);

      setintervalData(intervalVariable);
      progressFunction(intervalVariable);
    } else {
      dispatch(setProgressData(100));
      window.clearInterval(intervalData);
    }
  }, [isLoading]);

  const getProfileData = async () => {
    setIsLoading(true);
    try {
      const { status, data } = await profileData();
      if (status === 200) {
        setIsLoading(false);
        if (data.status === "success") {
          setProfileDetails(data?.pageContent?.customer);
          setName(data?.pageContent?.customer?.common_customer_First_Name);
          setMobile(data?.pageContent?.customer?.common_customer_Telephone);
          setEmail(data?.pageContent?.customer?.common_customer_Email);
        }
      }
    } catch (e) {
      setIsLoading(false);
      console.error("error msg", e);
    } finally {
      setIsLoading(false);
    }
  };

  // const [showOverlay, setShowOverlay] = useState(false);

  // const validateAndSave = () => {
  //   const nameRegex = /^[a-zA-Z\s]*$/;
  //   const emailRegex =
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   const mobileRegex = /^[0-9]{11}$/;

  //   if (!nameRegex.test(name)) {
  //     setAlertMessage("Invalid name format");
  //   } else if (!emailRegex.test(email)) {
  //     setAlertMessage("Invalid email format");
  //   } else if (!mobileRegex.test(mobile)) {
  //     setAlertMessage("Invalid mobile format");
  //   } else {
  //     profileData.fullName = name;
  //     // profileData.email = formData.email;
  //     profileData.mobile = mobile;
  //     setAlertMessage("Profile updated successfully");
  //   }
  // };

  // const validateAndSave = () => {
  //   const nameRegex = /^[a-zA-Z\s]*$/;
  //   const emailRegex =
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   const mobileRegex = /^[0-9]{11}$/;

  //   if (!nameRegex.test(name)) {
  //     setAlertMessage("Invalid name format");
  //   } else if (!emailRegex.test(email)) {
  //     setAlertMessage("Invalid email format");
  //   } else if (!mobileRegex.test(mobile)) {
  //     setAlertMessage("Invalid mobile format");
  //   } else {
  //     // Dispatch an action to update profile data in Redux
  //     setAlertMessage("Profile updated successfully");
  //   }
  // };

  const openeditPhoto = () => {
    setShowTooltip((prev) => !prev);
    // setShowOverlay((prev) => !prev);
  };

  // const closeditPhoto = () => {
  //   setShowTooltip(false);
  //   setShowOverlay(false);
  // };

  const closeAlert = () => {
    setAlertMessage(null);
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      if (total === 0) {
        setShowResend(true);
        clearInterval(timerRef.current);
      }
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    const { minutes, seconds } = getTimeRemaining(e);
    setTimer(
      (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
    );
    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (timerRef.current) clearInterval(timerRef.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    timerRef.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(
      deadline.getSeconds() +
        (counterCount === 1 ? 40 : (counterCount - 1) * 30 + 40)
    );
    return deadline;
  };

  const handleOpenModal = (content) => {
    setModalContent(content);
    setModalIsOpen(true);
    setShowTooltip(false);
    // setShowOverlay(false);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setImageSrc("");
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImageSrc(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSaveImage = () => {
    // Here you can save the image locally or send it to a server
    handleCloseModal();
  };

  const handleNameChange = (event) => {
    const name = event.target.value;
    setName(name);
    if (checkEmpty(name)) {
      setNameError(true);
      setInputError("This field is required");
    } else if (name.length > 50) {
      setNameError(true);
      setInputError("Input limit exceeded");
    } else {
      setNameError(false);
      setInputError("");
    }
  };

  const handleMobileNoChange = (event) => {
    const mobile = event.target.value;
    if (checkEmpty(mobile)) {
      setMobileError(true);
      setInputError("This field is required");
    } else {
      setMobileError(false);
      setInputError("");
    }
  };

  const handleEmailChange = (event) => {
    const email = event.target.value;
    setEmail(email);
    if (checkEmpty(email)) {
      setEmailError(true);
      setInputEmailError("This field is required");
    } else {
      setEmailError(false);
      setInputEmailError("");
    }
  };

  const resetUserInforForm = () => {
    setName("");
    setEmail("");
    setMobile("");
    setOtp("");
    setInputError("");
    setInputEmailError("");
    setNameError(false);
    setEmailError(false);
    setName(profileDetails?.common_customer_First_Name);
    setMobile(profileDetails?.common_customer_Telephone);
    setEmail(profileDetails?.common_customer_Email);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth", // smooth scroll
    });
  };

  const toggleUserInfoForm = () => {
    resetUserInforForm();
    setCounterCount(0);
    setShowResend(false);
    if (isOpen) {
      setIsOpen(false);
      scrollToTop();
    } else {
      setIsOpen(true);
    }
  };

  const submitProfile = () => {
    setCounterCount(counterCount + 1);
    let hasError = false;
    setOtp("");

    if (checkEmpty(name)) {
      setNameError(true);
      setInputError("This field is required");
      hasError = true;
    } else if (ProperNameRegEx.test(name) === false) {
      setNameError(true);
      setInputError("Invalid name");
      hasError = true;
    }

    if (checkEmpty(email)) {
      setEmailError(true);
      setInputEmailError("This field is required");
      hasError = true;
    } else if (emailIdrRegEx.test(email) === false) {
      setEmailError(true);
      setInputEmailError("Invalid email");
      hasError = true;
    }

    if (!hasError) {
      updateData("No");
    }
  };

  const telephoneOTP = "";
  const telephoneOTPResend = "";
  const emailOTP = "";
  const emailOTPResend = "";
  const voiceOTP = "";

  useEffect(() => {
    setShowResend(false);
    clearTimer(getDeadTime());
  }, [counterCount]);

  const updateData = async (isResend) => {
    setShowResend(false);
    setIsLoading(true);
    setOtp("");
    try {
      const { status, data } = await updateProfile(
        name,
        mobile,
        telephoneOTP,
        telephoneOTPResend,
        email,
        "",
        isResend != "Yes" ? emailOTPResend : "Yes",
        voiceOTP
      );
      if (status === 200) {
        if (data.status === "success") {
          if (!checkEmpty(data.code)) {
            if (data.code === "verify_email") {
              setVisible(true);
              setCounterCount(counterCount + 1);
              // clearTimer(getDeadTime());
            } else {
              if (!checkEmpty(data.msg)) {
                setProfileDetails(data?.customer);
                success(data.msg);
                scrollToTop();
                setIsLoading(false);
                setTimeout(() => {
                  setIsOpen(false);
                }, 0);
              }
            }
          }
        }
      }
    } catch (e) {
      setIsLoading(false);
      // error(e.msg);
    } finally {
      setIsLoading(false);
      // clearAdd();
    }
  };

  const onOtpSubmit = async () => {
    // setIsLoading(true);
    try {
      if (!checkEmpty(otp) && otp.length == 4) {
        const { status, data } = await updateProfile(
          name,
          mobile,
          telephoneOTP,
          telephoneOTPResend,
          email,
          otp,
          emailOTPResend,
          voiceOTP
        );
        if (status === 200) {
          if (data.code === "verify_email") {
            if (!checkEmpty(data.msg)) {
              error(data.msg);
            }
          } else {
            if (data.status === "success") {
              setVisible(false);
              toggleUserInfoForm();
              setProfileDetails(data?.customer);
              if (!checkEmpty(data.msg)) {
                success(data.msg);
              }
            } else {
              error(data.msg);
            }
          }
        }
      } else {
        error("Please enter the valid OTP");
      }
    } catch (data) {
      error(data.msg);
    } finally {
      // clearProfileData();
    }
  };

  // const onOtpSubmit = async () => {
  //   try {
  //     const { status, data } = await submitCheckoutBasketOtp(otp);
  //     if (status === 200) {
  //       if (data.status === "success") {
  //         success(data.msg);
  //       }
  //     }
  //   } catch (data) {
  //     error(data.msg);
  //   } finally {
  //     // clearProfileData();
  //   }
  // };

  // const onOtpSubmit = async () => {
  //   console.log("otpotp", otp);
  //   if (!checkEmpty(otp)) {
  //     const { status, data } = await updateProfile(
  //       name,
  //       mobile,
  //       telephoneOTP,
  //       telephoneOTPResend,
  //       email,
  //       otp,
  //       emailOTPResend,
  //       voiceOTP
  //     );
  //     if (status === 200) {
  //       if (data.code === "success") {
  //         success(data.msg);
  //         getProfileData();
  //       }
  //     }
  //   } else {
  //     error("Please fill in this field");
  //   }
  //   setVisible(false);
  //   setIsOpen(false);
  // };

  const onOpenButton = () => {
    setVisible(true);
    document.body.style.overflow = "hidden";
    document.body.style.height = "100vh";
    document.body.style.setProperty("overflow", "hidden", "important");
  };

  const onCloseButton = () => {
    document.body.style.overflow = "";
    document.body.style.height = "auto";
    setVisible(false);
    setTimer("00:00");
    setCounterCount(0);
    setShowResend(false);
  };

  return (
    <MotionWrapper>
      <div className="">
        {isLoading ? (
          <ProfileLoader
            className={`mdl:min-h-[calc(100vh_-_10.5rem)] mdl:h-auto`}
          />
        ) : (
          <div className="mdl:min-h-[calc(100vh_-_10.5rem)] mdl:h-auto mdl:p-7">
            <div
              className="grid grid-cols-2 items-center gap-6"
              style={{ gridTemplateColumns: "max-content auto" }}
            >
              <div className="relative">
                <img
                  className="w-16 h-16 rounded-full object-cover"
                  loading="lazy"
                  src={Default_pfp}
                  alt=""
                  srcSet=""
                />
                <span
                  className="bottom-0 left-10 absolute p-1.5 w-8 h-8 bg-primary border-2 border-white rounded-full cursor-pointer hidden"
                  // onClick={openeditPhoto}
                  onClick={() => {
                    toggleUserInfoForm();
                  }}
                >
                  <img
                    src={EditIcon}
                    className="w-4 h-4 fill-white"
                    alt=""
                    srcSet=""
                  />
                </span>

                {showTooltip && (
                  <div
                    id="dropdownDots"
                    className="tooltipText absolute top-18 left-0 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 "
                  >
                    <ul
                      className="py-2 text-sm text-gray-700"
                      aria-labelledby="dropdownMenuIconButton"
                    >
                      <li>
                        <a
                          href="#"
                          className="block text-black px-4 py-2 hover:bg-gray-100"
                          onClick={() => handleOpenModal("upload")}
                        >
                          Change Photo
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          className="block text-black px-4 py-2 hover:bg-gray-100"
                          onClick={() => handleOpenModal("delete")}
                        >
                          Delete Photo
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className="w-full">
                <div className="flex justify-between w-full">
                  <div className="text-black-shade-4 text-p6 sm:text-lg mdl:text-p5 font-medium font-inter">
                    {profileDetails &&
                      profileDetails.common_customer_First_Name}
                  </div>
                  <div className="">
                    <Button
                      className={`${
                        isOpen
                          ? "bg-primary border-2 border-primary"
                          : "border-2 border-primary"
                      } flex items-center px-2 py-1 cursor-pointer`}
                      onClick={() => {
                        toggleUserInfoForm();
                      }}
                    >
                      <div className="w-5">
                        <img
                          className={`${isOpen ? "block" : "hidden"}`}
                          loading="lazy"
                          src={Edit}
                          alt=""
                          srcSet=""
                        />
                        <EditRectangle
                          className={`${!isOpen ? "block" : "hidden"}`}
                        />
                      </div>
                      <p
                        className={`hidden sm:block ${
                          isOpen ? "text-white" : "text-primary"
                        }  w-max pl-2 text-sm`}
                      >
                        Change
                      </p>
                    </Button>
                  </div>
                </div>
                <div className="flex-col mdl:flex-row flex relative">
                  <div className="flex ">
                    <div className="w-4 mdl:w-6 pt-0.5">
                      <img src={PhoneIcon} alt="" srcSet="" />
                    </div>
                    <p className="ml-2 text-sm mdl:text-mdl text-black-shade-4">
                      {profileDetails &&
                        profileDetails.common_customer_Telephone}
                    </p>
                  </div>
                  {profileDetails && profileDetails.common_customer_Email && (
                    <div className="hidden mdl:flex mx-5 mt-1 h-4 w-[1.5px] bg-gray-shade-3 items-center"></div>
                  )}
                  {profileDetails && profileDetails.common_customer_Email && (
                    <div className="flex break-all ">
                      <div className="w-4 mdl:w-6 pt-1">
                        <img src={MailIcon} alt="" srcSet="" />
                      </div>
                      <p className="w-[100%] ml-2 text-sm mdl:text-mdl text-black-shade-4">
                        {profileDetails && profileDetails.common_customer_Email}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {isOpen && (
              <div className="font-['Inter'] space-y-5 md:space-y-0 grid md:grid-cols-2 my-10 md:my-8 md:gap-x-5 md:gap-y-5">
                <div className="relative">
                  <label>
                    Full Name<span className="text-primary">*</span>
                  </label>
                  <InputField
                    type="text"
                    placeholder={"Enter Your Name"}
                    value={name}
                    onChange={handleNameChange}
                    // onBlur={() => {
                    //   if (!name.match(/^[a-zA-Z\s]*$/)) {
                    //     setAlertMessage("Invalid name format");
                    //   } else {
                    //     setAlertMessage(null);
                    //   }
                    // }}

                    // disabled
                    maxLength={50}
                  />
                  {nameError && (
                    <span className="text-primary text-sm absolute top-0 right-0">
                      {inputError}
                    </span>
                  )}
                </div>
                {/* <label className="">
                  Mobile<span className="text-primary">*</span>
                  <InputField
                    placeholder="Enter Your Mobile Number"
                    value={mobile}
                    onChange={handleMobileNoChange}
                  />
                  {mobileError && (
                    <span className="text-primary text-sm">{inputError}</span>
                  )}
                </label> */}
                <div className="relative">
                  <label>
                    Email<span className="text-primary">*</span>
                  </label>
                  <InputField
                    placeholder="Enter Your Mail"
                    value={email}
                    onChange={handleEmailChange}
                    // onBlur={() => {
                    //   if (
                    //     !email.match(
                    //       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    //     )
                    //   ) {
                    //     setAlertMessage("Invalid email format");
                    //   } else {
                    //     setAlertMessage(null);
                    //   }
                    // }}
                    // disabled
                    maxLength={50}
                  />
                  {emailError && (
                    <span className="text-primary text-sm absolute top-0 right-0">
                      {inputEmailError}
                    </span>
                  )}
                </div>

                {/* )} */}
              </div>
            )}
            {isOpen && (
              <div className="w-full grid grid-cols-2 gap-4 sm:w-[375px] mt-6 xl:flex justify-between self-center xl:self-start">
                <Button
                  onClick={() => {
                    submitProfile();
                  }}
                  className={`text-white sm:w-44 bg-primary focus:outline-none rounded-lg text-sm inline-flex justify-center items-center px-5 py-2.5 text-center`}
                >
                  Save
                </Button>
                <Button
                  onClick={() => {
                    toggleUserInfoForm();
                  }}
                  className="text-border-shade-1 border border-border-shade-1 sm:w-44 bg-secondary  focus:outline-none rounded-lg text-sm inline-flex justify-center items-center px-5 py-2.5 text-center"
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        )}
        {/* {alertMessage && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-5 w-80 flex justify-between items-center">
            <p>{alertMessage}</p>
            <div className="mb-4">
              <Button className="text-gray-500" onClick={closeAlert}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 11.414l3.536 3.536 1.414-1.414L11.414 10l3.536-3.536-1.414-1.414L10 8.586 6.464 5.05 5.05 6.464 8.586 10l-3.536 3.536 1.414 1.414L10 11.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </Button>
            </div>
          </div>
        </div>
      )} */}
        {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        className=" bg-black bg-opacity-50 z-50 "
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-50"
      >
        <div className="bg-white rounded-lg shadow-lg p-6 w0 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-72 sm:w-96">
          <div className="flex justify-between items-center mb-4">
            <div>{modalContent === "delete" ? "Delete" : "Upload Image"}</div>
            <div onClick={handleCloseModal} className="w-8 cursor-pointer">
              <img className="max-w-full" src={CloseIcon} alt="" srcSet="" />
            </div>
          </div>
          {modalContent === "delete" ? (
            <div className="text-center">
              <p>Do you want to delete the photo?</p>
              <div className="flex justify-center mt-4">
                <Button
                  onClick={() => {
                    // Handle delete logic here
                    handleCloseModal();
                  }}
                  className="bg-primary hover:bg-primary text-white font-bold py-2 px-4 rounded mr-2"
                >
                  Yes
                </Button>
                <Button
                  onClick={handleCloseModal}
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                >
                  No
                </Button>
              </div>
            </div>
          ) : (
            <>
              <div className="text-center">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="mb-4"
                />
                {imageSrc && (
                  <div className="mt-4">
                    <img
                      src={imageSrc}
                      alt="Uploaded"
                      className="max-w-[150px] max-h-[150px] mx-auto"
                    />
                  </div>
                )}
              </div>
              <div className="flex justify-end mt-4">
                <Button
                  onClick={handleSaveImage}
                  className="px-4 py-2 bg-primary text-white rounded-lg"
                >
                  Save{" "}
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal> */}
        <Modal
          isOpen={visible}
          onRequestClose={onCloseButton}
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-50 cursor-pointer"
          // style={modalStyles}
          className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-none border-none bg-white rounded-2xl`}
        >
          <Button
            type="button"
            className="z-50 absolute top-3 right-3 bg-transparent rounded-lg text-sm w-6 h-6 sm:w-8 sm:h-8 ms-auto inline-flex justify-center items-center"
            onClick={onCloseButton}
          >
            <Cross />
            <span className="sr-only">Close modal</span>
          </Button>
          <div className={`relative px-4 sm:px-10 mdl:px-12`}>
            <img
              loading="lazy"
              src={kuickLogo}
              className="flex mx-auto w-[104px] md:w-[133px] my-6 md:my-10"
            />
            <div className="text-2xl sm:text-3xl font-bold leading-[38px] font-inter text-center">
              One Time Password
            </div>
            <div className="text-center">
              <p>We have sent the OTP verification to </p>
              <span>{email}</span>
            </div>
            <div className="pt-6 space-x-2">
              <OtpInput
                length={otpLen}
                onOtpSubmit={setOtp}
                // pageNames={pageNames}
              />
            </div>
            {showResend ? (
              <div className="flex justify-between pt-[23px]">
                <div
                  className="cursor-pointer w-[131px] sm:w-[156px] h-11 px-4 py-1 bg-pink-50 rounded-lg justify-center items-center gap-1 inline-flex"
                  onClick={() => {
                    updateData("Yes");
                    setCounterCount(counterCount + 1);
                  }}
                >
                  <div className="text-center text-slate-500 text-base font-semibold font-inter leading-normal">
                    Resend OTP
                  </div>
                </div>
                <div
                  className="cursor-pointer w-[131px] sm:w-[156px] h-11 px-4 py-1 bg-pink-50 rounded-lg justify-center items-center gap-1 inline-flex"
                  onClick={() => {
                    updateData("Yes");
                    setCounterCount(counterCount + 1);
                  }}
                >
                  <div className="text-center text-slate-500 text-base font-semibold font-inter leading-normal">
                    Voice OTP
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-center mt-4 md:mt-6">
                <span className="text-p6 font-bold">{timer}</span>
              </div>
            )}
            <div className="m-auto w-[328px] mb-12">
              <Button
                // onClick={() => getLogin("otp")}
                // disabled={otp.length !== otpLen || loader}
                onClick={() => onOtpSubmit()}
                type="primary"
                className="w-full py-1 mt-4 md:mt-6 h-12 md:h-14 gap-1 inline-flex justify-center items-center cursor-pointer"
              >
                {/* <VerifyButton /> */}
                <div className="text-center text-white text-base font-semibold font-inter leading-normal">
                  Verify
                </div>
              </Button>
            </div>
            {/* <div
            className={`px-5 md:${step > 1 ? "px-0" : "px-24"} sm:mt-auto`}
          >
            <div className="text-2xl sm:text-3xl font-bold leading-[38px] font-inter">
              {getHeader()}
            </div>
            <div className="text-neutral-900 text-base sm:text-lg font-normal font-inter leading-normal sm:pt-6">
              {getSubHeader()}
            </div>
            {getBody()} */}

            {/* {step === 2 &&
              (showResend ? (
                <div className="flex justify-between pt-[23px]">
                  <div className="cursor-pointer w-[131px] sm:w-[156px] h-11 px-4 py-1 bg-pink-50 rounded-lg justify-center items-center gap-1 inline-flex">
                    <div
                      className="text-center text-slate-500 text-base font-semibold font-inter leading-normal"
                      onClick={() => {
                        // getLogin("otp", "Yes");
                        // setCounterCount(counterCount + 1);
                      }}
                    >
                      Resend OTP
                    </div>
                  </div>
                  <div className="cursor-pointer w-[131px] sm:w-[156px] h-11 px-4 py-1 bg-pink-50 rounded-lg justify-center items-center gap-1 inline-flex">
                    <div
                      className="text-center text-slate-500 text-base font-semibold font-inter leading-normal"
                      onClick={() => {
                        getLogin("otp", "Yes", "VOICE");
                        setCounterCount(counterCount + 1);
                      }}
                    >
                      Voice OTP
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-center mt-4 md:mt-6">
                  <span className="text-p6 font-bold">{timer}</span>
                </div>
              ))} */}

            {/* get otp button */}
            {/* {onSubmit()} */}

            {/* </div> */}
          </div>
        </Modal>
      </div>
    </MotionWrapper>
  );
};

export default Profile;

{
  /* {showOverlay && (
        <div
          className="opac-Bg w-full h-screen absolute bg-black bg-opacity-50 z-10"
          onClick={closeditPhoto}
        ></div>
      )} */
}
