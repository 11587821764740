import React from "react";
import { useEffect, useRef, useState } from "react";
import CheckCircle from "../../components/Icon/CheckCircle";
import CheckboxCheckout from "../../components/Elements/CheckboxCheckout";
import { setCartConfigSession } from "../../utils";
import { changeBasketType } from "../../services";
import { useSelector, useDispatch } from "react-redux";
import { checkEmpty } from "../../utils/helper";
import { error, success } from "../../components/notify";
import { setBasketData } from "../../store/basicData/basicDataSlice";
import { useNavigate } from "react-router-dom";
import { MenuPage } from "../../routes/RoutesPath";
const CheckoutPayment = ({
  storeDetails,
  cartDetails,
  handleFinishOrder,
  setIsLoadingTopBar,
  checkoutStepper,
}) => {
  const navigate = useNavigate();
  const basket = useSelector((state) => state.basicdata.basket);
  const customer = useSelector((state) => state.basicdata.customer);
  const checkoutStep = useSelector((state) => state.checkoutStep);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const walletBalanceItems = basket.pos_basket_Dineorder_Wallet_Text
    ? basket?.pos_basket_Dineorder_Wallet_Text.split(" ")
    : "";

  useEffect(() => {
    if (checkEmpty(basket.basketProducts)) {
      navigate(MenuPage(storeDetails?.store_Slug, storeDetails?.product_Id));
    }
  }, [basket]);

  const handleBasketChange = async (e) => {
    e.stopPropagation();
    console.log("handleBasketChange called", checked);
    let changeStatus = 0;
    if (!checked) {
      changeStatus = 1;
    }
    setIsLoadingTopBar(true);
    try {
      setIsLoadingTopBar(true);
      const { status, data } = await changeBasketType(
        changeStatus,
        "pos_basket_Dineorder_Wallet_Applied"
      );
      if (status === 200) {
        if (data.status === "success") {
          if (!checkEmpty(data.msg)) {
            success(data.msg);
          }
          dispatch(setBasketData(data?.basket));
          setCartConfigSession(data?.cartConfig);
          setChecked(!checked);
        } else if (data.status === "error") {
        }
      }
    } catch (error) {
      console.error("error msg", error);
    } finally {
      setTimeout(() => {
        setIsLoadingTopBar(false);
      }, 500);
    }
  };
  const sectionStepper = (value) => {
    if (value == "prev") {
      basket.pos_basket_Delivery_Type == "Delivery"
        ? checkoutStepper("addressDetails")
        : checkoutStepper("basicDetails");
    }
  };
  return (
    <>
      {checkoutStep.payment.summary === true ? (
        <>
          <div className="flex w-full border bg-red-100">
            <CheckCircle className="fill-green-400" />
          </div>
        </>
      ) : checkoutStep.payment.disp === true ? (
        <>
          <div
            className={`w-full 2xl:w-full h-auto border-t-2 py-5 ${
              checkoutStep.payment.disp === true && "animate-fadedown"
            }`}
          >
            <div className="flex justify-between items-center">
              <div className="text-black text-xl 2xl:text-[26px] font-medium font-inter capitalize">
                Payment
              </div>
            </div>

            {!checkEmpty(customer) &&
              !checkEmpty(customer.common_customer_Id) &&
              basket?.pos_basket_Payment_Type == "Card" &&
              !checkEmpty(basket.pos_basket_Dineorder_Wallet_Text) && (
                <div
                  className="pt-2 sm:pt-4 flex items-center space-x-2 cursor-pointer"
                  onClick={(e) => {
                    handleBasketChange(e);
                  }}
                >
                  <CheckboxCheckout checked={checked} onChange={(e) => {}} />
                  <div>
                    <span className="text-gray-500 text-sm font-semibold font-inter">
                      {walletBalanceItems[1] + " "}
                    </span>
                    <span className="text-primary text-sm font-semibold font-inter">
                      {walletBalanceItems[2] + " "}
                    </span>
                    <span className="text-gray-500 text-sm font-semibold font-inter">
                      {walletBalanceItems[3] +
                        " " +
                        walletBalanceItems[4] +
                        " "}
                    </span>
                    <span className="text-primary text-sm font-semibold font-inter">
                      {walletBalanceItems[5] + " "}
                    </span>
                    <span className="text-gray-500 text-sm font-semibold font-inter">
                      {walletBalanceItems[6] + " " + walletBalanceItems[7]}
                    </span>
                  </div>
                </div>
              )}

            <div className="flex justify-between mt-5 sm:mt-5">
              <button
                onClick={() => sectionStepper("prev")}
                className="border border-[#28303F] sm:w-[135px] w-[48%] h-[46px] sm:h-max sm:py-4  text-md font-medium text-[#28303F] rounded-lg"
              >
                Previous
              </button>

              <button
                onClick={handleFinishOrder}
                className="bg-primary sm:w-[135px] w-[48%] h-[46px] sm:h-max sm:py-4 text-md font-medium text-white rounded-lg"
                data-type="delivery"
                data-check={basket.pos_basket_Delivery_Type}
              >
                {checked ? "Finish Order" : "Proceed to Pay"}
              </button>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default CheckoutPayment;
