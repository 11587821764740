import { Link } from "react-router-dom";

import Gmail from "../Icon/Gmail";
import Layout from "../../layout/Layout";
import Button from "../Elements/Button";
import { useSelector, useDispatch } from "react-redux";
import { success, error } from "../notify";
import { useNavigate } from "react-router-dom";
import { Drawer } from "@mui/material";
import UnsubscribeModal from "../../components/Modal/UnsubscribeModal";

import {
  facebookPageLink,
  instagramPageLink,
  twitterPageLink,
  phoneNumberInfo,
  emailRegEx,
  getDineConfigSession,
} from "../../utils";
import { sendEmail } from "../../services";
import { useState } from "react";
import {
  Faq,
  TermsOfUse,
  TermsConditions,
  PrivacyPolicy,
} from "../../routes/RoutesPath";
import useHomeClick from "../../hooks/useHomeClick";
import { checkEmpty, formatPostcode } from "../../utils/helper";

const Footer = ({ className }) => {
  const { handleHomeRedirect } = useHomeClick();
  const [inputMail, setInputMail] = useState("");
  const [mailError, setMailError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  let base_url = "https://imgcdn.kuick.com/kuickwebsiteimg";
  const isLoggedin = useSelector((state) => state.userdata.loggedIn);
  const navigate = useNavigate();
  const storedData = getDineConfigSession();
  const dispatch = useDispatch();

  const pathname = location.pathname.toLocaleLowerCase();
  const pathParts = pathname.split("/");
  let postcode = "";

  if (pathParts.includes("stores")) {
    const postcodeIndex = pathParts.indexOf("stores") + 1;
    postcode = pathParts[postcodeIndex]?.toLowerCase();
  } else {
    postcode = storedData?.postcode?.toLowerCase();
  }

  const handleMailChange = (e) => {
    const mail = e.target.value;
    setInputMail(mail);
    if (mail.length === 0) {
      setMailError(true);
      setErrorMsg("This field is required");
    } else if (mail.length <= 30) {
      setMailError(false);
      setErrorMsg("");
    } else {
      //
    }
  };

  const sendThankYouEmail = async (email) => {
    try {
      const { status, data } = await sendEmail(email);
      if (status === 200) {
        if (data.status === "success") {
          success(data.msg);
          setInputMail("");
        } else {
          error(data.msg);
        }
      }
    } catch (e) {
      error(e.msg);
    }
  };

  const mailsubscribe = (e) => {
    setErrorMsg("");
    e.preventDefault();
    let hasError = false;
    if (inputMail === "") {
      setMailError(true);
      setErrorMsg("This field is required");
      hasError = true;
    }
    if ((inputMail != "") & !emailRegEx.test(inputMail)) {
      setMailError(true);
      setErrorMsg("Invalid email");
      hasError = true;
    }
    if (!hasError) {
      sendThankYouEmail(inputMail);
    }
  };

  const kuickImgReload = async () => {
    const currentPath = location.pathname?.toLowerCase();
    const targetPath = `/stores/${postcode?.toLowerCase()}`;
    const landingPagePath = `/`;
    if (!checkEmpty(postcode)) {
      handleHomeRedirect(postcode);
    } else {
      navigate(landingPagePath);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleViewUnsubscribe = async () => {
    setIsOpen(true);
  };

  return (
    <div className={`bg-footer w-full  ${className}`}>
      <div className="footerBgImage   bg-gradient-to-b from-transparent via-footer  to-footer ">
        {/* newsletter for tab and large screen */}
        <div className="hidden sm:block xl:hidden w-full px-8 pt-7">
          <div className=" h-[88px] rounded-[8px] bg-primary flex justify-between items-center">
            <div className="px-[14px] py-[17px] w-72">
              <div className="text-base font-semibold text-white 2xl:text-2xl">
                Newsletter
              </div>
            </div>
            <div className="flex space-x-5 items-center pr-4">
              {mailError && <p className="text-white text-sm">{errorMsg}</p>}
              <div className=" flex space-x-3  items-center bg-transparent border border-white p-3 rounded-lg  w-60 ">
                <Gmail className="w-5 h-5 text-white " />
                <input
                  type="text"
                  autoComplete="new-password" // Try this value
                  value={inputMail}
                  className="placeholder-white bg-transparent text-store-card-background sm:text-[13px] focus:outline-none w-full"
                  placeholder="Enter your email address"
                  onChange={handleMailChange}
                />
              </div>

              <Button
                className=" bg-primary sm:bg-white  p-3 rounded-lg text-base font-semibold leading-snug text-primary"
                onClick={mailsubscribe}
              >
                Subscribe&nbsp;Now
              </Button>
            </div>
          </div>
        </div>
        {/* newsletter for tab  */}

        <div className="sm:flex sm:justify-between sm:pt-10 xl:pt-12">
          <Layout className="xl:pt-6">
            <div className="pt-4 pb-6 sm:pt-0 sm:pl-4 xl:pl-0">
              <div
                className="w-[132px] h-[63px] pt-1.5 mx-auto sm:mx-0 cursor-pointer"
                onClick={kuickImgReload}
              >
                <img
                  loading="lazy"
                  src={`${base_url}/landing_page/logo_kuick.png`}
                />
              </div>

              <div className=" text-white text-center py-6 sm:text-left text-[16px]  xl:font-medium sm:w-[280px] xl:w-[260px] font-inter leading-tight  2xl:w-72 tracking-wide font-normal">
                Food Ordering Excellence at Your Fingertips
              </div>

              <div className=" text-white text-center pb-6 sm:text-left text-[16px]  xl:font-semibold sm:w-[280px] xl:w-max font-inter leading-tight   2xl:w-72 tracking-wide font-normal">
                <div>{phoneNumberInfo}</div>
              </div>
              {/* social media start */}
              <div className="flex space-x-3 justify-center sm:justify-start">
                <div className="w-auto h-5 cursor-pointer">
                  <a href={facebookPageLink} target="_blank" rel="noreferrer">
                    <img
                      loading="lazy"
                      src={`${base_url}/landing_page/icon/facebook.svg`}
                    />
                  </a>
                </div>
                <div className="w-auto h-5 cursor-pointer">
                  <a href={twitterPageLink} target="_blank" rel="noreferrer">
                    <img
                      loading="lazy"
                      src={`${base_url}/landing_page/icon/twitter.svg`}
                    />
                  </a>
                </div>
                <div className="w-auto h-5 cursor-pointer">
                  <a href={instagramPageLink} target="_blank" rel="noreferrer">
                    <img
                      loading="lazy"
                      src={`${base_url}/landing_page/icon/instagram.svg`}
                    />
                  </a>
                </div>
              </div>

              {/* social media end */}
              <div className="hidden md:hidden lg:hidden space-x-5 pt-10 pb-6 justify-start">
                <img
                  loading="lazy"
                  src={`${base_url}/home/Maestrooo.png`}
                  className="h-6 sm:h-4 w-auto"
                />
                <img
                  loading="lazy"
                  src={`${base_url}/home/visa.png`}
                  className="w-auto h-6 sm:h-4"
                />
                <img
                  loading="lazy"
                  src={`${base_url}/home/Mastercard.png`}
                  className="h-6 sm:h-4 w-auto"
                />
              </div>
              {/* cards end */}
            </div>
          </Layout>
          <div className="border-b border-zinc-600 sm:hidden" />
          {/* newsletter start mobile */}
          {/* <Layout> */}
          <div className="px-[25px] sm:px-7 md:px-8 lg:pl-12 lg:pr-[130px] xl:px-[60px]  pt-6 pb-8 block sm:hidden">
            <div className="text-base font-semibold text-primary ">
              Newsletter
            </div>
            <div className="mt-4 flex items-center bg-transparent border-[1.5px] border-white-shade-2 rounded-lg w-full p-4">
              <Gmail className="w-5 h-5 text-white-shade-2 " />
              <input
                type="text"
                autoComplete="new-password" // Try this value
                value={inputMail}
                className="w-full placeholder-dark-shade-2 ml-2 bg-transparent text-dark-shade-2 focus:outline-none text-[13px] font-medium "
                placeholder="Enter your email address"
                onChange={handleMailChange}
              />
            </div>
            {mailError && (
              <p className="text-primary text-sm pt-2">{errorMsg}</p>
            )}
            <Button
              className="bg-primary w-full p-4 rounded-lg text-[15px] font-semibold text-white mt-4"
              onClick={mailsubscribe}
            >
              Subscribe Now
            </Button>
          </div>
          {/* </Layout> */}
          {/* newsletter end */}
          <div className="border-b border-zinc-600 sm:hidden" />
          <div className="sm:flex sm:flex-1 lg:flex 2xl:justify-between sm:w-1/2  sm:space-x-4 pt-6 pb-8 px-[25px]">
            <div className="grow  sm:pt-0 xl:pb-10 mb-6 ">
              <div className="text-primary text-[16px] font-semibold 2xl:text-2xl text-center sm:text-left pb-6 sm:py-0 sm:pb-6 sm:text-base">
                Company
              </div>

              <div className="text-[16px] font-normal sm:text-base 2xl:text-xl text-white-shade-2 text-center sm:text-left space-y-5 xl:space-y-4">
                <div className="cursor-pointer">
                  {" "}
                  <Link to={Faq}>FAQ</Link>
                </div>
                <div className="cursor-pointer">
                  {" "}
                  <Link to={Faq}>Price Promise</Link>
                </div>
                <div className="cursor-pointer">
                  {" "}
                  <Link to={TermsOfUse}>Terms of Use</Link>
                </div>
                <div className="cursor-pointer">
                  <Link to={TermsConditions}>Terms & Condition</Link>
                </div>
              </div>
            </div>
            <div className="border-b border-zinc-600" />
            <div className="grow pt-6  sm:pt-0 s">
              <div className=" text-primary text-[16px] font-semibold sm:text-base 2xl:text-2xl text-center sm:text-left pb-6 sm:py-0 sm:pb-6">
                Learn More
              </div>

              <div className="text-base sm:text-base font-normal text-white-shade-2 text-center sm:text-left 2xl:text-xl  space-y-5 xl:space-y-4">
                <div className="cursor-pointer">
                  <Link to={Faq}> Cookies Policy </Link>
                </div>
                <div className="cursor-pointer">
                  <Link to={PrivacyPolicy}> Privacy Policy </Link>
                </div>
                <div className="cursor-pointer">
                  <Link to={Faq}>Refund & Cancellation</Link>
                </div>
                <Drawer anchor={"right"} open={isOpen} onClose={toggleDrawer}>
                  <UnsubscribeModal onClose={toggleDrawer} fromWhere="footer" />
                </Drawer>
                <div className="w-max md:w-[86px] md:h-[40px] ">
                  <Button
                    onClick={() => {
                      handleViewUnsubscribe();
                    }}
                    className="cursor-pointer"
                  >
                    Unsubscribe
                  </Button>
                </div>
              </div>
            </div>
            <div className="hidden px-[25px] sm:px-7 md:px-8 lg:px-12 xl:pr-[60px] xl:pl-[20px]  lg:pl-0  grow sm:hidden xl:block lg:pt-0 xl:relative  ">
              <div className="text-base font-semibold text-primary xl:pb-6 2xl:text-2xl flex items-center justify-between">
                Newsletter
                {mailError && (
                  <p className="text-primary text-sm">{errorMsg}</p>
                )}
              </div>
              {/* newsletter large screen */}
              <div className="flex space-x-3 items-center bg-transparent border border-white-shade-2 rounded-lg w-full pl-4">
                <Gmail className="w-5 h-5 text-slate-300 " />
                <input
                  type="text"
                  autoComplete="new-password" // Try this value
                  value={inputMail}
                  maxLength="100"
                  className="placeholder-dark-shade-2 bg-transparent 2xl:text-xl text-dark-shade-2 w-full focus:outline-none text-xs py-4 pr-4"
                  placeholder="Enter your email address"
                  onChange={handleMailChange}
                />
              </div>
              {/* {mailError && (
                <p className="text-primary text-sm pt-2">{errorMsg}</p>
              )} */}
              {/* newsletter large screen */}
              <Button
                className="bg-primary w-full p-4 2xl:text-xl rounded-lg text-sm font-medium text-white xl:mt-4"
                onClick={mailsubscribe}
              >
                Subscribe Now
              </Button>
            </div>
          </div>
        </div>

        {/* cards Start */}
        <div className="md:hidden flex space-x-5 pt-1 pb-6 sm:pt-6 justify-center sm:justify-start sm:px-[43px] xl:px-12">
          <img
            loading="lazy"
            src={`${base_url}/landing_page/icon/american-express.svg`}
            className="h-6 sm:h-4 w-auto"
          />
          <img
            loading="lazy"
            src={`${base_url}/landing_page/icon/google-pay.svg`}
            className="w-auto h-6 sm:h-4"
          />
          <img
            loading="lazy"
            src={`${base_url}/landing_page/icon/apple-pay.svg`}
            className="w-auto h-6 sm:h-4"
          />
          <img
            loading="lazy"
            src={`${base_url}/home/Maestrooo.png`}
            className="h-6 sm:h-4 w-auto"
          />
          <img
            loading="lazy"
            src={`${base_url}/home/visa.png`}
            className="w-auto h-6 sm:h-4"
          />
          <img
            loading="lazy"
            src={`${base_url}/home/Mastercard.png`}
            className="h-6 sm:h-4 w-auto"
          />
        </div>
        {/* cards end */}

        <div className="border-b border-zinc-600" />
        <div className=" md:flex md:justify-between md:items-center  md:px-16 xl:px-[60px] items-center md:py-8">
          <div className="text-[15px] text-white-shade-2 font-inter font-normal text-center py-8 lg:py-0">
            © Kuick 2024, All Rights Reserved
          </div>
          <div className="hidden md:flex space-x-5  pt-20 sm:pt-6 md:pt-0 justify-center sm:justify-start sm:px-16 xl:pr-5">
            <img
              loading="lazy"
              src={`${base_url}/landing_page/icon/american-express.svg`}
              className="h-5 w-auto"
            />
            <img
              loading="lazy"
              src={`${base_url}/landing_page/icon/google-pay.svg`}
              className="w-auto h-5"
            />
            <img
              loading="lazy"
              src={`${base_url}/landing_page/icon/apple-pay.svg`}
              className="w-auto h-5"
            />
            <img
              loading="lazy"
              src={`${base_url}/home/Mastercard.png`}
              className="h-5 w-auto"
            />
            <img
              loading="lazy"
              src={`${base_url}/home/visa.png`}
              className="w-auto h-5"
            />
            <img
              loading="lazy"
              src={`${base_url}/home/Maestrooo.png`}
              className="h-5 w-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
