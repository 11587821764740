import { useState, useEffect, useRef } from "react";
import useWindowDimensions from "../../hooks/useWindowsDimensons";

const Category = ({ onItemClick, className, data, height, currentItem }) => {
  const { width } = useWindowDimensions();
  const listRefs = useRef([]);
  const mobileListRefs = useRef([]);
  const parentRef = useRef(null);
  const subParentRef = useRef(null);
  const [selectedItem, setSelectedItem] = useState("");
  const [scrollToIndex, setScrollToIndex] = useState(null);

  const handleMenuItemClick = (item, inventoryId) => {
    onItemClick(item, inventoryId);
    setSelectedItem(item);
  };

  useEffect(() => {
    setSelectedItem(currentItem);
    const index = data?.findIndex(
      (val) => val.inventory_product_Name === currentItem
    );
    setScrollToIndex(index);
  }, [currentItem, data]);

  useEffect(() => {
    if (width < 769) {
      // Automatically check and scroll each item into view if necessary
      // mobileListRefs?.current.forEach((item, index) => {
      //   if (index === scrollToIndex) checkIfInView(item);
      // });
      const containerRect = subParentRef.current.getBoundingClientRect();
      const item = mobileListRefs.current[scrollToIndex];
      if (containerRect && item) {
        // Get the item's position relative to the container
        const itemRect = item.getBoundingClientRect();
        if (
          itemRect.left < containerRect.left ||
          itemRect.right > containerRect.right
        ) {
          // Scroll the item into view
          item.scrollIntoView({
            // behavior: "smooth",
            // block: "center",
            inline: "center",
          });
        }
      }
    } else {
      if (scrollToIndex !== null) {
        scrollToItem(scrollToIndex);
      }
    }
  }, [scrollToIndex]);

  // Function to scroll item into view for mobile
  const checkIfInView = (item) => {
    const containerRect = subParentRef.current.getBoundingClientRect();
    const itemRect = item.getBoundingClientRect();
    console.log("------", containerRect, itemRect);

    // Check if the item is out of view horizontally
    if (
      itemRect.left < containerRect.left ||
      itemRect.right > containerRect.right
    ) {
      console.log("-----scroll into view-------");
      // Scroll the item into view
      item.scrollIntoView({
        // behavior: "smooth",
        // block: "center",
        inline: "center",
      });
    }
  };

  // Function to scroll item into view for web
  const scrollToItem = (index) => {
    const container = parentRef.current;
    const item = listRefs.current[index];

    if (container && item) {
      // Get the item's position relative to the container
      const itemRect = item.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();

      if (
        itemRect.top < containerRect.top ||
        itemRect.bottom > containerRect.bottom
      ) {
        // Scroll the item into view
        item.scrollIntoView({
          // behavior: "smooth",
          // block: "nearest", // Ensure it scrolls only if necessary
          inline: "center",
        });
      }
    }
  };

  useEffect(() => {
    // scroll the first item into view on mount
    scrollToItem(0);
  }, []);

  return (
    <div ref={parentRef} className={`${className}`} style={{}}>
      {/* Mobile and Tab Version */}
      <div
        className={`bg-secondary flex scroll-smooth no-scrollbar md:space-x-8 mdl:hidden`}
      >
        <div
          ref={subParentRef}
          className="flex scroll-smooth overflow-x-auto no-scrollbar space-x-4 md:space-x-8 px-4 md:px-8 scroll-m-4"
        >
          {data?.map((item, index) => (
            <span
              ref={(el) => (mobileListRefs.current[index] = el)}
              key={item.inventory_product_Id}
              onClick={(event) => {
                event.preventDefault();
                handleMenuItemClick(item.inventory_product_Name, index);
              }}
              className={`scroll-item font-medium text-sm cursor-pointer w-max text-center whitespace-nowrap py-3 ${
                selectedItem === item.inventory_product_Name
                  ? "text-red-500 border-b-2 border-primary"
                  : "text-black"
              }`}
            >
              {item.inventory_product_Name}
            </span>
          ))}
        </div>
      </div>
      {/* Web Version */}
      {width > 769 && (
        <div
          style={{ height }}
          className={`hidden mdl:block relative xl:w-full overflow-hidden xl:mb-8 bg-store-card-background rounded-lg border border-gray-200 sm:mr-5 xl:mr-0 ${className}`}
        >
          <div
            className={`text-zinc-800 justify-between text-[15px] 2xl:text-xl font-medium font-inter leading-tight h-9 px-5 flex items-center bg-white border-l-4  border-primary`}
          >
            <span>Menu</span>
          </div>
          {/* menu items start */}
          <div
            style={{ maxHeight: Number(height) - 50 }}
            className={`h-full py-2 space-y-0 text-sm overflow-y-auto border-bg-stroke pb-10 visible transition-transform w-full no-scrollbar`}
          >
            {data?.map((item, index) => (
              <div
                ref={(el) => (listRefs.current[index] = el)}
                key={"catgory" + index}
                onClick={(event) => {
                  event.preventDefault();
                  handleMenuItemClick(item.inventory_product_Name, index);
                }}
                className="px-4 flex space-x-2 py-3 hover:bg-gray-200 cursor-pointer"
              >
                <div
                  className={`border-l-2 ${
                    selectedItem === item.inventory_product_Name
                      ? "border-primary"
                      : "border-transparent"
                  }`}
                />
                <label
                  key={item.inventory_product_Id}
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  className={`cursor-pointer flex flex-wrap  ${
                    selectedItem === item.inventory_product_Name
                      ? "text-primary "
                      : "text-zinc-600"
                  }`}
                >
                  {item.inventory_product_Name}
                </label>
              </div>
            ))}
          </div>
          {/* menu item end */}
        </div>
      )}
    </div>
  );
};

export default Category;
