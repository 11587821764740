import Button from "../../Elements/Button";
import Slider from "react-slick";
import RatingAlert from "./ratingAlert";
import {
  setDismissedPendingOrders,
  getDismissedPendingOrders,
} from "../../../utils";
import { copyObject } from "../../../utils/helper";
import { useState } from "react";
import { setDismissedPendingList } from "../../../store/pendingOrders/pendingOrderSlice";
import { useDispatch } from "react-redux";

const OrderAlert = ({ items, setIsPendingOrderList, itemLenChecker }) => {
  const [hideAlert, setHideAlert] = useState(false);
  const dispatch = useDispatch();
  const settings = {
    dots: true,
    arrows: false,
    infinite: itemLenChecker,
    speed: 1000,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    adaptiveHeight: true,
  };

  const setLocalPendingOrder = (item) => {
    const getLocalDismissedPendingOrder = getDismissedPendingOrders();
    const parsedArrDismissedPendingOrder = getLocalDismissedPendingOrder
      ? getLocalDismissedPendingOrder
      : [];
    parsedArrDismissedPendingOrder.push(item);
    if (items.length == parsedArrDismissedPendingOrder.length) {
      setHideAlert(true);
      dispatch(setDismissedPendingList(true));
    } else {
      dispatch(setDismissedPendingList(false));
      setHideAlert(false);
    }
    setDismissedPendingOrders(parsedArrDismissedPendingOrder);
    setIsPendingOrderList(copyObject(items));
  };

  const handleDismissedPendingOrders = (baskId) => {
    const parsedStorageDismissedItems = getDismissedPendingOrders();
    if (parsedStorageDismissedItems !== null) {
      const isExist = parsedStorageDismissedItems.findIndex(
        (val) => val === baskId
      );
      if (isExist !== -1) return false;
    }
    return true;
  };

  const handleDataFromRatingAlert = (item) => {
    setLocalPendingOrder(item);
  };

  return (
    <div>
      {hideAlert ? (
        <></>
      ) : (
        <div className="flex items-start justify-center z-auto">
          <div className="w-[361px] h-[80px] md:w-[425px] md:h-[94.2px] lg:w-[485px] lg:h-[107.5px] 2xl:w-[582px] 2xl:h-[129px]">
            <Slider {...settings}>
              {items.map((item, index) => {
                return handleDismissedPendingOrders(item.pos_basket_Id) ? (
                  item.need_review == 0 ? (
                    <div key={index} className="">
                      <div className="bg-white p-4 rounded-lg shadow-lg w-[361px] h-[80px] md:w-[425px] md:h-[94.2px] lg:w-[485px] lg:h-[107.5px] 2xl:w-[582px] 2xl:h-[129px] flex justify-between items-center">
                        {/* Your content goes here */}
                        <div className="flex flex-row">
                          <img
                            className="w-12 md:w-[60px] lg:w-[67.5px] 2xl:w-[81px]"
                            loading="lazy"
                            src={item.store_Logo}
                            alt=""
                          />
                          <div className="flex flex-col ml-4 md:ml-5">
                            <div className="text-[10px] md:text-[11px]  lg:text-[12.5px] 2xl:text-[15px] text-gray-500 font-bold flex flex-start">
                              Your food is being ordered from
                            </div>
                            <div className="text-primary flex flex-start text-[10px] md:text-[11px]  lg:text-[12.5px] 2xl:text-[15px] text-bold -mt-1">
                              {item.store_Name}
                            </div>
                            <p className="text-gray-500 text-[10px] md:text-[11px] lg:text-[15px] 2xl:text-[18px] mt-2 md:mt-3 lg:mt-[14.83px]">
                              Total Price: {item.store_Currency}
                              {item.pos_basket_Total}
                            </p>
                          </div>
                        </div>
                        <Button
                          className="inline-flex items-center justify-center lg:mr-6 md:mr-4 2xl:mr-7 py-[6.2px] px-[9.3px] md:py-[7.3px] md:px-[11px] lg:py-[8.3px] lg:px-[12.5px] 2xl:py-[10px] 2xl:px-[15px] rounded bg-orderAlertBtn text-orderAlertBtnText text-[10px] lg:text-[11px] 2xl:text-[14px]"
                          onClick={() =>
                            setLocalPendingOrder(item.pos_basket_Id)
                          }
                        >
                          Dismiss
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <RatingAlert
                      key={index}
                      items={item}
                      onSendData={handleDataFromRatingAlert}
                    />
                  )
                ) : null;
              })}
            </Slider>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderAlert;
