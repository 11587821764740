import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  ArrowSmallLeftIcon,
  ArrowSmallRightIcon,
} from "@heroicons/react/24/solid";
import FoodBanner1 from "../../assets/images/landing_page/FoodBanner1.png";
import FoodBanner2 from "../../assets/images/landing_page/FoodBanner2.png";
import FoodBanner3 from "../../assets/images/landing_page/FoodBanner3.png";
// import howItWorksMb from "../../assets/images/landing_page/howItWorks_new_mob.png";
// import howItWorksTab from "../../assets/images/landing_page/howItWorks_new_tab.png";
// import howItWorksWeb from "../../assets/images/landing_page/howItWorks_new_web.png";
// import howItWorksXL from "../../assets/images/landing_page/howItWorks_new_largeweb.png";
import landing from "../../assets/images/landing_page/landing.png";
import LandingUsers from "../../assets/images/landing_page/landing_users.png";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import PhonePreview from "../../assets/images/landing_page/phne_preview.png";
import { LocationSpot } from "../../components/Icon/LocationSpot";
import EveryMood from "../../components/Icon/EveryMood";
import FabFood from "../../components/Icon/FabFood";
import Payment from "../../components/Icon/Payment";
import Tracking from "../../components/Icon/Tracking";
import Accordian from "../../components/accordian/Accordian";
import Dropdown from "../../components/Elements/Dropdown";
import { success, error } from "../../components/notify";
import ReCAPTCHA from "react-google-recaptcha";
import Layout from "../../layout/Layout";
import { getLocationFromLatLng, sendLinkSMS } from "../../services";
import {
  getDineConfigSession,
  postcodeRegEx,
  removeAllSpaceFromString,
  downloadAppStoreUrl,
  downloadGooglePlayStoreUrl,
  isNumeric,
} from "../../utils";
import { HomePage } from "../../routes/RoutesPath";
import Button from "../../components/Elements/Button";

let base_url = "https://imgcdn.kuick.com/kuickwebsiteimg";

const { screen } = window;
import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";
import { setHomeLoader } from "../../store/basicData/basicDataSlice";
import howItWorksMb from "../../assets/images/landing_page/HowItWorksNewMob.png";
import howItWorksTab from "../../assets/images/landing_page/HowItWorksNewTab.png";
import howItWorksWeb from "../../assets/images/landing_page/HowItWorksNewWeb.png";
import howItWorksXL from "../../assets/images/landing_page/HowItWorksNewXl.png";

const LandingPage = () => {
  const images = [FoodBanner1, FoodBanner2, FoodBanner3];
  const [imageIndex, setimageIndex] = useState(0);
  const [current, setCurrent] = useState(0);
  const [currentIndex, setcurrentIndex] = useState(0);
  const [pinCode, setPinCode] = useState("");
  const navigation = useNavigate();
  const settings = useSelector((state) => state.basicdata.settings);
  const filter = useSelector((state) => state.basicdata.filter);
  const [showcaptcha, setShowCaptcha] = useState(false);
  const dispatch = useDispatch();
  const { progressFunction } = useProgress();
  const [isLoading, setIsLoading] = useState(false);
  const [intervalData, setintervalData] = useState(0);
  const [ctoken, setCToken] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [showError, setShowError] = useState(false);
  const [inputError, setInputError] = useState("");
  const [countryCode, setCountryCode] = useState(
    settings?.countryCode ? settings.countryCode[0] : "+44"
  );
  const [mobileNumber, setMobileNumber] = useState("");
  const [isBorderRed, setIsBorderRed] = useState(false);
  const [isTopLevelToggler, setIsTopLevelToggler] = useState(false);
  const dropDownRef = useRef(null);
  const [countryData, setCountryData] = useState("");
  const [defaultCountryList, setDefaultCountryList] = useState([]);
  const slides = [
    {
      id: 1,
      content: "Content for first slide",
    },
    {
      id: 2,
      content: "content for second",
    },
  ];

  const onChange = (value) => {
    setCToken(value);
    setIsVerified(true);
  };
  const prevSlideCa = () => {
    setcurrentIndex(
      (prevIndex) => (prevIndex - 1 + slides.length) % slides.length
    );
  };

  const nextSlideCa = () => {
    setcurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/countries.json");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setDefaultCountryList(jsonData);
        setCountryData(jsonData);
      } catch (err) {
        console.log();
      } finally {
        console.log();
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setimageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    checkSession();
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setIsTopLevelToggler(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [dropDownRef]);

  const checkSession = () => {
    const data = getDineConfigSession();
    if (data) {
      setPinCode(data.locationData?.postcodePretty?.toLowerCase());
    }
  };

  useEffect(() => {
    if (isLoading) {
      let count = 0;
      const intervalVariable = setInterval(() => {
        if (count < 90) {
          dispatch(setProgressData(count));
        } else if (count >= 90) {
          dispatch(setProgressData(90));
          window.clearInterval(intervalVariable);
        }
        count = count + 10;
      }, 100);

      setintervalData(intervalVariable);
      progressFunction(intervalVariable);
    } else {
      window.clearInterval(intervalData);
      dispatch(setProgressData(100));
    }
  }, [isLoading]);

  const getCurrentLocation = () =>
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        setIsLoading(true);
        const { latitude, longitude } = position.coords;
        const { status, data } = await getLocationFromLatLng(
          latitude,
          longitude
        );
        if (status === 200) {
          setIsLoading(false);
          const { locationData } = data;
          setPinCode(locationData?.postcode);
        }
      },
      (err) => {
        setIsLoading(false);
        switch (err.code) {
          case 1:
            error("Permission denied. Please allow permission", true);
            return;
          case 2:
            error("Unable to get location.", true);
            return;
          case 3:
            error("Timeout. Unable to fetch location", true);
            return;
          default:
            error("Something went wrong!! Please try again", true);
        }
      }
    );

  const searchStoresFromLocation = async () => {
    dispatch(setHomeLoader(true));
    try {
      if (
        postcodeRegEx.test(removeAllSpaceFromString(pinCode?.toLowerCase()))
      ) {
        navigation(HomePage + removeAllSpaceFromString(pinCode?.toLowerCase()));
      } else {
        error("Enter valid postcode", true);
        setIsBorderRed(true);
      }
    } catch (err) {
      error("Something went wrong!! Please try again");
    } finally {
      setTimeout(() => {
        dispatch(setHomeLoader(false));
      }, 1500);
    }
  };

  const handleSend = () => {
    if (mobileNumber.length === 11 && mobileNumber.charAt(0) !== "0") {
      setShowError(true);
      setInputError("Invalid number");
    } else {
      sendSMS();
      console.log("done");
      setTimeout(() => {
        clearAll();
      }, 100);
    }
  };

  const sendSMS = async () => {
    try {
      const { status, data } = await sendLinkSMS(
        countryCode,
        mobileNumber,
        ctoken
      );
      if (status === 200) {
        if (data.status === "success") {
          success(data.msg);
        } else {
          error(data.msg);
        }
      }
    } catch (e) {
      error(e.msg);
    }
  };

  const clearAll = () => {
    setCToken("");
    setMobileNumber("");
    setShowCaptcha(false);
    setShowError(false);
    setInputError("");
  };

  return (
    <div className="overflow-hidden	h-max font-[inter]">
      {/* Stats Part */}
      <Layout
        id="topMostPart"
        className={`bg-white-shade-7 flex flex-col sm:flex-row sm:h-[380px]  md:h-[380px]  lg:h-[476px] xl:h-[576px]  items-center py-0`}
      >
        <div className="xl:w-full sm:w-[60%] lg:w-[50%]">
          <div className="flex justify-center pt-10 items-center sm:justify-start sm:pt-0 sm:pb-5 xl:ml-0">
            <img
              loading="lazy"
              src={LandingUsers}
              className="w-14 h-5 2xl:w-16 2xl:h-6"
            />
            <p className="pl-1.5 text-xs font-semibold tracking-widest leading-[13.20px]  sm:text-[11px]  font-inter">
              OVER 10M+ USERS
            </p>
          </div>
          {/* Heading text */}
          <div className="flex flex-col justify-between text-center sm:flex sm:flex-col sm:justify-start sm:text-left xl:ml-0">
            <h2 className="leading-[48.41px] capitalize text-neutral-800 text-[40px] font-semibold font-inter w-72 mx-auto tracking-tighter sm:mx-1 sm:w-[300px] sm:font-semibold sm:text-[40px]  lg:w-[500px]  lg:font-semibold lg:text-[70px] min-[1920px]:text-[130px] lg:leading-[70px]  min-[1920px]:leading-[120px] xl:w-[670px] min-[1920px]:w-[910px]  xl:font-semibold xl:text-[86px] xl:leading-[83px]  xl:ml-0  ">
              enjoy foods all over the{" "}
              <span className="text-primary capitalize relative sm:relative">
                world
                {/* curl image starts  */}
                <img
                  loading="lazy"
                  src={`${base_url}/landing_page/Vector 62.png`}
                  className=" w-[100px] -mt-1 absolute top-full right-0 sm:absolute sm:right-0 sm:top-full sm:ml-0  sm:w-[119.54px] sm:h-[13.41px] sm:-mt-1 lg:ml-[300px] lg:w-[170.66px] lg:h-[22.74px] lg:-mt-3 xl:-mt-4 xl:ml-[380px] xl:w-[202.66px] xl:h-[22.74px] min-[1920px]:w-[304px]"
                  alt="vector62"
                />
                {/* curl image ends  */}
              </span>
            </h2>
            <p className=" text-center leading-[18px] text-gray-shade-6 text-[13px] font-medium font-inter px-5 pt-4 sm:w-[100%] sm:text-left sm:text-base sm:leading-5 sm:px-0 sm:pt-6 sm:pl-1 lg:mt-[10px]  xl:w-[650px]  xl:tracking-normal xl:font-normal xl:text-base   sm:text-[13px]">
              Kuick, the most reliable food ordering app, brings the exquisite
              experience of indulging in global culinary wonders at your
              fingertips.
            </p>
          </div>
          {/* Enter Postcode */}
          <div
            className={`flex items-center bg-slate-50 border ${
              isBorderRed ? "border-primary" : "border-gray-300"
            }  h-12 min-[1920]:h-16 sm:w-max  xl:mx-0 rounded-full mt-9 sm:mt-6 xl:mt-14`}
          >
            <div
              onClick={() => getCurrentLocation()}
              className="flex items-center cursor-pointer h-full"
            >
              <LocationSpot className="w-8 h-8 fill-black pl-3 pr-1" />
              <div
                className={`xxxs:hidden xxs:flex items-center text-center text-black  text-[12px] sm:text-sm 2xl:text-md font-semibold font-inter pr-2 h-full ${
                  isBorderRed
                    ? "border-r border-primary"
                    : "border-r border-gray-300"
                }`}
              >
                Locate&nbsp;Me
              </div>
            </div>

            <input
              className="w-full font-semibold focus:outline-none bg-slate-50 text-[12px] sm:text-sm 2xl:text-[16px] min-[1920]:text-md my-1 pl-2 uppercase h-[46px] sm:w-[140px]  md:w-[170px] lg:w-[200px] xl:w-[240px]"
              placeholder="Enter Postcode"
              value={pinCode}
              maxLength={8}
              autoComplete="new-password"
              onChange={(event) => {
                setPinCode(event.target.value);
                setIsBorderRed(false);
              }}
            />

            <div
              onClick={() => searchStoresFromLocation()}
              disabled={pinCode?.length < 6}
              className="flex mr-[3px] h-[41px] 2xl:h-[87%] px-[12px] sm:px-[19px] bg-black rounded-[38px] 2xl:pl-6 2xl:pr-10 items-center cursor-pointer"
            >
              {/* <Search /> */}
              <div className="w-6 2xl:w-8 pr-2 fill-hover-shade">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M11.6342 11.6098L15 15M13.4444 7.22222C13.4444 10.6587 10.6587 13.4444 7.22222 13.4444C3.78578 13.4444 1 10.6587 1 7.22222C1 3.78578 3.78578 1 7.22222 1C10.6587 1 13.4444 3.78578 13.4444 7.22222Z"
                    stroke="#F8F9FD"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <Button className="w-10 text-[13px] sm:text-xs 2xl:text-[16px] font-bold h-full mr-3 text-white">
                SEARCH
              </Button>
            </div>
          </div>
        </div>
        {/* Platter Image */}
        <div className="flex relative sm:relative  lg:relative w-full  lg:w-full xl:w-full  sm:w-full  h-[350px] sm:h-[280px] lg:h-[340px]   xl:relative justify-center  sm:mr-0 md:mr-0 md:ml-0 ">
          {images.map((img, index) => (
            <img
              key={index}
              loading="lazy"
              src={img}
              alt={`image-${index}`}
              className={`absolute top-0 min-[440px]:translate-x-20 min-[440px]:-top-10   min-[440px]:right-auto  sm:right-0  sm:w-auto  sm:h-[400px]  sm:top-[50%] sm:translate-y-[-50%] translate-x-[-50%] left-[50%] h-[350px]  md:h-[390px]  lg:h-[480px] xl:h-[580px] lg:w-[auto] xl:w-[auto] object-contain  sm:flex sm:justify-end transition-opacity duration-1000 ${
                imageIndex === index
                  ? "opacity-100"
                  : "opacity-0 pointer-events-none"
              }`}
            />
          ))}
        </div>
        {/* Platter Image */}
      </Layout>

      <section>
        <div className="bg-landing-strip-mb md:bg-landing-strip-tab xl:bg-landing-strip-web 2xl:bg-landing-large bg-cover w-auto h-[490px] md:mt-0 flex justify-evenly relative sm:h-[196px] xl:h-[196px] 2xl:h-[215px] sm:flex sm:items-center">
          {/* Stats Starts */}
          <div className="flex flex-col sm:flex-row mx-auto items-center justify-center sm:justify-between  w-full sm:px-10 lg:px-20 xl:px-20 2xl:px-28 py-10">
            <div className="mb-8 sm:mb-0 pt-0 sm:pt-11 sm:py-10  sm:h-28 sm:flex sm:flex-col flex  flex-col items-center justify-center sm:justify-center sm:items-center ">
              <h2 className="w-52 text-center text-[38.40px] text-white leading-[48px] font-bold sm:text-[38.40px] sm:leading-10 2xl:leading-[48px] 2xl:text-[46px]">
                10K+
              </h2>
              <div className="w-52 pt-2 text-center sm:w-[144px] lg:w-[200px] xl:w-[230px] 2xl:w-[310px]">
                <p className="text-white text-[14px] font-normal font-inter leading-5 sm:font-medium sm:text-xs 2xl:text-[20px] 2xl:leading-[25px] 2xl:font-normal ">
                  Satisfied Customers
                  <br />
                  All Great Over The World
                </p>
              </div>
            </div>

            <div className="w-[180px] h-[0.889px] bg-white sm:mt-0 sm:w-0 sm:inline sm:h-[100px] xl:h-[109px] opacity-50 2xl:opacity-30 sm:border-[0.89px] sm:border-white "></div>
            <div className="mb-8 sm:mb-0 pt-0 sm:pt-11 sm:py-10  sm:h-28 sm:flex sm:flex-col flex  flex-col items-center justify-center sm:justify-center sm:items-center ">
              <h2 className="w-52 text-center text-[38.40px] text-white font-bold sm:font-bold sm:text-4xl 2xl:leading-[48px] 2xl:text-[46px]">
                4M
              </h2>
              <div className="w-52 pt-2 text-center sm:w-[184px] lg:w-[200px] xl:w-[230px] 2xl:w-[310px]">
                <p className="text-white text-[13px] font-normal font-inter sm:font-medium sm:text-xs 2xl:text-[20px] 2xl:leading-[25px] 2xl:font-normal">
                  Healthy Dishes Sold
                  <br />
                  Including Milk Shakes Smooth
                </p>
              </div>
            </div>

            <div className="w-[180px] h-[0.889px] bg-white sm:mt-0 sm:w-0 sm:inline sm:h-[100px] xl:h-[109px] opacity-50 2xl:opacity-30 sm:border-[0.89px] sm:border-white "></div>
            <div className="mb-8 sm:mb-0 pt-0 sm:pt-11 sm:py-10  sm:h-28 sm:flex sm:flex-col flex  flex-col items-center justify-center sm:justify-center sm:items-center ">
              <h2 className="w-52 text-center text-[38.40px] text-white font-bold sm:font-bold sm:text-4xl 2xl:leading-[48px] 2xl:text-[46px]">
                99.99%
              </h2>
              <div className="w-52 pt-2 text-center sm:w-[174px] lg:w-[200px] xl:w-[230px] 2xl:w-[310px]">
                <p className="text-white text-[13px] font-normal font-inter sm:font-medium sm:text-xs 2xl:text-[20px] 2xl:leading-[25px] 2xl:font-normal">
                  Reliable Customer Support
                  <br />
                  We Provide Great Experiences
                </p>
              </div>
            </div>
          </div>
          {/* Stats Ends */}
        </div>
      </section>

      {/* How it works */}
      <Layout className="bg-white flex flex-col items-center pt-6 pb-8 sm:pt-8 sm:pb-12 2xl:pt-20 ">
        <h2 className="w-full pb-4 text-center text-black text-[30px] 2xl:text-[48px] font-medium sm:hidden">
          How It Works
        </h2>
        <div className="">
          <img
            loading="lazy"
            src={
              screen.availWidth > 1535
                ? howItWorksXL
                : screen.availWidth >= 1280
                ? howItWorksWeb
                : screen.availWidth >= 750
                ? howItWorksTab
                : howItWorksMb
            }
          />
        </div>
      </Layout>

      {/* Why Choose Our Favorite Food */}
      <Layout className="bg-white-shade-7 flex flex-col items-center pt-8 2xl:mt-[91px] lg:pt-10">
        <h2 className="w-64 text-center sm:w-full text-blac text-[30px] leading-9 md:text-[32px] 2xl:text-[48px] 2xl:mt-8 font-semibold font-inter">
          Why Choose Our Favourite Food
        </h2>
        <div className="flex pt-8 pb-12 2xl:pb-20 2xl:pt-16">
          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-y-[25px] sm:gap-8 lg:gap-8 xl:gap-x-5 w-full">
            {/* 1st Card */}
            <ChooseOurFoodItem
              Icon={EveryMood}
              heading1="Food For"
              className={`3xl:w-[430px]`}
              heading2="EVERY MOOD"
              description="From high street favs to your local regulars, we have got it all! Want it? Kuick it."
            />
            {/* 2nd Card */}
            <ChooseOurFoodItem
              Icon={FabFood}
              className={`3xl:w-[430px]`}
              heading1="Select Your"
              heading2="FAB FOOD"
              description="Now adding your favourite dishes to the cart is Kuick-er. More time for Netflix!"
            />
            {/* 3rd Card */}
            <ChooseOurFoodItem
              Icon={Payment}
              className={`3xl:w-[430px]`}
              heading1="Make Your"
              heading2="PAYMENT"
              description="You can use coupons, loyalty points & wallet to make payments. Easy-Peasy right?"
            />

            {/* 4th Card */}
            <ChooseOurFoodItem
              Icon={Tracking}
              className={`3xl:w-[430px]`}
              heading1="Live Order"
              heading2="TRACKING"
              description="Track your order at every step of the way. Ignorance is no more bliss!"
            />
          </div>
        </div>
      </Layout>

      {/* Our Happy Client Says */}
      <Layout>
        <div className="pb-12">
          <div className="pt-9 py-4 px-20 text-center 2xl:pt-16">
            <h3 className="capitalize text-black text-[28px] xl:text-[32px] 2xl:text-5xl 2xl:font-semibold font-semibold leading-9">
              our happy client's say
            </h3>
          </div>

          <div className="flex justify-between flex-col-reverse xl:grid xl:grid-cols-2 sm:mt-5 relative sm:relative md:flex-row  md:space-y-0 xl:mx-0 2xl:py-5 2xl:pt-16 min-[1920px]:pt-40 min-[1920px]:py-10">
            {/* Card part starts */}
            <div
              style={{
                boxShadow: "0px 0px 37.5px 0px rgba(0, 0, 0, 0.1)",
              }}
              className="w-[95%] md:w-[55%] xl:w-[95%] relative left-[8px] sm:left-[14px] xl:left-[22px] rounded-[26px] xl:rounded-3xl 2xl:rounded-[28px] flex justify-center items-center h-[225px] lg:h-[250px] xl:h-[330px]  2xl:h-[430px] min-[1920px]:h-[430px] p-10"
            >
              <div className="overflow-hidden">
                <div
                  className="relative flex  transition-transform duration-300  "
                  style={{
                    transform: `translateX(-${currentIndex * 100}%)`,
                  }}
                >
                  {slides?.map((slide) => (
                    <div className="min-w-full" key={slide.id}>
                      <div className=" flex lg:pt-8 xl:pt-0 flex-col 2xl:items-start 2xl:px-6 px-4 md:px-0 md:pt-0  w-100 rounded-3xl ">
                        <div className=" flex items-center justify-center md:justify-start space-x-5 mb-5">
                          <div className="w-14 h-[55px] sm:w-[70.55px] sm:h-[70.64px] xl:w-[90px] xl:h-[90px] 2xl:w-[100px] 2xl:h-[100px]">
                            <img
                              loading="lazy"
                              src={`${base_url}/landing_page/client.png`}
                              alt="client"
                              className=""
                            />
                          </div>
                          <div>
                            <h3 className="capitalize text-black text-[20px] font-semibold font-inter sm:w-36 sm:h-5 2xl:text-2xl 2xl:w-max">
                              Willians Jhone
                            </h3>
                            <p className="text-black lg:pt-1 text-sm 2xl:text-base 2xl:w-max font-normal -mt-[5px] sm:mt-0 font-inter sm:w-[133.93px] sm:h-[14.87px] md:mt-1">
                              CEO & Co-Founder
                            </p>
                          </div>
                        </div>

                        <div className="text-center w-full md:text-left">
                          <p className="text-black text-xs 2xl:text-[20px] font-medium font-inter sm:font-medium s sm:text-[14px] xl:text-[14px] lg:text-[14px] lg:font-medium lg:font-inter md:leading-6	">
                            “Kuick is one of the best food ordering apps. I
                            completely rely on it to bring fresh food to my
                            doorstep. With Kuick, it is easy to skip weekend
                            cooking.”
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-full absolute top-1/2 flex justify-between ">
                <div className=" cursor-pointer">
                  <div
                    onClick={prevSlideCa}
                    className=" bg-primary rounded-full flex items-center justify-center -top-4  xl:-left-4 2xl:-left-[30px]  absolute p-2 md:left-[-20px] left-[-15px]"
                  >
                    <ArrowSmallLeftIcon className="p-1 text-white stroke-white w-5 h-5 xl:w-[31px] xl:h-[31px] 2xl:w-10 2xl:h-10 " />
                  </div>
                </div>

                <div className=" cursor-pointer">
                  <div
                    onClick={nextSlideCa}
                    className=" bg-primary rounded-full flex items-center justify-center absolute -top-4  xl:-right-4 2xl:-right-[30px] p-2 md:right-[-15px] right-[-15px]"
                  >
                    <ArrowSmallRightIcon className="p-1 text-white stroke-white w-5 h-5 xl:w-[31px] xl:h-[31px] 2xl:w-10 2xl:h-10" />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full sm:w-auto sm:flex sm:justify-center h-auto pt-4 pb-12  md:pt-0 flex items-center ">
              <div className="md:w-[290px] lg:w-[370px] xl:w-[491px] 2xl:w-[550px] w-[310px] h-auto mx-auto lg:mx-0 sm:mx-auto">
                <img
                  loading="lazy"
                  src={landing}
                  alt="pngwing1"
                  className="h-auto object-contain"
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>

      {/* download our app */}
      <Layout className="bg-white-shade-7 pt-4 sm:pb-10 xl:grid xl:grid-cols-2 xl:place-items-center  lg:items-center lg:pb-[35px] lg:pt-[40px] xl:py-5 ">
        <div className="w-[277.776px] sm:w-[442px] xl:w-[630px] 2xl:w-[650px] 3xl:w-[796px] m-auto xl:m-0">
          <img loading="lazy" src={PhonePreview} alt="" className="" />
        </div>
        {/* contents start */}
        <div className="font-inter sm:pt-3 2xl:pl-[25px] 3xl:pl-0">
          <div className=" text-center py-4 lg:pb-2">
            <div className="text-black-shade-10 px-4 xl:px-0 text-center text-[21px] sm:text-[26px] 2xl:text-4xl 2xl:font-light font-normal font-inter leading-[25px]  sm:tracking-tight xl:text-left xl:font-light lg:text-[26px]">
              Download Our Android Or IOS App.
            </div>
            <div className="text-primary py-1.5 text-[17.737px] text-center xl:text-left xl:pt-0 2xl:pt-4 font-semibold font-inter sm:text-[36px] sm:pt-[0px] sm:pb-[16px] 2xl:pb-8 lg:text-[36px] lg:pt-[5px] 2xl:text-5xl">
              Find takeaways in your pockets!
            </div>
            <div className="2xl:text-xl text-center leading-4 text-stone-500 text-[12px] sm:text-[18px] font-semibold font-inter lg:pb-[8px] xl:text-left">
              Add your phone number below and we will send you the link.
            </div>
          </div>
          <div className="sm:pb-53 sm:px-[90px] md:px-[120px] lg:px-[170px] xl:px-0 2xl:w-[93%] relative">
            <div className="border border-border-shade-3 rounded-md flex justify-between lg:justify-between lg:mx-0 xl:h-[55px] 2xl:h-[60px]">
              <div className="flex items-center space-x-[6px] w-full">
                <div
                  className="pl-3 cursor-pointer h-full flex items-center"
                  onClick={() => setIsTopLevelToggler(!isTopLevelToggler)}
                  ref={dropDownRef}
                >
                  <Dropdown
                    countryList={countryData}
                    setCountryData={setCountryData}
                    value={countryCode}
                    defaultCountryList={defaultCountryList}
                    className="px-1 text-[11px] sm:text-[13px] bg-white-shade-7"
                    onChange={(value) => {
                      setCountryCode(value);
                    }}
                    area="Home"
                    parentToggle={isTopLevelToggler}
                  />
                </div>
                <div className="pr-3 w-full">
                  <input
                    type="text"
                    placeholder="XXXXXXXXXX"
                    value={mobileNumber}
                    autoComplete="new-password" // Try this value
                    inputMode="numeric"
                    maxLength={11}
                    className="font-medium bg-white-shade-7 text-sm sm:text-md w-full font-inter focus:outline-none"
                    onChange={(e) => {
                      const { value } = e.target;
                      let typed = removeAllSpaceFromString(value);
                      if (isNumeric(typed)) {
                        setMobileNumber(typed);
                        setShowError(false);
                        setInputError("");
                        setShowCaptcha(true);
                      } else if (value === "") {
                        setMobileNumber("");
                      }
                    }}
                  />
                </div>
              </div>
              <Button
                onClick={handleSend}
                disabled={
                  (mobileNumber?.charAt(0) === "0"
                    ? mobileNumber?.length <= 10
                    : mobileNumber?.length < 10) || !ctoken
                }
                className={`${
                  (mobileNumber?.charAt(0) === "0"
                    ? mobileNumber?.length <= 10
                    : mobileNumber?.length < 10) || !ctoken
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                } py-2.5 sm:py-4 w-32 sm:w-56 bg-slate-800 flex justify-center mr-[-3px] items-center space-x-2 rounded-r-lg rounded-none`}
              >
                <div className="text-center text-white text-[12px] sm:text-[16px] font-semibold font-inter leading-[18px] 2xl:text[20px] ">
                  Send
                </div>
                <ArrowRightIcon className="w-auto h-3 sm:h-5  lg:h-5 lg:w-5 text-white" />
              </Button>
            </div>
            {showError && (
              <p className="text-primary text-sm pt-2">{inputError}</p>
            )}
            {showcaptcha && (
              <div className="mt-4 md:mt-6">
                <ReCAPTCHA
                  sitekey={import.meta.env.VITE_CAPTCHA_SITE_KEY}
                  onChange={onChange}
                />
              </div>
            )}
          </div>
          <div className="flex justify-center space-x-4 pt-[30px] pb-[40px] xl:py-[30px] xl:justify-start">
            <a
              href={downloadGooglePlayStoreUrl}
              target="_blank"
              rel="noreferrer"
            >
              <img
                loading="lazy"
                src={`${base_url}/landing_page/googleplay.png`}
                alt=""
                className="w-auto h-10 sm:h-[54px] xl:h-[55px] 2xl:h-[76px] lg:h-12 cursor-pointer"
              />
            </a>

            <a href={downloadAppStoreUrl} target="_blank" rel="noreferrer">
              <img
                loading="lazy"
                src={`${base_url}/landing_page/appstore.png`}
                alt=""
                className="w-auto h-10 sm:h-[54px] xl:h-[55px] lg:h-12 2xl:h-[76px] cursor-pointer"
              />
            </a>
          </div>
        </div>
        {/* contents End */}
      </Layout>

      {/* Popular tags near me */}
      <Accordian
        title={"Popular tags near me"}
        className="bg-white fill-red-500"
        dataArray={filter[1]}
        totalArray={filter}
        toggle={"1"}
        postcode={pinCode}
      />
      {/* Popular Offers near me */}
      <Accordian
        title={"Popular offers near me"}
        className="bg-white-shade-7"
        dataArray={filter[2]}
        totalArray={filter}
        postcode={pinCode}
      />
      {/* Popular tags near me */}
      <Accordian
        title={"Popular cuisines near me"}
        className="bg-white"
        dataArray={filter[0]}
        totalArray={filter}
        postcode={pinCode}
      />
    </div>
  );
};

export default LandingPage;

const ChooseOurFoodItem = ({
  Icon,
  heading1,
  heading2,
  description,
  className,
}) => {
  return (
    <div
      className={`border font-['Inter] py-[25px] lg:py-[40px] 2xl:px-5 border-dark-shade-7 group hover:border-none hover:shadow-md flex flex-col items-center justify-center text-center rounded-lg bg-white ${className}`} //min-[1536px]:px-4 min-[1600px]:px-0
    >
      <div className="w-24 h-24 lg:w-[98px] lg:h-[98px]  2xl:w-[115px] 2xl:h-[115px] bg-primary group-hover:bg-white  group-hover:shadow-[0_5px_24px_0px_rgba(6,40,61,0.1)] rounded-full flex items-center justify-center mb-4">
        <Icon className="w-10 h-10 2xl:w-[61px] 2xl:h-[61px] fill-white group-hover:fill-primary" />
      </div>
      <p className="capitalize text-[16px] sm:text-[17px] 2xl:text-xl font-normal 2xl:font-normal font-['Inter] leading-tight sm:pb-1">
        {heading1}
      </p>
      <h3 className="text-black text-[22px] 2xl:text-[26px] font-semibold uppercase mb-3 font-['Inter]">
        {heading2}
      </h3>
      <p className="text-black text-[14px] sm:text-[16px] xl:text-[14px] 2xl:text-[18px] font-inter font-normal leading-tight text-center px-8 lg:px-16 xl:px-8 2xl:px-0 2xl:w-[303px] 2xl:pt-[8px]">
        {description}
      </p>
    </div>
  );
};
