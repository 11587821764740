import { useEffect, useState, useRef } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import Button from "../Elements/Button";
import { checkEmpty } from "../../utils/helper";
import FlagInd from "../../assets/images/IND.png";
import FlagPak from "../../assets/images/PAK.png";
import FlagUk from "../../assets/images/UK.png";
import Search from "../Icon/Search";
import { input } from "@material-tailwind/react";
import cross from "../../assets/icons/cross.svg";

const Dropdown = ({
  countryList,
  setCountryData,
  onChange,
  value,
  defaultCountryList,
  className,
  area,
  parentToggle,
}) => {
  // const ref = useRef(null);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (ref.current && !ref.current.contains(event.target)) {
  //       setToggle(false);
  //     }
  //   };
  //   document.addEventListener("click", handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside, true);
  //   };
  // }, [ref]);

  // const isCountryDetailsAvl = () => {
  //   return data?.every((items) => checkEmpty(items.countryFlagUrl));
  // };

  // const isCountryFlagUrlReturn = () => {
  //   let flagUrl = "";
  //   data
  //     ? data.forEach((items) => {
  //         if (items.countryCode == value) {
  //           flagUrl = items.countryFlagUrl;
  //           return;
  //         }
  //       })
  //     : "";
  //   return flagUrl;
  // };

  // const [toggle, setToggle] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [showCrossIcon, setShowCrossIcon] = useState(false);
  const [filteredData, setFilteredData] = useState(countryList);
  const [flagUrl, setFlagUrl] = useState("https://flagcdn.com/w320/gb.png");
  const [countryCode, setCountryCode] = useState("+44");

  const filterCountries = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (checkEmpty(value)) {
      setShowCrossIcon(false);
      setCountryData(defaultCountryList);
      setFilteredData(defaultCountryList);
    } else {
      setShowCrossIcon(true);
      let allData = Object.assign(defaultCountryList);
      let filtered = allData.filter((item) =>
        item.country_name.toLowerCase().includes(value.toLowerCase())
      );
      setCountryData(filtered);
      setFilteredData(filtered);
    }
  };

  return (
    <div
      className="dropdown flex items-center md:h-10"
      // ref={ref}
      // onClick={() => setToggle(!toggle)}
    >
      <Button
        className={`focus:outline-none font-medium rounded-lg text-[11px] sm:text-[13px] text-center inline-flex ${
          className ?? ""
        }`}
        onClick={() => {
          setInputValue("");
          setShowCrossIcon(false);
          setCountryData(defaultCountryList);
        }}
      >
        <div className="flex items-center ">
          <img src={flagUrl} className="w-6" />
          <span
            className={`ml-1 sm:ml-2 text-xs sm:text-sm font-inter ${
              area != "Login" ? "w-20" : "w-14"
            }`}
          >
            {countryCode}
          </span>
          <span className="ml-1 xs:ml-0 sm:ml-0 flex-col items-end">
            <ChevronDownIcon className="w-3 h-3" />
          </span>
        </div>
      </Button>
      {parentToggle && (
        <div className="">
          <div
            className={`absolute left-0 z-10  divide-y divide-gray-100 rounded-lg shadow bg-gray-50 max-h-[238px] overflow-auto ${
              area != "Login" ? "-ml-3 mt-7 sm:mt-8" : "-ml-[18px] mt-7 sm:mt-9"
            } w-[260px] xs:w-[312px] sm:w-[353px]`}
          >
            <ul
              className="text-[11px] sm:text-[13px] text-gray-700"
              aria-labelledby="dropdownDefaultButton"
            >
              <div
                className="flex justify-between px-2 py-3 border-b"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div className="font-inter text-xs text-loyalityDate w-[90%]">
                  <input
                    type="text"
                    placeholder="Search"
                    className="outline-none text-xs w-full bg-gray-50"
                    onChange={filterCountries}
                    value={inputValue}
                  />
                </div>
                <div className="w-[10%] flex justify-end">
                  {showCrossIcon ? (
                    <div
                      onClick={() => {
                        setInputValue("");
                        setShowCrossIcon(false);
                        setCountryData(defaultCountryList);
                      }}
                    >
                      <img
                        src={cross}
                        className="w-4 h-4 md:h-5 md:w-5"
                        alt="cross-button"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              {countryList?.map((val) => (
                <li key={val.country_name}>
                  <Button
                    onClick={() => {
                      onChange(val.country_code);
                      setFlagUrl(val.flag_url);
                      setCountryCode(val.country_code);
                      // setToggle(false);
                    }}
                    className="block px-2 py-3 hover:bg-gray-100 w-full border-b"
                  >
                    {
                      <div className="flex items-center w-full">
                        <div className="w-[28%] flex items-center">
                          <img src={val.flag_url} className="w-6" />
                          <span className="text-sm sm:text-md font-inter font-medium ml-2">
                            {val.country_code}
                          </span>
                        </div>
                        <div className="w-[72%] flex items-center ml-2">
                          <span className="text-md font-inter text-disabled">
                            {val.country_name}
                          </span>
                        </div>
                      </div>
                    }
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
