import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { CheckoutPage, LandingPage, MenuPage } from "../routes/RoutesPath";
import { error } from "../components/notify";
import { setBasketData } from "../store/basicData/basicDataSlice";
import { getCartConfigSession, setCartConfigSession } from "../utils";
import { checkEmpty } from "../utils/helper";
import { resetCheckoutStep } from "../store/checkout/checkoutSlice";

const useBasketValidateAction = (data) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const basket = useSelector((state) => state.basicdata.basket);
  const cur_page = useSelector((state) => state.curPage.currentPage);
  const storeDetails = useSelector((state) => state.basicdata.storedetails);
  const storeSlug = storeDetails?.store_Slug;
  const productId = storeDetails?.product_Id;

  const basketValidateAction = (data) => {
    if (data.err_type == "store_error") {
      if (!checkEmpty(storeDetails)) {
        navigate(MenuPage(storeSlug, productId));
      } else {
        navigate(LandingPage);
      }
    } else if (
      cur_page == "Cart" ||
      cur_page == "Placeorder" ||
      cur_page == "Payment" ||
      (cur_page != "Confirm" &&
        cur_page != "Decline" &&
        cur_page != "Processing" &&
        data.err_type == "invalid_basket")
    ) {
      if (data.err_type == "do_login") {
        error("Please log in to continue");
      } else if (data.err_type == "invalid_basket") {
        let cartConfig = getCartConfigSession();
        cartConfig.pos_basket_Id = 0;
        let basketDelType = "Delivery";
        if (checkEmpty(basket) && checkEmpty(basket.pos_basket_Delivery_Type)) {
          basketDelType = basket.pos_basket_Delivery_Type;
        }
        let newBasket = {};
        newBasket.pos_basket_Delivery_Type = basketDelType;
        dispatch(setBasketData(newBasket));
        setCartConfigSession(cartConfig);
        if (cur_page == "Cart" || cur_page == "Placeorder") {
          if (!checkEmpty(storeDetails)) {
            navigate(MenuPage(storeSlug, productId));
          } else {
            navigate(LandingPage);
          }
        }
      } else if (data.err_type == "empty_basket") {
        if (!checkEmpty(storeDetails)) {
          navigate(MenuPage(storeSlug, productId));
        } else {
          navigate(LandingPage);
        }
      } else if (
        data.err_type == "valid_error" ||
        data.err_type == "preorder_dispatch_time"
      ) {
        dispatch(
          resetCheckoutStep({
            step: "dispatch",
            basketId: basket?.pos_basket_Id,
          })
        );
        setTimeout(() => {
          navigate(CheckoutPage());
        }, 500);
      } else if (data.err_type == "do_verify_number") {
        dispatch(
          resetCheckoutStep({
            step: "verifyOtp",
            basketId: basket?.pos_basket_Id,
          })
        );
        if (cur_page == "Cart") {
          error("Please Verify Otp");
        } else {
          setTimeout(() => {
            navigate(CheckoutPage());
          }, 500);
        }
      } else if (data.err_type == "delivery_error") {
        navigate(CheckoutPage());
      }
    }
  };

  return basketValidateAction;
};

export default useBasketValidateAction;
