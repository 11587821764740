import { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Drawer } from "@mui/material";
import Checkbox from "../../components/Elements/Checkbox";
import AllergyModal from "../../components/Modal/AllergyModal";
import { checkEmpty } from "../../utils/helper";
import { applyNote } from "../../services";
import OfferSection from "../../components/Elements/OfferSection";
import PercentageIcon from "../../assets/icons/percentage_badge.svg";
import Button from "../../components/Elements/Button";
import { error, success } from "../../components/notify";

const DispatchInfo = ({ instructions }) => {
  const textareaRef = useRef(null);
  const basket = useSelector((state) => state.basicdata.basket);
  const [value, setValue] = useState(basket.pos_basket_Customer_Note);
  const [showCoupon, setShowCoupon] = useState(false);
  const [showAllergy, setShowAllergy] = useState(false);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);

  useEffect(() => {
    setValue(basket.pos_basket_Customer_Note);
  }, [basket.pos_basket_Customer_Note]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleInstructions = async () => {
    if (!checkEmpty(value?.trim())) {
      const { status, data } = await applyNote(value);
      if (status === 200) {
        success(data.msg, true);
      }
    } else {
      error("Please enter instructions");
    }
  };

  const toggleDrawer = () => setShowCoupon(!showCoupon);

  return (
    <div className="bg-white rounded-lg border mb-8">
      <AllergyModal
        visible={showAllergy}
        onClose={() => setShowAllergy(!showAllergy)}
      />
      <div className="">
        <div className="h-max overflow-y-auto">
          <Drawer anchor="right" open={showCoupon} onClose={toggleDrawer}>
            <OfferSection onClose={toggleDrawer} />
          </Drawer>

          <div className="px-4">
            <div className="border-b flex justify-between items-center">
              <input
                onClick={() => setShowCoupon(true)}
                className="text-zinc-500 text-sm xl:text-md font-medium font-inter focus:outline-none w-full py-4"
                placeholder="Enter Your Coupon Code"
                readOnly
              ></input>
              <Button
                onClick={() => setShowCoupon(true)}
                className="text-primary underline underline-offset-4  text-sm xl:text-md font-medium font-inter leading-snug pl-4 py-4"
              >
                Apply
              </Button>
            </div>
            {basket?.coupon && (
              <div className="py-2 mt-1 bg-green-shade-2 bg-opacity-25 rounded-lg border border-green-500 border-opacity-50 flex space-x-3 px-5">
                <img loading="lazy" src={PercentageIcon} alt="" />
                <div className="">
                  <span className="text-green-shade-2 text-[13px] font-semibold font-inter capitalize leading-[17px]">
                    £{basket.basketCoupon} Saved!{" "}
                  </span>
                  <span className="text-green-shade-2 text-[13px] font-normal font-inter leading-[17px]">
                    using the coupon code{" "}
                    <span className="font-semibold">{basket.coupon}</span>
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className=" px-4">
            <div className="border-b flex justify-between items-center">
              <div className="w-full">
                <textarea
                  rows={2}
                  ref={textareaRef}
                  value={value}
                  onChange={handleChange}
                  className="text-zinc-500 text-sm xl:text-md font-medium font-inter focus:outline-none w-full py-4 no-scrollbar resize-none"
                  placeholder="Instruction for the restaurant"
                  maxLength="300"
                ></textarea>
              </div>
              <button
                onClick={handleInstructions}
                className="text-primary underline underline-offset-4 text-sm xl:text-md font-medium font-inter leading-snug pl-4 py-4"
              >
                Add
              </button>
            </div>
          </div>

          <div className="px-4 py-4 space-y-4">
            {Object.keys(instructions ?? {}).map((val, index) => {
              return (
                <div key={"instruct" + index} className="">
                  <div
                    className="flex h-auto items-center cursor-pointer"
                    onClick={(event) => {
                      event.stopPropagation();
                      if (value?.includes(instructions[val])) {
                        setValue(
                          value?.replace(instructions[val] + ".", "").trim()
                        );
                      } else {
                        setValue(
                          value +
                            (checkEmpty(value) ? "" : " ") +
                            instructions[val] +
                            "."
                        );
                      }
                    }}
                  >
                    <Checkbox
                      checked={value
                        ?.toLowerCase()
                        .includes(instructions[val].toLowerCase())}
                      // onChange={(event) => {
                      //   event.stopProgation();
                      // }}
                    />
                    <p className="pl-2 text-[#ABADBD] text-sm font-medium">
                      {instructions[val]}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>

          <div className=" px-4">
            <div className="flex justify-between items-center">
              <div className="w-full">
                <textarea
                  value={
                    basket?.pos_basket_allergy?.length > 0
                      ? `Allergic to ${basket?.pos_basket_allergy?.join(", ")}`
                      : ""
                  }
                  className="text-zinc-500 text-sm xl:text-md font-medium font-inter focus:outline-none w-full py-4 no-scrollbar break-all resize-none"
                  placeholder="Do you have any allergy?"
                  maxLength="300"
                  onChange={() => {}}
                  onClick={() => setShowAllergy(true)}
                  readOnly
                ></textarea>
              </div>
              <Button
                onClick={() => setShowAllergy(true)}
                className="text-primary underline underline-offset-4 text-sm xl:text-md font-medium font-inter leading-snug pl-4 py-4"
              >
                Add
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DispatchInfo;
