import { useState, useEffect } from "react";
import Map from "../../components/Elements/Map";
import RadioButton from "../../components/Elements/RadioButton";
import AddressCardProfile from "../../components/Cards/SavedAddressCard";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import Button from "../../components/Elements/Button";
import AddressCardLoader from "../../components/Loader/ProfilePageLoaders/AddressCardLoader/AddressCardLoader";
import ButtonLoader from "../../components/Loader/ButtonLoader";
import { success, error } from "../../components/notify";
import {
  getLocationFromLatLng,
  profileData,
  getPostCode,
  addAddressDetails,
} from "../../services";
import { postcodeRegEx } from "../../utils";
import MotionWrapper from "../../components/Animation/MotionWrapper";

import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";
import { useSelector, useDispatch } from "react-redux";
import {
  checkEmpty,
  formatPostcode,
  capitalizeString,
} from "../../utils/helper";
import { setUserAddress } from "../../store/userData/userDataSlice";

const Address = () => {
  const [newAddress, setNewAddress] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [activeAddressId, setActiveAddressId] = useState(0);
  const [postcode, setPostcode] = useState("");
  const [houseNo, setHouseNo] = useState("");
  const [streetName, setStreetName] = useState("");
  const [city, setCity] = useState("");
  const [landMark, setLandMark] = useState("");
  const options = ["Home", "Work", "Others"];
  const [selectedOption, setSelectedOption] = useState("Home");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTopBar, setIsLoadingTopBar] = useState(false);
  const [mapShow, setMapShow] = useState(false);
  const [location, setLocation] = useState(null);
  const [newAddress2, setNewAddress2] = useState(true);
  const [selectedLat, setSelectedLat] = useState(null);
  const [selectedLng, setSelectedLng] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [postcodeError, setPostcodeError] = useState(false);
  const [houseNoError, setHouseNoError] = useState(false);
  const [streetNameError, setStreetNameError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [landMarkError, setLandMarkError] = useState(false);
  const [postcodeEmptyError, setPostcodeEmptyError] = useState(false);
  const [houseNoEmptyError, setHouseNoEmptyError] = useState(false);
  const [streetNameEmptyError, setStreetNameEmptyError] = useState(false);
  const [cityEmptyError, setCityEmptyError] = useState(false);
  // const [landMarkEmptyError, setLandMarkEmptyError] = useState(false);
  const [selectedOptionError, setSelectedOptionError] = useState(false);
  const [inputError, setInputError] = useState("");
  const [showButton, setShowButton] = useState(true);
  const [customOption, setCustomOption] = useState("Home");
  const [customOptionError, setCustomOptionError] = useState("");
  const [addressDetails, setAddressDetails] = useState("");
  const [personalDetails, setPersonalDetails] = useState("");
  const [typeAddress, setTypeAddress] = useState("");
  const [onHover, setOnHover] = useState(false);
  const libraries = ["places"];
  const mapContainerStyle = {
    width: "100vw",
    height: "50vh",
  };
  const center = {
    lat: 7.2905715, // default latitude
    lng: 80.6337262, // default longitude
  };
  const [isFormubmit, setisFormubmit] = useState(false);

  const { progressFunction } = useProgress();
  const dispatch = useDispatch();
  const [intervalData, setintervalData] = useState(0);
  const [isRadioChange, setIsRadioChange] = useState(false);

  useEffect(() => {
    if (isLoading || isLoadingTopBar) {
      let count = 0;
      const intervalVariable = setInterval(() => {
        if (count < 90) {
          dispatch(setProgressData(count));
        } else if (count >= 90) {
          dispatch(setProgressData(90));
          window.clearInterval(intervalVariable);
        }
        count = count + 10;
      }, 10);

      setintervalData(intervalVariable);
      progressFunction(intervalVariable);
    } else {
      dispatch(setProgressData(100));
      window.clearInterval(intervalData);
    }
  }, [isLoading, isLoadingTopBar]);

  const addressData = async () => {
    setIsLoading(true);
    setAddressDetails("");
    try {
      const { status, data } = await profileData();
      if (status === 200) {
        if (data.status === "success") {
          setPersonalDetails(data && data.pageContent.customer);
          setAddressDetails(
            data?.pageContent?.customer.common_customer_Address
          );
        }
      }
    } catch (e) {
      console.error("error msg", e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    addressData();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth", // smooth scroll
    });
  }, []);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyAilt_8cTAaLj51xHphaKPx_27jrrcrTAw",
    libraries,
  });

  if (loadError) {
    return <div style={{ color: "#f7f7f7" }}>.</div>;
  }

  if (!isLoaded) {
    return <div style={{ color: "#f7f7f7" }}>.</div>;
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth", // smooth scroll
    });
  };

  const handleLocationUpdate = (newLocation) => {
    setLocation(newLocation);
    setSelectedLat(newLocation.lat);
    setSelectedLng(newLocation.lng);
    setStreetName(newLocation.city);
    setCity(newLocation.street);
    setPostcode(newLocation.postcode);
    setPostcodeEmptyError(false);
    setPostcodeError(false);
    setStreetNameError(false);
    setStreetNameEmptyError(false);
    setCityError(false);
    setCityEmptyError(false);
    if (!postcodeRegEx.test(newLocation.postcode)) {
      error("Enter a valid postcode");
    }
  };

  const openEdit = (addressId) => {
    setShowTooltip((prev) => !prev);
    setActiveAddressId(addressId);
    setIsEditMode(true);
  };

  const closedEdit = () => {
    setShowTooltip(false);
    setIsEditMode(false);
  };

  const handleRadioChange = (option) => {
    setIsRadioChange(true);
    if (option !== "Others") {
      setTypeAddress(option);
      setSelectedOption(option);
      setCustomOption(""); // Reset custom option if another option is selected
      setSelectedOptionError(false);
    } else {
      setSelectedOption(option);
      setTypeAddress(option);
      setSelectedOptionError(false);
    }
  };

  const handleCustomOptionChange = (event) => {
    setIsRadioChange(true);
    const input = event.target.value;
    if (input.length > 20) {
      setCustomOptionError(true);
      setInputError(`input limit exceeded`);
    } else {
      setCustomOption(event.target.value);
      setTypeAddress(event.target.value);
      setCustomOptionError(false);
    }
  };

  const handlePostcodeChange = async (event) => {
    const postcode = event.target.value;
    if (postcode.length === 0) {
      setPostcode("");
      setPostcodeError(false);
      setPostcodeEmptyError(true);
    } else if (postcode.length <= 10) {
      setPostcode(postcode);
      setPostcodeError(false);
      setPostcodeEmptyError(false);
    } else {
    }
  };

  const handleHouseNumberChange = (event) => {
    const houseNo = event.target.value;

    if (houseNo.length === 0) {
      setHouseNo("");
      setHouseNoError(false);
      setHouseNoEmptyError(true);
    } else if (houseNo.length <= 10) {
      setHouseNo(houseNo);
      setHouseNoError(false);
      setHouseNoEmptyError(false);
    } else {
    }
  };

  const handleStreetNameChange = (event) => {
    const streetName = event.target.value;
    if (streetName.length === 0) {
      setStreetName("");
      setStreetNameError(false);
      setStreetNameEmptyError(true);
    } else if (streetName.length <= 50) {
      setStreetName(streetName);
      setStreetNameError(false);
      setStreetNameEmptyError(false);
    } else {
    }
  };

  const handleCityChange = (event) => {
    const city = event.target.value;
    if (city.length === 0) {
      setCity("");
      setCityError(false);
      setCityEmptyError(true);
    } else if (city.length <= 20) {
      setCity(city);
      setCityError(false);
      setCityEmptyError(false);
    } else {
    }
  };

  const handleLandMarkChange = (event) => {
    const landmark = event.target.value;
    if (landmark.length <= 50) {
      setLandMark(landmark);
      setLandMarkError(false);
    } else {
      setLandMarkError(true);
      setInputError(`input limit exceeded`);
    }
  };

  const getPostcodeData = async () => {
    if (postcodeRegEx.test(postcode)) {
      setIsLoading(true);
      try {
        const { status, data } = await getPostCode(postcode);
        if (status === 200) {
          const {
            pos_basket_Customer_Billing_Address_Line_2,
            pos_basket_Customer_Billing_City,
            pos_basket_Customer_Billing_Latitude,
            pos_basket_Customer_Billing_Longitude,
          } = data;
          setStreetName(pos_basket_Customer_Billing_Address_Line_2);
          setCity(pos_basket_Customer_Billing_City);
          setSelectedLat(pos_basket_Customer_Billing_Latitude);
          setSelectedLng(pos_basket_Customer_Billing_Longitude);
          setStreetNameError(false);
          setStreetNameEmptyError(false);
          setCityError(false);
          setCityEmptyError(false);
        } else {
          console.error("Failed to fetch postcode data", status);
        }
      } catch (error) {
        console.error("Postcode Error", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      console.error("Enter a valid postcode");
      // Display an error message to the user
    }
  };

  const clearAdd = async () => {
    closedEdit();
    setNewAddress(false);
    setPostcode("");
    setLandMark("");
    setHouseNo("");
    setStreetName("");
    setCity("");
    setNewAddress2(true);
    setIsEditMode(false);
    setPostcodeEmptyError(false);
    setPostcodeError(false);
    setHouseNoEmptyError(false);
    setStreetNameEmptyError(false);
    setCityEmptyError(false);
    setSelectedOptionError(false);
    setShowButton(true);
    setCustomOption("Home");
    setSelectedOption("Home");
    setIsRadioChange(false);
    scrollToTop();
  };

  const submitAddress = (e) => {
    e.preventDefault();
    let hasError = false;

    if (postcode === "") {
      setPostcodeEmptyError(true);
      hasError = true;
    }

    if (postcodeRegEx.test(postcode) === false) {
      setPostcodeEmptyError(false);
      setPostcodeError(true);
      hasError = true;
    }

    if (houseNo === "") {
      setHouseNoEmptyError(true);
      hasError = true;
    }

    if (streetName === "") {
      setStreetNameEmptyError(true);
      hasError = true;
    }

    if (city === "") {
      setCityEmptyError(true);
      hasError = true;
    }

    if (selectedOption === "") {
      setSelectedOptionError(true);
      hasError = true;
      setInputError(`please select a field`);
    }

    if (!hasError) {
      setisFormubmit(true);
      addAddressData();
    } else {
      // error(`Fill all fields marked with '*'`);
    }
    setTimeout(() => {
      setIsLoadingTopBar(false);
    }, 100);
  };

  const addAddressData = async () => {
    setIsLoadingTopBar(true);
    setShowTooltip(false);

    try {
      const { status, data } = await addAddressDetails({
        common_customer_address_City: capitalizeString(city),
        common_customer_address_Label: isRadioChange ? typeAddress : "Home",
        common_customer_address_Latitude: selectedLat,
        common_customer_address_Longitude: selectedLng,
        common_customer_address_Line_1: capitalizeString(houseNo),
        common_customer_address_Line_2: capitalizeString(streetName),
        common_customer_address_Postcode: postcode,
        common_customer_address_Country: "",
        common_customer_address_County: "",
        common_customer_address_Id: activeAddressId,
      });

      if (status === 200) {
        if (data.status === "success") {
          setAddressDetails(data?.customer?.common_customer_Address);
          dispatch(
            setUserAddress({
              address: data?.customer.common_customer_Address,
            })
          );
          success(data.msg);
          if (checkEmpty(activeAddressId)) {
            scrollToTop();
          }
        }
      }
    } catch (e) {
      error(e.msg);
    } finally {
      setNewAddress2(false);
      setIsLoadingTopBar(false);
      clearAdd();
      setActiveAddressId(0);
    }
  };

  return (
    <MotionWrapper>
      <div className="" id="addressCard">
        <div className="mdl:min-h-[calc(100vh_-_10.5rem)] mdl:h-auto mdl:p-7 overflow-y-scroll h-[1050px] no-scrollbar pb-6">
          <div className="flex justify-between w-full items-center">
            <div className="block text-lg font-bold sm:text-xl sm:font-semibold sm:font-inter">
              {newAddress
                ? isEditMode
                  ? "Edit Address"
                  : "Add New Address"
                : "Address"}
            </div>
            {isLoading
              ? showButton && <ButtonLoader className={`my-2 sm:w-max`} />
              : showButton && (
                  <div className="">
                    <Button
                      className="rounded-lg sm:w-max font-medium text-sm md:text-md border px-4 py-2 bg-primary text-white md:px-8 md:py-3"
                      onClick={() => {
                        setisFormubmit(false);
                        setNewAddress(true);
                        setShowButton(false); // Hide the button
                        setTimeout(() => {
                          setIsLoading(false);
                        }, 100);
                      }}
                    >
                      + Add New Address
                    </Button>
                  </div>
                )}
          </div>
          <div className="space-y-4 mt-5 pb-10 overflow-x-hidden ">
            {newAddress ? (
              <div className=" overflow-x-hidden">
                {newAddress2 && (
                  <div className="text-textPrimary">
                    <div className="flex flex-wrap -mx-3 md:mx-0 mb-6 md:mb-8 lg:mb-8 xl:mb-0">
                      <Map onLocationUpdate={handleLocationUpdate} />
                      <div className="w-full xl:w-1/2 px-3 md:px-0 xl:pl-3 relative">
                        <label
                          htmlFor="email-address-icon"
                          className="block mb-2 text-sm text-gray-900"
                        >
                          Postcode<span className="text-primary">*</span>
                        </label>
                        <div className="">
                          <input
                            type="text"
                            id="email-address-icon"
                            className="bg-white-50 border border-gray-300 text-sm rounded-lg focus:border-blue-500 focus:outline-none block w-full ps-3.5 p-2.5"
                            placeholder="Postcode"
                            value={postcode}
                            autoComplete="off"
                            onChange={handlePostcodeChange}
                            onBlur={getPostcodeData}
                          />
                          {postcodeError && (
                            <span className="text-primary text-sm absolute top-0 right-0 pr-3 md:pr-0">
                              Invalid postcode
                            </span>
                          )}
                          {postcodeEmptyError && (
                            <span className="text-primary text-sm absolute top-0 right-0 pr-3 md:pr-0">
                              This field is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 md:mx-0 mb-0 xl:mb-0">
                      <div className="w-full xl:w-1/2 px-3 md:px-0 mb-6 md:mb-8 relative">
                        <label
                          htmlFor="email-address-icon"
                          className="block mb-2 text-sm"
                        >
                          Flat/House No
                          <span className="text-primary text-sm">*</span>
                        </label>
                        <div className="xl:pr-3">
                          <input
                            type="text"
                            id="email-address-icon"
                            className="bg-white-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500 focus:outline-none block w-full  ps-3.5 p-2.5"
                            placeholder="Flat/House No."
                            value={houseNo}
                            autoComplete="off"
                            onChange={handleHouseNumberChange}
                          />
                        </div>
                        {houseNoError && (
                          <span className="text-primary text-sm absolute top-0 right-0 pr-3 md:pr-0 xl:pr-3">
                            {inputError}
                          </span>
                        )}
                        {houseNoEmptyError && (
                          <span className="text-primary text-sm absolute top-0 right-0 pr-3 md:pr-0 xl:pr-3">
                            This field is required
                          </span>
                        )}
                      </div>

                      <div className="w-full xl:w-1/2 px-3 md:px-0 mb-6 md:mb-8 xl:pl-3 relative">
                        <label
                          htmlFor="email-address-icon"
                          className="block mb-2 text-sm"
                        >
                          Street Name<span className="text-primary">*</span>
                        </label>
                        <div className="relative">
                          <input
                            type="text"
                            id="email-address-icon"
                            className="bg-white-50 border border-gray-300 text-sm rounded-lg focus:border-blue-500 focus:outline-none block w-full ps-3.5 p-2.5"
                            placeholder="Street Name"
                            autoComplete="off"
                            value={streetName}
                            onChange={handleStreetNameChange}
                          />
                        </div>
                        {streetNameError && (
                          <span className="text-primary text-sm absolute top-0 right-0 pr-3 md:pr-0">
                            {inputError}
                          </span>
                        )}
                        {streetNameEmptyError && (
                          <span className="text-primary text-sm absolute top-0 right-0 pr-3 md:pr-0">
                            This field is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 md:mx-0 mb-0 md:mb-0 lg:mb-0 xl:mb-0">
                      <div className="w-full xl:w-1/2 px-3 md:px-0 mb-6 md:mb-8 relative">
                        <label
                          htmlFor="email-address-icon"
                          className="block mb-2 text-sm"
                        >
                          City/Town
                          <span className="text-primary text-sm">*</span>
                        </label>
                        <div className="xl:pr-3">
                          <input
                            type="text"
                            id="email-address-icon"
                            className="bg-white-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500 focus:outline-none block w-full ps-3.5 p-2.5"
                            placeholder="City"
                            value={city}
                            autoComplete="off"
                            onChange={handleCityChange}
                          />
                        </div>
                        {cityError && (
                          <span className="text-primary text-sm absolute top-0 right-0 pr-3 md:pr-0 xl:pr-3">
                            {inputError}
                          </span>
                        )}
                        {cityEmptyError && (
                          <span className="text-primary text-sm absolute top-0 right-0 pr-3 md:pr-0 xl:pr-3">
                            This field is required
                          </span>
                        )}
                      </div>
                      <div className="w-full xl:w-1/2 px-3 md:px-0 xl:pl-3 hidden">
                        <label
                          htmlFor="email-address-icon"
                          className="block mb-2 text-sm"
                        >
                          Landmark
                        </label>
                        <div className="relative">
                          <input
                            type="text"
                            id="email-address-icon"
                            className="bg-white-50 border border-gray-300 text-sm rounded-lg focus:border-blue-500 focus:outline-none block w-full ps-3.5 p-2.5"
                            placeholder="Landmark"
                            value={landMark}
                            autoComplete="off"
                            onChange={handleLandMarkChange}
                          />
                        </div>
                        {landMarkError && (
                          <span className="text-primary">{inputError}</span>
                        )}
                      </div>
                    </div>
                    <div className="w-full">
                      <label
                        htmlFor="email-address-icon"
                        className="block mb-2 text-sm"
                      >
                        Address Type
                      </label>
                      <div className="relative flex flex-row items-center gap-0">
                        {options.map((option) => (
                          <label
                            key={option}
                            className="py-3 ms-0 me-4 sm:text-md flex justify-between cursor-pointer"
                          >
                            <RadioButton
                              name="addressType"
                              value={option}
                              onChange={() => handleRadioChange(option)}
                              checked={selectedOption === option}
                            />
                            <span className="pt-0.5 pl-1.5">{option}</span>
                          </label>
                        ))}
                      </div>

                      {selectedOption === "Others" && (
                        <div className="relative">
                          <input
                            type="text"
                            id="email-address-icon"
                            className="xl:w-1/3 bg-white-50 border border-gray-300 text-sm rounded-lg focus:border-blue-500 focus:outline-none block w-full ps-3.5 p-2.5"
                            placeholder="Other"
                            autoComplete="off"
                            value={customOption}
                            onChange={handleCustomOptionChange}
                          />
                          {customOptionError && (
                            <span className="text-primary">{inputError}</span>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="w-full grid grid-cols-2 gap-4 xl:w-[375px] mt-6 xl:flex justify-between self-center xl:self-start">
                      <Button
                        disabled={isFormubmit}
                        onClick={submitAddress}
                        onHover={(val) => setOnHover(val)}
                        className={`text-white xl:w-44 bg-primary focus:outline-none rounded-lg text-sm inline-flex justify-center items-center px-5 py-2.5 text-center`}
                      >
                        Save
                      </Button>
                      <Button
                        onClick={clearAdd}
                        className="text-border-shade-1 border border-border-shade-1 xl:w-44 bg-secondary focus:outline-none rounded-lg text-sm inline-flex justify-center items-center px-5 py-2.5 text-center"
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                )}
                {mapShow && (
                  <div>
                    <GoogleMap
                      mapContainerStyle={mapContainerStyle}
                      zoom={10}
                      center={center}
                    ></GoogleMap>

                    <div className="my-8 grid md:grid-cols-2 xl:grid-cols-4">
                      <Button
                        className="rounded-lg w-full font-medium border bg-primary text-white px-8 py-2 cursor-pointer"
                        onClick={() => {
                          addAddressData();
                          setNewAddress(false);
                          setMapShow(false);
                        }}
                      >
                        Confirm Location
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="">
                <div className="">
                  <AddressCardLoader
                    visible={isLoading}
                    subClassName={`gap-6 grid`}
                  />
                  <AddressCardProfile
                    showTooltip={showTooltip}
                    closedEdit={closedEdit}
                    openEdit={openEdit}
                    activeAddressId={activeAddressId}
                    setNewAddress={setNewAddress}
                    setisFormubmit={setisFormubmit}
                    setSelectedOption={setSelectedOption}
                    setPostcode={setPostcode}
                    setHouseNo={setHouseNo}
                    setStreetName={setStreetName}
                    setCity={setCity}
                    clearAdd={clearAdd}
                    setIsLoading={setIsLoading}
                    setLandmark={setLandMark}
                    setShowButton={setShowButton}
                    setShowTooltip={setShowTooltip}
                    addressDetails={addressDetails}
                    personalDetails={personalDetails}
                    addressData={addressData}
                    setIsLoadingTopBar={setIsLoadingTopBar}
                    setAddressDetails={setAddressDetails}
                    setSelectedLat={setSelectedLat}
                    setSelectedLng={setSelectedLng}
                    setCustomOption={setCustomOption}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </MotionWrapper>
  );
};

export default Address;
