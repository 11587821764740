import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Drawer } from "@mui/material";
import { getBasicData, fetchMenuItem } from "../../services";
import { setSessionUserId, setSsipSession } from "../../utils";
import MenuBanner from "../menu/MenuBanner";
import { error } from "../../components/notify";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useCallback } from "react";
import { DayTime } from "../../components/Elements/DayTimes";
import { getAbout } from "../../services";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import Reviewcard from "../../components/Cards/ReviewCard";
import MenuLoader from "../../components/Loader/MenuLoaders/MenuLoader";
import { checkEmpty } from "../../utils/helper";
import Layout from "../../layout/Layout";
import AboutOptions from "./aboutmobile/aboutOptions";
import AboutFeedback from "./aboutmobile/aboutFeedback";
import PreferencesLoader from "../../components/Loader/ProfilePageLoaders/PreferencesLoader/PreferencesLoader";
import AboutLandingMobileLoader from "../../components/Loader/AboutPageLoaders/AboutLandingMobile";
import AboutLandingDesktopLoader from "../../components/Loader/AboutPageLoaders/AboutLandingDesktopLoader";
import { Link } from "react-router-dom";
import SubHeader from "../../components/Loader/MenuLoaders/SubHeader";
import { HomePage, MenuPage } from "../../routes/RoutesPath";
import { capitalizeFirstWord } from "../../utils";
import GreaterThan from "../../assets/images/chevron-right.png";

const About = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [storeSlug, setStoreSlug] = useState("");
  const [productId, setProductId] = useState("");
  const [storeData, setStoreData] = useState([]);
  const [storeDetails, setStoreDetails] = useState({});
  const [isFilterDrawerOpen, setFilterIsDrawerOpen] = useState(false);
  const [refreshComponent, setRefreshComponent] = useState(0);
  const [isFixed, setIsFixed] = useState(false);
  const [storeName, setStoreName] = useState("");
  const [isApiLoaded, setIsApiLoaded] = useState(false);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: import.meta.env.VITE_MAP_KEY,
  });
  const [data, setData] = useState({});
  const [aboutElement, setAboutElement] = useState(null);

  const filtertoggleDrawer = () => {
    setFilterIsDrawerOpen(!isFilterDrawerOpen);
  };

  const getInfo = async () => {
    const { status, data } = await getAbout();
    setIsLoading(false);
    if (status === 200) {
      if (data.status === "success") {
        setAboutElement(parse(data?.pageContent.storeContent?.about));
        setData(data?.pageContent);
        setIsLoading(false);
      }
    }
  };

  const onLoad = useCallback(function callback(map) {
    map.setZoom(zoomLevel);
  }, []);

  const onUnmount = useCallback(function callback() {}, []);

  const center = {
    lat: -3.745,
    lng: -38.523,
  };

  const options = {
    gestureHandling: "none", // 'cooperative', 'greedy', 'none', or 'auto'
    mapTypeControl: false,
    streetViewControl: false,
    keyboardShortcuts: false,
  };

  const zoomLevel = 16;

  const openMapNewTab = () => {
    const url = `https://www.google.com/maps/search/?api=1&query=${storeDetails.store_Latitude},${storeDetails.store_Longitude}`;
    window.open(url, "_blank");
  };

  const metaDescription = document.querySelector('meta[name="description"]');
  const metaOgDescription = document.querySelector(
    'meta[property="og:description"]'
  );
  const metaOgTitle = document.querySelector('meta[property="og:title"]');

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  useEffect(() => {
    if (!checkEmpty(storeDetails)) {
      document.title = `About ${data?.storedetails?.store_Name} in ${
        data?.storedetails?.store_City
      } - Order online from  ${import.meta.env.VITE_PRODUCT_NAME}`;
      metaOgTitle.setAttribute(
        "content",
        `About ${data?.storedetails?.store_Name} in ${
          data?.storedetails?.store_City
        } - Order online from  ${import.meta.env.VITE_PRODUCT_NAME}`
      );
      metaDescription.setAttribute(
        "content",
        `${data.storedetails.store_Name}, ${data.storedetails.store_City}: View reviews, menu, contact, location, and more for ${data.storedetails.store_Name}  Takeaway and Restaurant.`
      );
      metaOgDescription.setAttribute(
        "content",
        `${data.storedetails.store_Name}, ${data.storedetails.store_City}: View reviews, menu, contact, location, and more for ${data.storedetails.store_Name}  Takeaway and Restaurant.`
      );
    } else {
      document.title = `About - Order online from ${
        import.meta.env.VITE_PRODUCT_NAME
      }`;
      metaOgTitle.setAttribute(
        "content",
        `About - Order online from ${import.meta.env.VITE_PRODUCT_NAME}`
      );
      metaDescription.setAttribute(
        "content",
        ` View reviews, menu, contact, location, and more for Takeaway and Restaurant.`
      );
      metaOgDescription.setAttribute(
        "content",
        ` View reviews, menu, contact, location, and more for Takeaway and Restaurant.`
      );
    }
  }, [isApiLoaded]);

  useEffect(() => {
    getMenuItem();
  }, [storeSlug, productId]);

  useEffect(() => {
    const paths = location?.pathname?.split("/");
    setStoreSlug(paths[2]);
    setProductId(paths[3]);
  }, [location.pathname]);

  const getMenuItem = async () => {
    if (!checkEmpty(storeSlug) && !checkEmpty(productId)) {
      const { status, data } = await fetchMenuItem(storeSlug, productId);
      if (status == 200) {
        if (data.status === "success") {
          setSsipSession(data.store?.store_Config?.ssip);
          setSessionUserId(data.store?.store_Config?.usrId);
          setStoreData(data.store);
          setStoreName(data.store.store_Name);
          getBasicDetails();
        } else {
          error("Something went wrong!!Please try again3");
          setIsLoading(false);
        }
      } else {
        error("Something went wrong!!Please try again");
        setIsLoading(false);
      }
    }
  };

  const getBasicDetails = async () => {
    const { status, data } = await getBasicData();
    if (status == 200) {
      if (data.status === "success") {
        setIsApiLoaded(true);
        if (!checkEmpty(data.storedetails)) {
          setStoreDetails(data?.storedetails);
          getInfo();
        }
      } else {
        error("Something went wrong!!Please try again");
        setIsLoading(false);
      }
    } else {
      error("Something went wrong!!Please try again");
      setIsLoading(false);
    }
  };

  const closeDrawerNotification = () => {
    filtertoggleDrawer();
    setRefreshComponent((prev) => prev + 1);
  };

  const isSticky = () => {
    const scrollTop = window.scrollY;
    console.log(scrollTop, "MNMNMNMNMNM");
    setIsFixed(scrollTop > 80);
  };

  const setReviewCardFeedbackSubmit = () => {};

  const handleStoreNameClicked = () => {
    const parts = location.pathname.split("/");
    navigate(MenuPage(parts[2], parts[3]));
  };

  return (
    <div>
      <Layout
        className={` ${
          isFixed
            ? "fixed animate-fadedown top-[76px] left-0 shadow-xl px-[25px] sm:px-7 md:px-8 lg:px-12 xl:px-[60px]"
            : "animate-fadeup"
        } bg-white w-full z-50 mdl:flex items-center hidden`}
      >
        <Link to={HomePage} className="flex items-center">
          {isLoading ? (
            <SubHeader />
          ) : (
            <span className="text-xs sm:text-sm font-normal text-grey">
              {capitalizeFirstWord("Home")}
            </span>
          )}
          <img src={GreaterThan} className="w-4 h-4" loading="lazy" />
        </Link>
        <div
          onClick={handleStoreNameClicked}
          className="flex items-center cursor-pointer"
        >
          {isLoading ? (
            <SubHeader />
          ) : (
            <span className="text-xs sm:text-sm font-normal text-grey">
              {capitalizeFirstWord(storeName)}
            </span>
          )}
          <img src={GreaterThan} className="w-4 h-4" loading="lazy" />
        </div>
        <Link to={location.pathname} className="flex items-center">
          {isLoading ? (
            <SubHeader />
          ) : (
            <span className="text-xs sm:text-sm font-normal text-active-route">
              {capitalizeFirstWord("About")}
            </span>
          )}
        </Link>
      </Layout>
      {isLoading ? (
        <MenuLoader subClassName={`h-46 lg:h-60 xl:h-72 relative`} />
      ) : (
        <MenuBanner
          storeSlug={storeSlug}
          productId={productId}
          storeData={storeDetails}
        />
      )}
      <Layout>
        {isLoading ? (
          <div className="mt-5 mdl:mt-0">
            <AboutLandingMobileLoader className="block mdl:hidden" />
          </div>
        ) : (
          <AboutOptions
            className="block mdl:hidden"
            storeData={storeData}
            restaurantInfo={data}
          />
        )}

        {checkEmpty(data.storeTiming) ? (
          <div className="mt-5 mdl:mt-0">
            <AboutLandingDesktopLoader className="hidden mdl:flex" />
          </div>
        ) : (
          <>
            <div className="w-full hidden mdl:block">
              <div className="flex flex-row">
                <div className="w-[48%] pb-4">
                  {isLoaded && (
                    <GoogleMap
                      mapContainerClassName="w-auto h-[369px] mt-[17px]"
                      center={center}
                      zoom={zoomLevel}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                      options={options}
                    >
                      {/* Child components, such as markers, info windows, etc. */}
                      <div className="relative w-full flex justify-center ">
                        <div className="absolute flex justify-between w-[200px] px-2 items-center">
                          <div className=" flex items-center bg-white h-min rounded-lg px-[6px] py-[4.5px] mt-2">
                            <div className="text-[8.5px] font-semibold">
                              {storeDetails &&
                                storeDetails?.storerating?.Rating}
                            </div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="7"
                              height="7"
                              viewBox="0 0 7 7"
                              fill="none"
                            >
                              <path
                                d="M5.10309 6.625L3.25 5.60581L1.3962 6.625L1.75106 4.46479L0.25 2.93526L2.32239 2.6203L3.25 0.625L4.17761 2.6203L6.25 2.93526L4.74894 4.46554L5.10309 6.625Z"
                                fill="#FFC529"
                              />
                            </svg>
                            <div className="text-[8.5px] font-normal text-black-shade-8">
                              (
                              {storeDetails &&
                                storeDetails?.storerating?.feedbackCount}
                              +)
                            </div>
                          </div>
                        </div>

                        <Marker position={center && center} />
                      </div>
                    </GoogleMap>
                  )}

                  <div className="pt-4 text-zinc-800 mdl:text-[16px] sm:leading-tight font-bold font-inter leading-[15px]">
                    Delivery Starts At
                  </div>
                  <div className="pt-2 pr-3 h-auto w-auto">
                    {data?.storeTiming?.Delivery?.map((day, ind) => {
                      return (
                        <DayTime
                          key={ind}
                          currentDay={data?.cur_day}
                          data={day}
                          isNewAbout={true}
                        />
                      );
                    })}
                  </div>

                  <div className="">
                    <div className="pt-5 text-zinc-800 mdl:text-[16px] font-bold font-inter leading-[15px]">
                      Collection Starts At
                    </div>
                    <div className="pt-2 pr-3 h-auto w-auto">
                      {data?.storeTiming?.Collection?.map((day) => (
                        <DayTime
                          key={day}
                          currentDay={data?.cur_day}
                          data={day}
                          isNewAbout={true}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="w-[52%] ml-4">
                  <div className="font-bold mdl:text-[16px] mdl:pt-3 xl:pt-3">
                    About
                  </div>

                  <div className="pt-1 text-gray-shade-5 text-[14px] font-normal font-inter pb-2 sm:pb-4">
                    <div>{aboutElement}</div>
                  </div>
                  <div
                    onClick={() => filtertoggleDrawer()}
                    className="cursor-pointer py-2.5 px-3 border-2 border-primary text-primary rounded-md text-center mb-4 text-xs"
                  >
                    Write a Review
                  </div>
                  <div className="">
                    <Reviewcard
                      key={refreshComponent}
                      setIsLoading={setIsLoading}
                      isLoading={isLoading}
                    />
                  </div>
                  <Drawer
                    className="mdl:block"
                    anchor="right"
                    open={isFilterDrawerOpen}
                    onClose={filtertoggleDrawer}
                  >
                    <AboutFeedback
                      onNotification={closeDrawerNotification}
                      onClose={filtertoggleDrawer}
                    />
                  </Drawer>
                </div>
              </div>
            </div>
          </>
        )}
      </Layout>
    </div>
  );
};

export default About;
