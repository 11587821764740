import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumbs } from "../../components/Navigation/BreadCrumbs";
import React, { useRef } from "react";
import { ColorRing } from "react-loader-spinner";
import Layout from "../../layout/Layout";
import { chatWithAndWhatApp, chatWithAndGmail } from "../../utils";

import { useLocation } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import "tailwindcss/tailwind.css";
import { current } from "@reduxjs/toolkit";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";

const FaqTab = ({ data, item }) => {
  const [Show, setShow] = useState(false);
  const [step, setStep] = useState(0);
  const [title, setTitle] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    if (item >= 0) {
      setShow(true);
      setStep(item);
      setTitle(data[item].title);
    } else {
      setShow(false);
      setStep(item);
    }
  }, [item]);

  const handleClick = (ref, title, step) => {
    setShow(true);
    setStep(step);
    setTitle(title);
  };

  const backToList = () => {
    setShow(false);
    setStep(0);
    navigate("/frequently/asked/questions");
  };

  return (
    <div className=" w-full mt-5 ">
      {Show == true ? (
        <div className="flex items-center	 pl-4  w-full bg-white h-[55px] sm:h-[60px] md:h-[80px] fixed top-0 left-0 z-[999]">
          <ChevronRightIcon
            onClick={() => backToList()}
            className={` cursor-pointer rotate-180	 w-8 h-14  2xl:w-5 2xl:h-5`}
          />
          <div className="w-full ml-[-24px] text-center font-extrabold	">
            {title}
          </div>
        </div>
      ) : (
        <></>
      )}

      {Show == false ? (
        <div className="grid w-[100%]  relative " key='faqtab'>
          <div className=" w-full rounded-lg  bg-white">
            {data.map((item, key) =>
              key == 8 ? (
                <Link
                  className="grid w-[100%]"
                  key={'faqt'+key}
                  to={chatWithAndWhatApp}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div  className="border-b cursor-pointer  bg-white">
                    <div className="flex  px-5 py-3 text-[11px] md:text-[13px] font-medium w-full	">
                      <div className=" w-full">
                        <p dangerouslySetInnerHTML={{ __html: item.title }}></p>
                        <div className="flex justify-center w-[20%]"> </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ) : key == 9 ? (
                <Link
                  className="grid"
                  key={'faqt'+key}
                  to={chatWithAndGmail}
                  target="_blank"
                >
                  <div  className="border-b cursor-pointer  bg-white">
                    <div className="flex  px-5 py-3 text-[11px] md:text-[13px] font-medium w-full	">
                      <p dangerouslySetInnerHTML={{ __html: item.title }}></p>
                      <div className="w-[20%]"> </div>
                    </div>
                  </div>
                </Link>
              ) : key == 10 ? (
                <Link  key={'faqt'+key} className="grid">
                  <div  className=" cursor-pointer  bg-white rounded-br-md	rounded-bl-md	">
                    <div className="flex  px-5 py-3 text-[11px] md:text-[13px] font-medium w-full	">
                      <p dangerouslySetInnerHTML={{ __html: item.title }}></p>
                      <div className="w-[20%]"> </div>
                    </div>
                  </div>
                </Link>
              ) : (
                <div
                key={'faqt'+key}
                  className="grid"
                  onClick={() => handleClick(item.ref, item.title, key)}
                >
                  <div  className="border-b cursor-pointer  bg-white">
                    <div className="flex  px-5 py-3 text-[11px] md:text-[13px] font-medium w-full	">
                      <div className=" flex w-full">
                        <div className="flex w-[90%]"> {item.title}</div>
                        <div className="flex w-[20%]  justify-end	">
                          <ChevronRightIcon
                            className={` w-4 h-4 2xl:w-5 2xl:h-5`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      ) : (
        <></>
      )}

      {Show == true ? (
        <div className="grid grid-flow-col w-[100%] h-[100%]  sm:overflow-scroll md:overflow-x-hidden		">
          <div className="grid w-full rounded-lg  ">
            {data.map((item, key) =>
              step == key ? (
                <div ref={item.ref}  key={'faqt'+key}>
                  <div
                    className="mt-0"
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </div>
              ) : (
                <></>
              )
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default FaqTab;
