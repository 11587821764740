import { Navigate } from "react-router-dom";
import { getDineConfigSession, removeAllSpaceFromString } from "../utils";

export default function HomeLayout() {
  const data = getDineConfigSession();

  if (data) {
    if (data.locationData?.hasOwnProperty("postcodePretty")) {
      return (
        <Navigate
          to={
            "/stores/" +
            removeAllSpaceFromString(data.locationData.postcodePretty.toLowerCase())
          }
          replace
        />
      );
    } else {
      return <Navigate to={"/"} replace />;
    }
  } else {
    return <Navigate to={"/"} replace />;
  }
}
