import RatingStar from "../../Icon/RatingStar";
import { Drawer } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import PendingOrderRating from "../../../pages/pendingorders";
import { error } from "../../notify";
import { useRef } from "react";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

const RatingAlert = ({ items, onSendData }) => {
  const [isFilterDrawerOpen, setFilterIsDrawerOpen] = useState(false);
  const loggedIn = useSelector((state) => state.userdata.loggedIn);
  const parentRef = useRef(null);
  const childRef = useRef(null);

  const filtertoggleDrawer = () => {
    setFilterIsDrawerOpen(!isFilterDrawerOpen);
  };

  const closeDrawerNotification = () => {
    filtertoggleDrawer();
  };

  const sendDataToParent = (data) => {
    onSendData(data);
  };

  const handleParentClick = (e) => {
    if (childRef.current && childRef.current.contains(e.target)) {
      sendDataToParent(items.pos_basket_Id);
    } else if (parentRef.current && parentRef.current.contains(e.target)) {
      if (loggedIn) {
        setFilterIsDrawerOpen(true);
      } else {
        error(<>You are currently not logged in. Please log in</>);
      }
    }
  };

  return (
    <div className="flex items-start justify-center z-auto">
      <div
        className="bg-white p-4 rounded-lg shadow-lg w-[361px] h-[80px] md:w-[425px] md:h-[94.2px] lg:w-[485px] lg:h-[107.5px] 2xl:w-[582px] 2xl:h-[129px] flex justify-between items-center cursor-pointer"
        ref={parentRef}
        onClick={(e) => {
          handleParentClick(e);
        }}
      >
        {/* Your content goes here */}
        <div className="flex flex-row flex-1 items-center">
          <img
            className="w-12 md:w-[60px] lg:w-[67.5px] 2xl:w-[81px]"
            loading="lazy"
            src={items.store_Logo}
            alt=""
          />
          <div className="ml-4 md:ml-5">
            <div className="text-xs md:text-[14px] lg:text-[15px] text-primary font-inter font-semibold flex flex-start leading-4 2xl:leading-5 md:mt-1 lg:mt-2 2xl:mt-3">
              {items.store_Name}
            </div>
            <p className="text-gray-500 text-[10px] md:text-xxs lg:text-xs font-inter md:mt-1 flex">
              {/* <span>
                <RatingStar className="w-7 md:w-8 mdl:w-9" />{" "}
              </span> */}
              <span className="flex items-end">
                {/* <span className="text-gray-shade-16">Please tap to </span> */}
                <span className="text-black font-extrabold text-[10px] mdl:text-[12px] underline">
                  Rate your experience{" "}
                </span>
                <ChevronRightIcon className="w-4 h-4 2xl:w-6 2xl:h-6" />
                {/* <span className="text-gray-shade-16">with us.</span> */}
              </span>
            </p>
          </div>
        </div>
        <button
          ref={childRef}
          className="bg-primary text-white font-inter text-[10px] md:text-[11px] inline-flex items-center justify-center lg:mr-6 md:mr-4 2xl:mr-7 py-[6.2px] px-[9.3px] md:py-[7.3px] md:px-[11px] lg:py-[8.3px] lg:px-[12.5px] 2xl:py-[10px] 2xl:px-[15px] rounded"
        >
          Dismiss
        </button>
        <Drawer
          className="mdl:block"
          anchor="right"
          open={isFilterDrawerOpen}
          onClose={filtertoggleDrawer}
        >
          <PendingOrderRating
            item={items}
            onNotification={closeDrawerNotification}
            onClose={filtertoggleDrawer}
          />
        </Drawer>
      </div>
    </div>
  );
};

export default RatingAlert;
