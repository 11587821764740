import { useState, useEffect, useRef } from "react";
import { Drawer } from "@mui/material";
import MedalIcon from "../../assets/images/profile_section/medal.png";
import { loyaltyData, loyaltyStoreData } from "../../services/index";
import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";
import { useSelector, useDispatch } from "react-redux";
import Card from "../../components/Cards/Card";
import LazyLoadImageComponent from "../../components/Image/LazyLoadImage";
import logo_cover from "../../assets/images/default_images/logo_cover.jpg";
import right_arrow from "../../assets/images/profile_section/arrowRight.png";
import { error } from "../../components/notify";
import LoyaltyTabDrawer from "./LoyaltyTabDrawer";
import nodata_found from "../../assets/images/no-data-found/profile/loyalty/nodata.png";
import LoyaltyLoader from "../../components/Loader/ProfilePageLoaders/LoyaltyLoader/LoyaltyLoader";

const LoyaltyTab = () => {
  const [loyaltyStorePoints, setLoyaltyStorePoints] = useState("");
  const [loyalty, setloyaltyData] = useState([]);
  // const [loyaltyDetails, setLoyaltyDetails] = useState("");
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(3);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { progressFunction } = useProgress();
  const [intervalData, setintervalData] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [backdropVisible, setBackdropVisible] = useState(false);
  const [item, setitem] = useState("");

  useEffect(() => {
    if (isLoader) {
      let count = 0;
      const intervalVariable = setInterval(() => {
        if (count < 90) {
          dispatch(setProgressData(count));
        } else if (count >= 90) {
          dispatch(setProgressData(90));
          window.clearInterval(intervalVariable);
        }
        count = count + 10;
        //  console.log(count);
      }, 10);

      setintervalData(intervalVariable);
      progressFunction(intervalVariable);
    } else {
      dispatch(setProgressData(100));
      window.clearInterval(intervalData);
    }
  }, [isLoader]);

  useEffect(() => {
    getLoyaltyData();
  }, [take]);

  const getLoyaltyData = async () => {
    setIsLoader(true);
    try {
      const { status, data } = await loyaltyData();
      if (status === 200) {
        if (data.status === "success") {
          setIsLoader(false);
          setloyaltyData(data?.common_customer_point);
        }
      }
    } catch (error) {
      console.error("error msg", error);
    } finally {
      setIsLoader(false);
    }
  };

  // const handleViewPoints = async (card) => {
  //   setLoyaltyDetails(card);
  //   console.log("cardcard", card);
  //   const productId = card.product_Id;
  //   setIsLoader(false);
  //   try {
  //     const { status, data } = await loyaltyStoreData(skip, take, productId);
  //     if (status === 200) {
  //       if (data.status === "success") {
  //         setBackdropVisible(true);
  //         setLoyaltyStorePoints(data?.common_customer_point);
  //         setIsOpen(true);
  //       }
  //       setIsLoader(false);
  //     }
  //   } catch (e) {
  //     setIsLoader(false);
  //     error("error msg");
  //   } finally {
  //     setIsLoader(false);
  //   }
  // };

  const loyaltytoggleDrawer = () => {
    setIsOpen(!isOpen);
    setBackdropVisible(!backdropVisible);
  };

  const handleViewPoints = (item) => {
    setitem(item);
  };

  return (
    <div className="pb-4 mt-5 mdl:mt-0">
      <Drawer
        open={isOpen}
        onClose={loyaltytoggleDrawer}
        anchor="right"
        className="overflow-y-auto lg:max-h-[calc(100vh-0px)]	max-h-[calc(100vh-60px)]"
      >
        {item && (
          <LoyaltyTabDrawer
            isDrawerOpen={isOpen}
            item={item}
            onClose={loyaltytoggleDrawer}
          />
        )}
      </Drawer>

      {backdropVisible && (
        <div className="h-[100vh] w-full fixed top-0 left-0 z-50 bg-[hsl(0deg_0%_0%_/_60%)] "></div>
      )}
      {isLoader ? (
        <LoyaltyLoader />
      ) : loyalty.length == 0 ? (
        <div className="flex justify-center items-center w-full min-h-[calc(100vh_-_17rem)] h-auto sm:min-h-[calc(100vh_-_17rem)] sm:h-auto">
          <div className="w-[350px]">
            <img src={nodata_found} alt="No data found image" />
          </div>
        </div>
      ) : (
        loyalty.map((item) => (
          <Card
            key={item.product_Id}
            className={`rounded-lg border-none bg-white mb-4 mdl:mb-6`}
          >
            <div className="flex justify-between items-center">
              <div className="flex p-4 mdl:p-5 w-[70%]">
                <div className="w-[100px] flex">
                  <LazyLoadImageComponent
                    src={item.store_Logo}
                    alt=""
                    className="rounded-lg"
                    onErrorSrc={logo_cover}
                    loading="lazy"
                    placeholderSrc={logo_cover}
                  />
                </div>
                <div className="ml-3 mdl:ml-4">
                  <h3 className="mt-2 text-sm sm:text-md font-medium">
                    {item.store_Name}
                  </h3>
                  <p className="my-1 text-xs">{item.store_Address}</p>
                  <div className="py-1 px-3 w-max bg-checkText rounded-full text-white text-xxs">
                    Points {item.common_customer_point_Balance}
                  </div>
                </div>
              </div>

              <div className="space-x-3 md:space-x-2 sm:flex items-center cursor-pointer flex w-[30%] justify-end ">
                <div
                  className="flex items-center pl-10 mr-2"
                  onClick={() => {
                    loyaltytoggleDrawer();
                    handleViewPoints(item);
                  }}
                >
                  <div className="w-max pl-4 md:py-2 md:w-[86] md:h-[40px]">
                    <div
                      className="flex items-center mr-2"
                      // onClick={() => {
                      //   handleViewPoints(item);
                      // }}
                    >
                      <div className="w-2.5">
                        <img src={right_arrow} alt="" srcSet="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        ))
      )}
    </div>
  );
};

export default LoyaltyTab;
