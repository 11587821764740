import { Link } from "react-router-dom";
import { capitalizeFirstWord } from "../../utils";
import GreaterThan from "../../assets/images/chevron-right.png";
import { HomePage } from "../../routes/RoutesPath";

export function Breadcrumbs({ className }) {
  const paths = window.location.pathname.split("/").slice(1);
  const breadcrumbs = [];
  paths?.map((p, index) => {
    if (p != "frequently" && p != "asked") {
      breadcrumbs.push({
        title: p === "stores" ? "Home" : p === "questions" ? "Faq" : `${p}`,
        link: `/${paths?.slice(0, index + 1).join("/")}`,
      });
    }
  });

  return (
    <div className={`${className}`}>
      <Link key={`breadcrumbshome`} to={HomePage} className="flex items-center">
        <span className="text-xs sm:text-sm font-normal text-grey">Home</span>
        <img src={GreaterThan} className="w-4 h-4" loading="lazy" />
      </Link>
      {breadcrumbs &&
        breadcrumbs.map((b, index) => {
          return index !== breadcrumbs.length - 1 ? (
            <Link
              key={`breadcrumbs` + index}
              to={b.link}
              className="flex items-center"
            >
              <span className="text-xs sm:text-sm font-normal text-grey">
                {capitalizeFirstWord(b?.title?.split("_").join(" "))}
              </span>
              <img src={GreaterThan} className="w-4 h-4" loading="lazy" />
            </Link>
          ) : (
            <Link
              key={`breadcrumbs` + index}
              to={b.link}
              className="flex items-center"
            >
              <span className="text-xs sm:text-sm font-normal text-active-route">
                {capitalizeFirstWord(b?.title?.split("_").join(" "))}
              </span>
            </Link>
          );
        })}
    </div>
  );
}
