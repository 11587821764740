import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBasketData } from "../../store/basicData/basicDataSlice";
import { setCartConfigSession } from "../../utils";
import Modal from "react-modal";
import AllergyItems from "../Elements/AllergyItems";
import { addAllergys, getAllergyList } from "../../services";
import Button from "../Elements/Button";
import { copyObject } from "../../utils/helper";
import useWindowDimensions from "../../hooks/useWindowsDimensons";

const AllergyModal = ({ visible, onClose }) => {
  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = visible ? "hidden" : "auto";
  }, [visible]);

  return (
    <Modal
      isOpen={visible}
      onRequestClose={onClose}
      className=" bg-black bg-opacity-50 z-50 "
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-50"
    >
      <AllergyList onClose={onClose} />
    </Modal>
  );
};

export default AllergyModal;

export const AllergyList = ({ onClose, isDrawer }) => {
  const dispatch = useDispatch();
  const basket = useSelector((state) => state.basicdata.basket);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const { width } = useWindowDimensions();
  const [isSmall, setIsSmall] = useState(false);

  useEffect(() => {
    if (width < 768) {
      setIsSmall(true);
    } else {
      setIsSmall(false);
    }
  }, [width]);

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    setSelected(copyObject(basket?.pos_basket_allergy ?? []));
  }, [basket]);

  const handleSelected = (val) => {
    const index = selected?.indexOf(val);
    if (index === -1) {
      selected.push(val);
    } else {
      selected.splice(index, 1);
    }
    setSelected(selected);
    setData([...data]);
  };

  const getList = async () => {
    const { status, data } = await getAllergyList();
    if (status === 200) {
      if (data.status === "success") {
        setData(data?.allergyList);
      }
    }
  };

  const handleSubmit = async () => {
    let result = {};
    selected.forEach((val) => {
      result[val] = val;
    });
    const { status, data } = await addAllergys(result);
    if (status === 200) {
      if (data.status === "success") {
        setCartConfigSession(data?.cartConfig);
        dispatch(setBasketData(data?.basket));
        onClose();
      }
    }
  };

  return (
    <div
      className={`bg-white flex flex-col justify-between ${
        isDrawer
          ? "w-full h-auto"
          : `${isSmall ? "w-full h-screen" : "w-[680px] rounded-lg"}`
      } mx-auto overflow ${
        isDrawer
          ? ""
          : `${
              isSmall
                ? ""
                : "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            }`
      }`}
    >
      {!isDrawer && (
        <div className="px-[16px]">
          <div className="border-b py-[16px] sm:px-[40px] h-20 flex items-center justify-between">
            <span className="text-xl font-semibold capitalize font-inter  ">
              Add Allergens
            </span>
            <Button
              type="button"
              className=" text-black bg-transparent text-sm ms-auto inline-flex justify-center items-center"
              onClick={() => onClose()}
            >
              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </Button>
          </div>
        </div>
      )}

      {/* h-[calc(100vh_-_20rem)] */}
      {/* <div className="border-b w-[94%] m-auto" /> */}
      <div className="px-[16px]">
        <div
          className={`py-4 h-[calc(100vh-10rem)] overflow-y-auto border-b ${
            isDrawer
              ? "h-full px-6"
              : `${isSmall ? "h-[80vh]" : "!h-[60vh]"} sm:px-[40px]`
          } overflow-x-hidden `}
        >
          <div className="leading-snug text-justify font-inter font-normal text-sm ">
            If you have any allergies that may affect your health, we strongly
            recommend contacting the store directly before placing your order.
            Please refer to our FAQ page for more details on our allergy
            policies.
          </div>
          <div className="grid sm:grid-cols-2 gap-2 mt-4 ">
            {data?.map((val, ind) => {
              let checked = selected?.indexOf(val.common_allergy_Name) !== -1;
              return (
                <AllergyItems
                  key={"allergy" + ind}
                  checked={checked}
                  onChange={() => handleSelected(val.common_allergy_Name)}
                  img={val.common_allergy_Thumbnail}
                  title={val.common_allergy_Name}
                  desc={val.common_allergy_Description}
                  alt="allergy images"
                />
              );
            })}
          </div>
        </div>
      </div>
      {/* <div className="border-b w-[94%] m-auto" /> */}
      <div
        className={`${
          isSmall ? "h-20" : "py-5"
        } flex justify-center items-center`}
      >
        <Button
          onClick={() => handleSubmit()}
          className="w-[90%] text-white bg-primary rounded-lg px-6 py-4 text-base font-normal font-inter leading-tight"
        >
          Update Cart
        </Button>
      </div>
    </div>
  );
};
