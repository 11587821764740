import { useState, useEffect } from "react";
import viewCoupons from "../../assets/images/rewards/scratchCard.png";
import ribbonGif from "../../assets/images/rewards/ribbonGif.gif";
import giftBoxBlue from "../../assets/images/rewards/giftBoxBlue.png";
import giftRibbonBlue from "../../assets/images/rewards/giftRibbonBlue.png";
import { success } from "../notify";
import Button from "../Elements/Button";
import { couponScratch } from "../../services";

const RewardCouponModal = ({ visible, onClose, data, onScratchReveal }) => {
  const [isCardRevealed, setIsCardRevealed] = useState(false);
  const [inputValue, setInputValue] = useState(data.common_coupon_Barcode);
  const [showRibbon, setShowRibbon] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const preloadImages = async () => {
      const images = [viewCoupons, ribbonGif, giftBoxBlue, giftRibbonBlue];
      const promises = images.map(
        (src) =>
          new Promise((resolve) => {
            const img = new Image();
            img.src = src;
            img.onload = resolve;
          })
      );
      await Promise.all(promises);
      setImagesLoaded(true);
    };
    preloadImages();
  }, []);

  useEffect(() => {
    setInputValue(data.common_coupon_Barcode);
  }, [data.common_coupon_Barcode]);

  useEffect(() => {
    if (!visible) return;

    const canvas = document.getElementById("scratch");
    if (!canvas) return;

    const ctx = canvas.getContext("2d", { willReadFrequently: true });
    const image = new Image();
    image.src = viewCoupons;

    image.onload = () => {
      const scale = window.devicePixelRatio || 1;
      const { width, height } = image;
      canvas.width = width * scale;
      canvas.height = height * scale;
      canvas.style.width = `${width}px`;
      canvas.style.height = `${height}px`;
      ctx.scale(scale, scale);
      ctx.globalCompositeOperation = "source-over";
      ctx.drawImage(image, 0, 0, width, height);
    };

    let isDrawing = false;
    let lastX, lastY;
    let apicall = false;

    const getRelativePosition = (event) => {
      const rect = canvas.getBoundingClientRect();
      const scaleX = canvas.width / rect.width;
      const scaleY = canvas.height / rect.height;
      const clientX = event.touches ? event.touches[0].clientX : event.clientX;
      const clientY = event.touches ? event.touches[0].clientY : event.clientY;
      const x = (clientX - rect.left) * scaleX;
      const y = (clientY - rect.top) * scaleY;
      return { x, y };
    };

    const scratch = (x, y, lastX, lastY) => {
      ctx.globalCompositeOperation = "destination-out";
      ctx.beginPath();
      ctx.moveTo(lastX, lastY);
      ctx.lineTo(x, y);
      ctx.lineCap = "round";
      ctx.lineJoin = "round";
      ctx.lineWidth = 450;
      ctx.stroke();
      checkIfCardRevealed();
    };

    const handleStart = (event) => {
      event.preventDefault();
      isDrawing = true;
      const { x, y } = getRelativePosition(event);
      lastX = x;
      lastY = y;
      scratch(x, y, lastX, lastY);
    };

    const handleMove = (event) => {
      event.preventDefault();
      if (!isDrawing) return;
      const { x, y } = getRelativePosition(event);
      scratch(x, y, lastX, lastY);
      lastX = x;
      lastY = y;
    };

    const handleEnd = () => {
      // alert()
      isDrawing = false;
    };

    const checkIfCardRevealed = () => {
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      let transparentPixels = 0;
      for (let i = 0; i < imageData.data.length; i += 4) {
        if (imageData.data[i + 3] === 0) {
          transparentPixels++;
        }
      }
      const totalPixels = canvas.width * canvas.height;
      const revealedPercentage = (transparentPixels / totalPixels) * 100;
      // console.log(revealedPercentage)
      if (revealedPercentage >= 22 && revealedPercentage <= 50) {
        revealCard();
      }
    };

    const revealCard = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      setIsCardRevealed(true);
      setShowRibbon(true);
      setTimeout(() => setShowRibbon(false), 6000);
      onScratchReveal(data.common_coupon_Id);
      apicall = true;
      if (apicall) {
        setTimeout(() => {
          getCardData();
        }, 500);
      }
    };

    const getCardData = async () => {
      apicall = false;
      const couponId = data.common_coupon_Id;
      try {
        const { status, data } = await couponScratch(couponId);
        if (status === 200) {
          if (data.status === "success") {
            // success(data.msg);
            success("You've scratched successfully! Here's your reward.");
          }
        }
      } catch (e) {
        // error(e.msg);
      }
      // console.log("Icebox", couponId);
    };

    canvas.addEventListener("mousedown", handleStart);
    canvas.addEventListener("mousemove", handleMove);
    canvas.addEventListener("mouseup", handleEnd);
    canvas.addEventListener("mouseleave", handleEnd);
    canvas.addEventListener("touchstart", handleStart);
    canvas.addEventListener("touchmove", handleMove);
    canvas.addEventListener("touchend", handleEnd);

    return () => {
      canvas.removeEventListener("mousedown", handleStart);
      canvas.removeEventListener("mousemove", handleMove);
      canvas.removeEventListener("mouseup", handleEnd);
      canvas.removeEventListener("mouseleave", handleEnd);
      canvas.removeEventListener("touchstart", handleStart);
      canvas.removeEventListener("touchmove", handleMove);
      canvas.removeEventListener("touchend", handleEnd);
    };
  }, [visible, data.common_coupon_Id, onScratchReveal]);

  const handleCopy = () => {
    navigator.clipboard.writeText(inputValue);
    success(`Copied to clipboard! ${inputValue}`);
  };

  return (
    visible &&
    imagesLoaded && (
      <div
        onClick={onClose}
        className="fixed inset-0 overflow-auto flex justify-center items-center transition-colors z-50 bg-black/60 rounded-lg"
      >
        <div
          className="relative p-4 max-h-full"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="relative bg-white rounded-lg shadow">
            {showRibbon && (
              <img className="absolute" src={ribbonGif} alt="" loading="lazy" />
            )}
            <div className="bg-white p-4 rounded-lg relative w-56 h-44 sm:w-72 sm:h-60 2xl:w-80 2xl:h-64">
              <div className="base absolute top-4 sm:top-6 md:top-8 left-0 2xl:top-10 flex justify-center w-full text-center">
                <div>
                  <div className="w-11 m-auto">
                    <img src={giftRibbonBlue} alt="" loading="lazy" />
                  </div>
                  <p className="text-center font-semibold relative -top-3 text-black text-[20px]">
                    {data.common_coupon_Heading}
                  </p>
                  <div className="w-11 relative -top-3 m-auto">
                    <img src={giftBoxBlue} alt="" loading="lazy" />
                  </div>
                  <div className="text-black 2xl:text-[18px] font-bold">
                    {inputValue}
                  </div>
                  <Button
                    className="text-black text-[12px] rounded"
                    onClick={handleCopy}
                  >
                    Copy Code
                  </Button>
                </div>
              </div>
              {!isCardRevealed && (
                <canvas
                  id="scratch"
                  width="400"
                  height="400"
                  className="w-44 h-44 sm:w-60 sm:h-60 2xl:w-60 2xl:h-60 rounded-lg absolute top-0 left-0"
                  style={{
                    cursor:
                      'url("https://media.geeksforgeeks.org/wp-content/uploads/20231030101751/bx-eraser-icon.png"), auto',
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default RewardCouponModal;
