import { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate, useLocation, matchPath } from "react-router-dom";
import { Drawer } from "@mui/material";
import HeaderItem from "./HeaderItem";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import HeaderDrawer from "./HeaderDrawer";
import RestaurantFilter from "../../pages/restaurant/RestaurantFilter";
import Search from "../Icon/Search";
import CartIcon from "../Icon/Cart";
import LocationPin from "../Icon/LocationPin";
import ArrowDown from "../Icon/ArrowDown";
import { useDispatch } from "react-redux";
import { profileData } from "../../services/index";
import KuickLogo from "../../assets/images/default_images/KuickLogo.png";
import {
  removeUserData,
  setUserData,
} from "../../store/userData/userDataSlice";
import {
  setStoreData,
  setTakeAwayOpen,
  setTakeAwayClosed,
  setSearchItems,
  setHomeLoader,
  setFilterItems,
} from "../../store/basicData/basicDataSlice";
import {
  getSession,
  removeSession,
  getDineConfigSession,
  removeAllSpaceFromString,
  setDineConfigSession,
} from "../../utils";
import Modal from "react-modal";
import LoginModal from "../Modal/LoginModal";
import Layout from "../../layout/Layout";
import Button from "../Elements/Button";
import LoadingBar from "react-top-loading-bar";
import { success, error } from "../notify";
import { checkDoSticky, checkEmpty, formatPostcode } from "../../utils/helper";
import { LandingPage, ProfilePage, HomePage } from "../../routes/RoutesPath";
import HomeLoader from "../Loader/HomeLoader/HomeLoader";
import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";
import { useSelector } from "react-redux";
import { getSearchFilter } from "../../services";
import filterIcon from "../../assets/images/filterhome.png";
import { setIsFilterLoaded } from "../../store/apiLoaderData/apiLoaderDataSlice";
import Cart from "../Elements/Cart";
import useHomeClick from "../../hooks/useHomeClick";
import SubHeader from "../Loader/MenuLoaders/SubHeader";
import { setLoginModule } from "../../store/userData/userDataSlice";
import Profile from "../Icon/ProfileIcon";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { handleHomeRedirect } = useHomeClick();

  let headerRef = useRef(null);
  const isLoggedin = useSelector((state) => state.userdata.loggedIn);
  const progress = useSelector((state) => state.progressdata.progress);
  const basket = useSelector((state) => state.basicdata.basket);
  const homeLoader = useSelector((state) => state.basicdata.homeLoader);
  const locationData = useSelector((state) => state.basicdata.locationData);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isFilterDrawerOpen, setFilterIsDrawerOpen] = useState(false);
  const [isCartDrawerOpen, setIsCartDrawerOpen] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [profileDetails, setProfileDetails] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [MenuOpen, setMenuOpen] = useState(false);
  const [locationOpen, setlocationOpen] = useState(false);
  const [isShadow, setShadow] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [postcode, setPostcode] = useState("");

  // Sticky Menu Area
  useEffect(() => {
    if (isLoggedin) {
      getProfileData();
    }
    checkSession();
    document.body.addEventListener("click", (event) => {
      if (event.target.innerText != "Login") {
        closeFilter();
      }
    });
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  //Header shadow based on route
  useEffect(() => {
    setTimeout(() => {
      const pathname = location.pathname.toLocaleLowerCase();
      const pathParts = pathname.split("/");
      if (pathParts.includes("stores")) {
        const postcodeIndex = pathParts.indexOf("stores") + 1;
        setPostcode(pathParts[postcodeIndex]?.toLowerCase());
      } else {
        // alert(locationData?.postcodePretty)
        setPostcode(locationData?.postcodePretty?.toLowerCase());
      }

      if (
        pathname === "/" ||
        pathname === `/stores/${postcode?.toLowerCase()}`
      ) {
        setShadow(true);
      } else {
        setShadow(false);
      }
    }, 1000);
  }, [isShadow, location.pathname, postcode]);

  useEffect(() => {
    let count = 0;
    basket?.basketProducts?.forEach((val) => {
      count += val.pos_basketproduct_Quantity;
    });
    setTotalItems(count);
  }, [basket]);

  const checkSession = () => {
    const data = getSession();
    if (data) {
      dispatch(setUserData());
      // getData();
    } else {
      dispatch(removeUserData());
    }
  };

  /* Method that will fix header after a specific scrollable */
  const isSticky = () => {
    const scrollTop = window.scrollY;
    if (checkDoSticky()) {
      setIsFixed(scrollTop > 80);
    }

    if (
      location?.pathname?.includes("frequently/asked/questions") ||
      location?.pathname?.includes("uns") ||
      location?.pathname?.includes("unsubscribe")
    ) {
      setIsFixed(scrollTop > 80);
    }
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    setIsOpen(!isOpen);
  };

  const filtertoggleDrawer = () => {
    setFilterIsDrawerOpen(!isFilterDrawerOpen);
  };

  const carttoggleDrawer = () => {
    setIsCartDrawerOpen(!isCartDrawerOpen);
  };

  const getProfileData = async () => {
    setIsLoading(true);
    try {
      const { status, data } = await profileData();
      if (status === 200) {
        if (data.status === "success") {
          setProfileDetails(data?.pageContent?.customer);
        }
      }
    } catch (e) {
      console.error("error msg", e);
    } finally {
      setIsLoading(false);
    }
  };

  const kuickImgReload = async () => {
    const landingPagePath = `/`;
    if (!checkEmpty(postcode)) {
      handleHomeRedirect(postcode);
    } else {
      navigate(landingPagePath);
    }
  };

  const closeFilter = () => {
    setShowTooltip(false);
  };

  const locationOpenModal = () => {
    setlocationOpen(true);
  };

  const openLoginModal = () => {
    dispatch(setLoginModule(true));
  };

  const handleOnFilterChange = async (filers) => {
    filtertoggleDrawer();
    dispatch(setSearchItems(""));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    dispatch(setHomeLoader(true));
    filers.postcode = postcode;
    filers.searchLat = "";
    filers.searchLon = "";
    navigate(HomePage + removeAllSpaceFromString(postcode));
    // const { status, data } = await getSearchFilter(filers);
    // if (status === 200) {
    //   dispatch(setStoreData(data?.storeList));
    //   dispatch(setIsFilterLoaded(false));
    //   const nearestCount =
    //     data?.storeList.find((section) => section.heading === "Nearest")?.data
    //       .length || 0;
    //   dispatch(setTakeAwayOpen(nearestCount));
    //   const closedCount =
    //     data?.storeList.find((section) => section.heading === "Closed")?.data
    //       .length || 0;
    //   dispatch(setTakeAwayClosed(closedCount));
    //   dispatch(setHomeLoader(false));
    // } else {
    //   dispatch(setIsFilterLoaded(false));
    //   error("Something went wrong!! Please try again", true);
    // }
  };

  return (
    <div>
      <Layout className={``}>
        {/* HeaderDrawer */}
        <Drawer open={isDrawerOpen} onClose={toggleDrawer} anchor="left">
          <HeaderDrawer isDrawerOpen={isDrawerOpen} onClose={toggleDrawer} />
        </Drawer>
        {/* HeaderDrawer */}

        {/* Restaurant */}
        <Drawer
          anchor="right"
          open={isFilterDrawerOpen}
          onClose={filtertoggleDrawer}
        >
          <RestaurantFilter
            onChange={handleOnFilterChange}
            postcode={postcode}
            filtertoggleDrawer={filtertoggleDrawer}
          />
        </Drawer>
        {/* Restaurant */}

        {/* cart */}
        <Drawer
          anchor="right"
          open={isCartDrawerOpen}
          onClose={carttoggleDrawer}
        >
          <Cart isDrawer toggleDrawer={carttoggleDrawer} />
        </Drawer>
        {/* cart */}
        <LoadingBar role="bar" color="#f11946" progress={progress} />
        <nav
          ref={headerRef}
          className={`${
            isFixed ? `h-16 ${isShadow ? "shadow-xl" : ""}` : `h-16 mdl:h-20`
          } py-3 xl:py-3 flex justify-between items-center bg-white ${
            isFixed
              ? "fixed top-0 left-0 z-50 w-full px-[14px] sm:px-7 md:px-8 lg:px-12 xl:px-[60px] mdl:h-20 transition duration-300"
              : ""
          } ${MenuOpen == true ? "z-[50]" : ""}`}
        >
          <div className="flex items-center sm:space-x-3 xl:space-x-6 ">
            <div onClick={kuickImgReload} className="cursor-pointer">
              <div className="w-[74.001px] h-[33.85px] sm:w-[100px] sm:h-[45px] xl:w-[116.14px] xl:h-[55px] 2xl:h-[63px] 2xl:w-[133px] align-middle lg:ml-0 xl:ml-0 2xl:ml-0">
                <img
                  loading="lazy"
                  src={KuickLogo}
                  alt=""
                  className="kuickLogo"
                />
              </div>
            </div>
            {/* {isLoggedin && ( */}

            <div className="hidden w-max sm:w-max mdl:block mdl:pr-2">
              {homeLoader ? (
                <div className="w-max flex space-x-2">
                  <SubHeader />
                  <SubHeader />
                  <SubHeader />
                </div>
              ) : (
                !checkEmpty(locationData) && (
                  <div
                    className="space-x-3 flex items-center "
                    onClick={() => toggleDrawer()}
                  >
                    <div className="text-base text-primary font-semibold underline underline-offset-4 cursor-pointer">
                      {locationData?.cityName}
                    </div>
                    <div className="cursor-pointer truncate w-44 lg:max-w-[calc(25vw-_5rem)] xl:w-max text-dark-shade-4 font-normal text-[16px] 2xl-[20px] leading-tight font-inter ">
                      {formatPostcode(locationData.postcodePretty ?? "")}{" "}
                      {locationData?.street}
                    </div>
                    <ArrowDown className="w-4 cursor-pointer" />
                  </div>
                )
              )}
            </div>
          </div>

          <div className="flex font-inter items-center  ">
            <div
              className={`block w-full mdl:hidden ${
                isLoggedin ? "sm:mr-0 md:mr-2" : "mr-1"
              }`}
            >
              {homeLoader ? (
                <div className="w-max flex space-x-0 md:space-x-2">
                  <SubHeader />
                  <SubHeader className="hidden sm:inline" />
                </div>
              ) : (
                !checkEmpty(locationData) && (
                  <div className="drawer-content" onClick={toggleDrawer}>
                    <div
                      className={`cursor-pointer line-clamp-1 w-full float-end text-xs md:text-sm font-semibold flex justify-end pl-2`}
                    >
                      <span className="line-clamp-1">
                        <span className="text-primary underline mr-1">
                          {locationData?.cityName}
                        </span>
                        {formatPostcode(locationData.postcodePretty ?? "")}{" "}
                        <span className="hidden md:inline md:mr-3">
                          {locationData?.street}
                        </span>
                      </span>
                    </div>
                  </div>
                )
              )}
            </div>
            <div
              onClick={toggleDrawer}
              className={`space-x-3 md:space-x-2 flex items-center cursor-pointer md:mb-0 mdl:mr-0`}
            >
              <Search
                className={`w-4 h-4 md:h-5 md:w-5 sm:ml-2 md:ml-0 ${
                  !checkEmpty(locationData) ? "hidden sm:block" : "block"
                }`}
              />
              <div
                className={`text-base text-black-shade-5 font-inter ${
                  !checkEmpty(locationData)
                    ? "hidden sm:hidden md:hidden lg:block"
                    : "block"
                }`}
              >
                Search
              </div>
            </div>

            {!(location.pathname === "/") && (
              <div className="items-center w-max px-0 py-0 ">
                {(matchPath("/stores/:id", location.pathname) ||
                  matchPath("/takeaways/*", location.pathname)) && (
                  <div
                    className="flex w-6 h-6 space-x-3 md:space-x-2 items-center justify-center cursor-pointer md:w-7 md:h-6 mdl:pl-4 mdl:py-2 mdl:w-max mdl:h-[40px] ml-2 mdl:ml-0 mdl:mr-0"
                    onClick={() => filtertoggleDrawer()}
                  >
                    <img
                      loading="lazy"
                      className="w-6 h-5 md:w-6 md:h-[22px]"
                      src={filterIcon}
                      alt="filters"
                    />
                    <div className="text-base hidden mdl:block text-black-shade-5 font-inter">
                      Filter
                    </div>
                  </div>
                )}
              </div>
            )}

            <div
              onClick={(event) => {
                event.stopPropagation();
                carttoggleDrawer();
              }}
              className="w-max mdl:pl-4 mdl:py-2 mdl:w-[86px] mdl:h-[40px] mdl:block"
            >
              <div className="flex drawer-button space-x-3 md:space-x-2 items-center cursor-pointer ">
                <div className="hidden mdl:block">
                  <div className="flex flex-col w-max items-center md:mb-0">
                    <CartIcon className={`w-6 h-6 stroke-black-shade-5`} />
                    {totalItems > 0 && (
                      <div
                        className="h-5 w-5 rounded-full bg-stepperCircle text-white flex items-center justify-center absolute ml-5 -mt-2"
                        style={{ fontSize: "10px" }}
                      >
                        {totalItems}
                      </div>
                    )}
                  </div>
                  {/* <img
                      loading="lazy"
                      className="w-6 h-6 relative"
                      src={CartIcon}
                      alt="cart"
                    /> */}
                </div>
                <div className="text-base hidden mdl:block text-black-shade-5 font-inter">
                  Cart
                </div>
              </div>
            </div>
            {isLoggedin && (
              <div
                onClick={() => {
                  navigate(ProfilePage);
                }}
                className="w-max mdl:pl-4 mdl:py-2 mdl:w-[86px] mdl:h-[40px] mdl:block"
              >
                <div className="flex drawer-button space-x-3 md:space-x-2 items-center cursor-pointer ">
                  <div className="hidden mdl:block">
                    <div className="flex flex-col w-max items-center md:mb-0">
                      <Profile className={`w-6 h-6 `} />
                    </div>
                  </div>
                  <div className="text-base hidden mdl:block text-black-shade-5 font-inter">
                    Profile
                  </div>
                </div>
              </div>
            )}

            {isLoggedin && (
              <div className="mdl:hidden gap-3 sm:ml-6 hidden">
                {/* <Link
                  to={BookmarksPage}
                  className={`flex space-x-1 items-center `}
                >
                  <Bookmark className="w-6  md:w-5, md:h-5 mr-1 " />
                  <LocationPin className="sm:hidden w-6 stroke-black-shade-5 " />
                  <div className="text-base hidden md:block text-black-shade-5 font-inter">
                    Bookmark
                  </div>
                </Link> */}

                <div className="px-[-50px]" onClick={() => toggleDrawer()}>
                  {/* <HeaderItem
                      Icon={LocationPin}
                      text="Location"
                      className="sm:hidden w-8 h-6 fill-black-shade-5"
                    /> */}
                  {/* <Drawer open={isDrawerOpen} onClose={toggleDrawer}>
                    <HeaderDrawer className="sm:hidden w-8 h-6 fill-black-shade-5" />
                  </Drawer> */}
                </div>

                <div
                  onClick={() => {
                    navigate(ProfilePage);
                  }}
                  className="w-11 h-11 cursor-pointer rounded-full object-cover hidden md:block"
                >
                  <img
                    loading="lazy"
                    src={
                      profileData.profileImage ||
                      "https://upload.wikimedia.org/wikipedia/commons/2/2c/Default_pfp.svg"
                    }
                    className="w-11 h-11 rounded-full object-cover hidden sm:block"
                  />
                </div>
              </div>
            )}
            {!isLoggedin && (
              <span className="hidden mdl:block ml-7 opacity-50">|</span>
            )}
            {!isLoggedin && (
              <div className="flex space-x-1 h-12 items-center">
                <Button
                  className="hidden mdl:hidden ml-1.5"
                  // onClick={() => {
                  //   navigate("/login", {
                  //     state: {
                  //       from: "login",
                  //     },
                  //   });
                  // }}
                >
                  <div
                    // to={RegistrationPage}
                    onClick={openLoginModal}
                    className="flex items-center text-xxs font-semibold text-white rounded-md px-2.5 py-1.5 bg-primary"
                  >
                    Login
                  </div>
                  {/* <ChevronDownIcon className="w-5 ml-1 text-white " /> */}
                </Button>
                <Button className="hidden mdl:block" onClick={openLoginModal}>
                  <div className="flex text-xs sm:text-sm 2xl:text-xl font-bold leading-relaxed font-inter px-4 sm:px-7 py-2 text-dark-shade-5">
                    Login
                  </div>
                </Button>

                <div
                  onClick={openLoginModal}
                  className="cursor-pointer hidden mdl:flex h-10 sm:h-12 text-sm 2xl:text-xl w-max xl:w-28 2xl:w-[130px] font-bold leading-relaxed font-inter px-4 sm:px-7 py-2 bg-primary rounded-lg text-white items-center"
                >
                  Sign up
                </div>

                {/* {showTooltip && (
                <div
                  id="dropdownDots"
                  className="absolute top-12 right-4 z-10 text-xs bg-white divide-y divide-gray-100 rounded-lg shadow w-[120px]"
                >
                  <ul
                    className="text-sm text-gray-700"
                    aria-labelledby="dropdownMenuIconButton"
                  >
                    <li
                      className={`border-b relative px-4 py-1.5`}
                      onClick={() => {
                        navigate("/login", {
                          state: {
                            from: "login",
                          },
                        });
                      }}
                    >
                      <Link to="#">Login</Link>
                    </li>
                    <li className={`border-b relative px-4 py-1.5`}>
                      <Link to="/registration">Sign up</Link>
                    </li>
                  </ul>
                </div>
              )} */}
              </div>
            )}
          </div>
        </nav>
        {/* // )} */}
      </Layout>
      {/* <Layout>
        {isLoading && (
          <HomeLoader
            subClassName={`grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4`}
            subsubClassName={`h-60`}
          />
        )}
      </Layout> */}
    </div>
  );
};

export default Header;

const DrawerItem = ({ to, title, Icon, onClick }) => {
  return (
    <NavLink
      to={to}
      style={{ margin: "0!important" }}
      className={`group flex justify-between items-center m-0   ${
        location.pathname === to ? "bg-primary text-white" : ""
      } hover:bg-primary hover:fill-white py-2 px-2 rounded-lg mx-0 my-2`}
      onClick={onClick}
    >
      <>
        <div className="w-full m-0 group flex space-x-6 items-center ">
          <Icon
            className={`${
              location.pathname === to ? "text-white fill-white" : " "
            } w-6 2xl:w-6 2xl:h-6`}
          />
          <div
            className={`${
              location.pathname === to ? "text-white" : "text-zinc-700"
            } group-hover:text-white text-base font-medium font-inter leading-normal`}
          >
            {title}
          </div>
        </div>
        <ChevronRightIcon
          className={`${
            location.pathname === to ? "text-white" : ""
          } group-hover:text-white w-5 h-5`}
        />
      </>
    </NavLink>
  );
};
